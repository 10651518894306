import React, {PureComponent} from 'react'

import {BASE_URL, post, postFormdata} from 'utils'
import { withTranslation } from 'react-i18next';

import GroupForm from 'resource/forms/groups/GroupForm';
import ProfileForm from 'resource/forms/profiles/ProfileForm';
import NewSubscriberForm from 'resource/forms/subscribers/NewSubscriberForm'
import CreateSystemForm from 'resource/forms/systems/CreateSystemForm';
import SiteForm from 'resource/forms/sites/SiteForm';
import AccountForm from 'resource/forms/accounts/AccountForm';
import ConfigForm from 'resource/forms/configs/ConfigForm';
import CustomerForm from 'resource/forms/customers/CustomerForm';
import ScriptForm from 'resource/forms/scripts/ScriptForm';
import CarrierForm from 'resource/forms/carriers/CarrierForm';
import DeviceForm from "resource/forms/devices/DeviceForm";
import CreateFileForm from "resource/forms/files/CreateFileForm";
import KeysetForm from "resource/forms/keysets/KeysetForm";
import SubscriberImsiForm from "resource/forms/subImsis/SubscriberImsiForm";

import withControls from 'hoc/withControls';
import Paper from '@material-ui/core/Paper';
import {object} from "prop-types";
import ReportForm from "./forms/report/ReportForm";

type P = {
  resourceName: string,
  user: object
}
export class ResourceNew extends React.Component<P> {

  getEndpoint = (resourceName: string) => {
    switch(resourceName) {
      case "files":
        return `${BASE_URL}/v1/${resourceName}/command/uploadFile`;
      default:
        return `${BASE_URL}/v1/${resourceName}`
    }
  };

  getComponent = (resourceName: string): PureComponent<*> => {
    switch(resourceName) {
      case "subs":
        return NewSubscriberForm;
      case "sites":
        return SiteForm;
      case "sys":
        return CreateSystemForm;
      case "accounts":
        return AccountForm;
      case "sub-groups":
        return GroupForm;
      case "sub-profiles":
        return ProfileForm;
      case "interceptor-scripts":
        return ScriptForm;
      case "server-configs":
        return ConfigForm;
      case "customers":
        return CustomerForm;
      case "carriers":
        return CarrierForm;
      case "devices":
        return DeviceForm;
      case "files":
        return CreateFileForm;
      case "keysets":
        return KeysetForm;
      case "sub-imsis":
        return SubscriberImsiForm;
      case "reports":
        return ReportForm;
      default:
        return <div />
    }
  };

  getOnSubmit = (resourceName: string) => {
    switch(resourceName) {
      case "files":
        return postFormdata;
      default:
        return post
    }
  };

  render() {
    const {resourceName, user, t} = this.props;
    const endpoint = this.getEndpoint(resourceName);
    let Component = this.getComponent(resourceName);
    return (
      <Paper>
        <Component
            endpoint={endpoint}
            onSubmit={this.getOnSubmit(resourceName)}
            resourceName={resourceName}
            resourceId={null}
            successMessage={t('createResourceSuccessMessage',{resourceName})}
            isSuperUser={user === "SUPERUSER"}
        />
      </Paper>
    )
  }
}

ResourceNew = withTranslation()(ResourceNew);
ResourceNew = withControls("new")(ResourceNew);
export default ResourceNew
