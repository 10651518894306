import Button from "@material-ui/core/Button";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export default (props) => {
  let component
  switch (props.type) {
    case "menuItem":
      component = <MenuItem onClick={props.openBottomDrawer}>{props.label}</MenuItem>
      break
    default:
      component = <Button onClick={props.openBottomDrawer} variant="outlined">{props.label}</Button>
  }
  return component
}
