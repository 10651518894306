import {DataTypeProvider} from "@devexpress/dx-react-grid";
import React from "react";
import PrimaryKeyFormatter from "./PrimaryKeyFormatter";

const PrimaryKeyProvider = props => (
  <DataTypeProvider
    formatterComponent={PrimaryKeyFormatter}
    {...props}
  />
);

export default PrimaryKeyProvider