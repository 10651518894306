// @flow
import React from 'react'
import {get, BASE_URL, getSelfLink} from 'utils'
import {headings} from 'config'
import withControls from 'hoc/withControls'
import GridLoader from "../components/GridLoader";
import ModuleTable from "./detail/modules/ModuleTable";
import Paper from "@material-ui/core/Paper";

type P = {
  resourceName: string,
  resourceId: string
}

type S = {
  loaded: boolean,
  message: ?string
}

export class ResourceHistory extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      message: null,
      searchString: props.resourceId,
      changedFields: "",
      username: null
    };
  }


  componentWillMount() {
    const {resourceName, resourceId} = this.props;
    this.fetchResources(resourceName, resourceId, [], "")
  }

  fetchResources(resourceName: string, resourceId: string, changedFields, username) {
    get(`${BASE_URL}/v1/${resourceName}/${resourceId}`).then(result => {
      get(headings[resourceName].audit.endpoint(getSelfLink(result), changedFields, username)).then((revisionHistory) => {
        this.setState({"loaded": true, message: revisionHistory})
      })
    })


  }

  onChange = (data) => {
    this.setState(data)
  }
  onClick = () => {
    this.setState({"loaded": false});
    this.fetchResources(this.props.resourceName, this.state.searchString, this.state.changedFields, this.state.username || "")
  }

  render() {
    const {resourceName} = this.props;
    if (!this.state.loaded) {
      return <GridLoader/>
    }
    return (
      <Paper>
        <div className="resource-form">
          <ModuleTable columns={headings[resourceName].audit.columns} rows={this.state.message.reverse()}
                       details={true} detailsPath={["entity", "extensions"]}/>
        </div>
      </Paper>
    )
  }
}

ResourceHistory = withControls("history")(ResourceHistory);
export default ResourceHistory
