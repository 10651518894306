// @flow
import React from 'react'
import {Button, Typography, Paper} from "@material-ui/core";
import {object} from "prop-types";
import * as Sentry from "@sentry/browser";
import {withTranslation} from 'react-i18next';

import {BASE_URL, postFormdata} from 'utils';
import GridLoader from "components/GridLoader";
import CustomerSelector from "components/selectors/CustomerSelector";
import Grid from "@material-ui/core/Grid";
import FileField from "components/fields/FileField";

const initialState = {
  values: {
    file: null,
    customerRef: ""
  },
  errors: {
    file: null,
    customerRef: null
  },
  message: null,
  loaded: true
};

type P = {
  classes: object,
  report: object
}

type S = {
  values: object,
  errors: object,
  loaded: boolean
}

class TmoBillingReportCard extends React.PureComponent<P, S> {

  state = initialState;

  getLoader = (loaded) => {
    if (!loaded) return <GridLoader/>;
    else return <div/>
  };

  launchReport = (uri: string) => {
    const {values} = this.state;
    const data = {
      file: values.file,
      customerRef: values.customerRef
    }
    const endpoint = `${BASE_URL}${uri}`;

    this.setState({
      loaded: false
    });

    // call backend
    postFormdata(endpoint, data).then((response) => {
      this.setState({
        message: response.result,
        loaded: true
      });
    }).catch((err) => {
      Sentry.captureException(err);
      this.setState({
        message: err.message,
        loaded: true
      })
    });

  };

  onChange = (newData) => {
    this.setState((state) => ({
      ...state, values: {...state.values, ...newData}
    }))
  };

  render() {
    const {values, errors, message, loaded} = this.state;
    const {report, t} = this.props;
    return (
      <Grid item spacing={16}>
        <Paper>
          <h4 className="featured card-title">{t('generateReport')}</h4>
          <div className="resource-form">
            <Grid container>
              <Grid item xs={12}>
                <FileField name="file" value={values.file} onChange={this.onChange}/>
                <CustomerSelector label={t('customer')} name="customerRef" value={values.customerRef}
                                  error={errors.customerRef}
                                  onChange={this.onChange}/>
                {message &&
                  <Typography variant="subtitle1" color="primary">
                    {message}
                  </Typography>
                }
                <Button variant="contained" color="primary"
                        onClick={() => this.launchReport(report.uri)}>{t('generateReport')}</Button>
              </Grid>
              {this.getLoader(loaded)}
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

TmoBillingReportCard = withTranslation()(TmoBillingReportCard);
export default TmoBillingReportCard