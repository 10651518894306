import CellLocationMap from "./CellLocationMap"
import Card from "@material-ui/core/Card"
import React from "react"
import { useTranslation } from "react-i18next"

const CellLocationMapContainer = ({data}) => {
  const {t} = useTranslation()
  if (!data) {
    return <Card>
      <p style={{padding:"18px", textAlign:"center", margin:0}}>{t('noLocationData')}</p>
    </Card>
  }
  return (
    <Card style={{height: "500px"}}>
      <CellLocationMap data={data} />
    </Card>
  )
}

export default CellLocationMapContainer
