// @flow
import React from 'react'

import ResourceForm from 'resource/forms/ResourceForm'
import TextField from 'components/fields/TextField'
import SubscriberSelect from "./fields/SubscriberSelect";

const defaultData = {
  "imsi": null,
};

const fieldsSuperUser = {
  "imsi": {
    "Component": TextField,
    "name": "imsi",
    "label": "IMSI",
    "errorName": "imsi",
    "updatable": false,
    "required": true
  },
  "subscriber": {
    "Component": props =>
      <SubscriberSelect
        {...props}
        displayEmpty={true}
      />,
    "name": "subscriber",
    "label": "Subscriber"
  },
};

const SubscriberImsiForm = (props) => <ResourceForm {...props} fields={fieldsSuperUser} defaultData={{...defaultData, ...props.defaultData}}/>
export default SubscriberImsiForm
