// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import {object} from "prop-types";
import SftpAccessFeature from "./fields/SftpAccessFeature";
import CodeEditor from "../../../components/fields/CodeEditor";

let defaultData = {
  "name": null,
  "hostname": null,
  "port": "22",
  "username": null,
  "privateKey": null,
  "remoteDirectory": null
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "hostname": {
    "Component": TextField,
    "name": "hostname",
    "label": "Hostname"
  },
  "port": {
    "Component": TextField,
    "name": "port",
    "label": "Port"
  },
  "username": {
    "Component": TextField,
    "name": "username",
    "label": "Username"
  },
  "privateKey": {
    "Component": CodeEditor,
    "mode": "plain_text",
    "name": "privateKey",
    "label": "Private key"
  },
  "remoteDirectory": {
    "Component": TextField,
    "name": "remoteDirectory",
    "label": "Remote directory"
  },
  "feature": {
    "Component": SftpAccessFeature,
    "name": "feature",
    "label": "Feature"
  }
};

const SftpAccessForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}} />
);

export default SftpAccessForm
