import React from 'react';
import {FormInner} from "../../form/FormInner";
import {connect} from "redux-zero/react";
import bottomDrawerAction from "../bottomDrawerAction";
import KeysetMappingForm from "../../../resource/forms/keysetMappings/KeysetMappingForm";
import OpenBottomDrawerButton from "../../row/actions/OpenBottomDrawerButton";


const BottomDrawer = (props) => (
  <FormInner>
    <KeysetMappingForm {...props} />
  </FormInner>
)

export default connect(null, bottomDrawerAction(BottomDrawer))(OpenBottomDrawerButton)