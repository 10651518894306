import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import AlreadyActivated from "./AlreadyActivated";
import LinkExpired from "./LinkExpired";
import UpdatePassword from "./UpdatePassword";
import Login from "./Login";
import {getPoweredBy} from "../../utils/theme";
import LoggedOut from "./LoggedOut";
import NoUserFound from "./NoUserFound";
import Error from "./Error";


class LoginRouter extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route path='/already-activated' component={AlreadyActivated}/>
          <Route path='/link-expired' component={LinkExpired}/>
          <Route path='/forgot' component={ForgotPassword}/>
          <Route path='/update-password' component={UpdatePassword}/>
          <Route path='/logged-out' component={LoggedOut}/>
          <Route path='/user-not-found' component={NoUserFound}/>
          <Route path='/error' component={Error}/>
          <Route component={Login}/>
        </Switch>
        { getPoweredBy() }
      </>
    );
  }
}

export default LoginRouter;