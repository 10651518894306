// @flow
import React from 'react'
import ResourceForm from '../ResourceForm'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import KeysetMappingFeature from "./fields/KeysetMappingFeature";
import KeysetSelector from "./fields/KeysetSelector";

let defaultData = {
  "name": null,
};


let fields = {
  "hssKeysetIdentifier": {
    "Component": TextField,
    "name": "hssKeysetIdentifier",
    "label": "hssKeysetIdentifier"
  },
  "hssKeysetDescription": {
    "Component": TextField,
    "name": "hssKeysetDescription",
    "label": "hssKeysetDescription"
  },
  "keyset": {
    "Component": KeysetSelector,
    "name": "keyset",
    "label": "keyset"
  },
  "feature": {
    "Component": KeysetMappingFeature,
    "name": "feature",
    "label": "Feature"
  }
};

const KeysetMappingForm = (props: object) => {
  return <ResourceForm {...props} fields={fields} defaultData={{...props.defaultData, ...defaultData}} />
};

export default KeysetMappingForm
