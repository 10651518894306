// @flow
import React from 'react'
import {array, func, object} from "prop-types";

type S = {
  options: array
}
const withOptions = (fetchOptions: func) => (WrappedComponent: *) => {
  return class extends React.Component<object, S>{
    state = {
      options:[]
    }
    componentWillMount() {
      fetchOptions().then((options) => {
        this.setState({options})
      })
    }
    render() {
      return <WrappedComponent {...this.props} options={this.state.options} />
    }
  }
};
export default withOptions