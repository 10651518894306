// @flow
import React from 'react'
import RowAction from './RowAction'
import {BASE_URL} from "utils";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type P = {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}
class SendPasswordReset extends React.PureComponent<P> {
  render() {
    const {value, t, ...props} = this.props;
    return <RowAction
      {...props}
      endpoint={`${BASE_URL}/v1/accounts/command/createPasswordResetToken?account=${value}`}
      message={t('passwordResetSent')}
      label={t('sendPasswordReset')}
      errorMessage={t('noEmailAddressPasswordReset')}
    />
  }
}

export default withTranslation()(SendPasswordReset)
