import React, {Component} from 'react';
import GetApp from '@material-ui/icons/GetApp';
import IconButton from "@material-ui/core/IconButton";
import {BASE_URL} from 'utils';


const endpoint = `${BASE_URL}/v1/files/command/downloadFile?file=`;

export default (class FormatFile extends Component {

    download = () => {
        const {selfUrl} = this.props;
        let downloadLink = `${endpoint}${selfUrl}`;

        window.open(downloadLink, '_blank');
    };

    render() {

        return (
            <IconButton color="primary" aria-label="download file" component="span" onClick={this.download}>
                <GetApp fontSize="large"/>
            </IconButton>
        );
    }
})