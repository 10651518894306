import React from 'react';
import map from 'lodash/map';
import {getEmbeddedResource, getSelfLink, NestedSelector} from 'utils';
import {withStyles} from '@material-ui/core/styles';
import AsyncAutoComplete from "components/fields/AutoCompleteAsync";
import AutoComplete from "components/fields/AutoComplete";
import {searchCustomerByName} from "../../../../utils/search";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

class CustomerSelector extends React.Component {
  state = {
    customers: [],
    loaded: {},
    sitesByCustomer: {}
  };

  rollUpSites(customer) {
    let sites = map(customer.sites, site => ({...site, customer: customer}));
    if (!customer.parent) {
      return sites
    }
    sites = sites.concat(this.rollUpSites(customer.parent));
    return sites
  }

  loadOptions = (searchString, callback) => {
    const {name} = this.props;

    searchCustomerByName(searchString, "withSites").then((response) => {
      const sitesByCustomer = {...this.state.sitesByCustomer};
      const customerOptions = [];
      map(getEmbeddedResource(response, "customers"),
        customer => {
          const customerLink = getSelfLink(customer)
          customerOptions.push({"label": customer.name, "value": customerLink});
          if (!this.state.sitesByCustomer[customerLink]) {
            sitesByCustomer[customerLink] = map(this.rollUpSites(customer), site => ({
              "label": site.name,
              "value": getSelfLink(site)
            }))
          }
        }
      );
      this.setState({"showCustomers": true, customers: customerOptions, sitesByCustomer}, () => {
        const oneAndOnlyOption = NestedSelector.getOptionIfOnlyOption(customerOptions);
        if (oneAndOnlyOption) {
          this.onChange({[name]: oneAndOnlyOption});
        }
      })
      callback(customerOptions)

    })
  }
  onChange = state => (newData) => {
    const {sitesByCustomer} = state;
    const {onChange, readOnly, values} = this.props;
    if (readOnly) {
      return
    }
    if (NestedSelector.hasChanged(values, newData, "customer")) {
      newData["site"] = NestedSelector.getOptionIfOnlyOption(sitesByCustomer[newData["customer"]])
    }
    onChange(newData)
  };

  render() {
    const onChange = this.onChange(this.state)
    const {values, errors, validationErrors, help, readOnly, classes, cachedOptions, t} = this.props;
    const {customers, sitesByCustomer} = this.state;
    return (
      <div className={classes.container}>
        <AsyncAutoComplete
          value={values.customer}
          error={errors.customer}
          validationError={validationErrors.customer}
          help={help.customer}
          options={customers}
          name="customer"
          label={t('customer')}
          onChange={onChange}
          readOnly={readOnly}
          displayEmpty={false}
          classeName={classes.formControl}
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions={true}
          cachedOptions={cachedOptions.customer}
        />
        <AutoComplete
          value={values.site}
          error={errors.site}
          validationError={validationErrors.site}
          options={sitesByCustomer[values.customer] || []}
          name="site"
          label={t('site')}
          onChange={onChange}
          classeName={classes.formControl}
          cachedOptions={cachedOptions.site}
        />
      </div>
    )
  }
}

CustomerSelector = withTranslation()(CustomerSelector);
CustomerSelector = withStyles(styles)(CustomerSelector);
export default CustomerSelector