// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  formHelperText: {
    marginBottom: theme.spacing.unit,
  },
});

let ValidationError = ({id, error, classes}) => {
  return error ? <FormHelperText id={id} error={true} className={classes.formHelperText}>{error}</FormHelperText> : <p />
};

ValidationError = withStyles(styles)(ValidationError)
export default ValidationError