// @flow
import React from 'react'
import Paper from "@material-ui/core/Paper"
import AlertDialog from '../components/dialogs/AlertDialog'
import {BASE_URL, del} from 'utils'
import withControls from 'hoc/withControls'
import RelativeLink from "components/RelativeLink"
import {withTranslation} from "react-i18next"

type P = {
  resourceName: string,
  resourceId: string
}

type S = {
  message: ?string
}

export class ResourceDelete extends React.Component<P, S> {
  state = {
    message: null,
    deleteSuccess: false,
  }
  delete(resourceName, resourceId) {
    del(`${BASE_URL}/v1/${resourceName}/${resourceId}`).then(() => {
      this.setState({
        message: this.props.t("resourceDeletionSuccess"),
        deleteSuccess: true,
      })
    }).catch((err) => {
      console.error(err)
      this.setState({message: "Error: " + err.message})
    })
  }
  getRelativeLink() {
    if (this.state.deleteSuccess) return '../../'
    return '../'
  }
  render() {
    const {resourceName, resourceId, t} = this.props
    return (
      <Paper>
        <div className="resource-form">
          <p>{this.state.message}</p>
          <AlertDialog
            title={t('confirmDelete')}
            content={t('extendedConfirmDelete')}
            confirmText={t('delete')}
            cancelText={t('cancel')}
            execute={this.delete.bind(this, resourceName, resourceId)}
          ></AlertDialog>
          <RelativeLink to={this.getRelativeLink()} >{t('takeMeBack')}</RelativeLink>
        </div>
      </Paper>
    )
  }
}

ResourceDelete = withTranslation()(ResourceDelete)
ResourceDelete = withControls("delete")(ResourceDelete)
export default ResourceDelete
