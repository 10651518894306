// @flow
import React from 'react';
import map from 'lodash/map';
import _get from 'lodash/get';
import Field from 'components/fields/Field';
import wrapForm from 'hoc/wrapForm';
import {withRouter} from "react-router-dom";
import {func, object} from "prop-types";

type P = {
  schema: object,
  fields: object,
  data: object,
  labels: object,
  validationErrors: object,
  onChange: func,
  editing: boolean,
  resourceId: string
}

export class ResourceForm extends React.Component<P> {
  getNestedFields = (nestedFields: object) => {
    let {data, labels, validationErrors, schema} = this.props;
    let nestedValues = {};
    map(nestedFields, nestedField => {
      nestedValues[nestedField] = {
        value: data[nestedField],
        valueLabel: labels[nestedField],
        help: _get(schema, nestedField),
        error: !!validationErrors[nestedField],
        validationError: validationErrors[nestedField]
      }
    });
    return nestedValues
  };
  render() {
    const {schema, fields, data, labels, validationErrors, onChange, editing, resourceId} = this.props;
    return (
      <React.Fragment>
        {map(fields, (field, key) => {
          const validationError = _get(validationErrors, field.errorName, _get(validationErrors, key))
          return field.Component && (
            <Field
              key={key}
              value={data[field.name || key]}
              valueLabel={labels[field.name]}
              {...field}
              help={_get(schema, key, field.help)}
              nestedFields={this.getNestedFields(field.nestedFields)}
              validationError={validationError}
              error={!!validationError}
              onChange={onChange}
              readOnly={field.updatable === false && editing}
              resourceId={resourceId}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

ResourceForm = withRouter(wrapForm(ResourceForm));
export default ResourceForm
