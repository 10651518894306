import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import InfoPopup from './CellLocationInfoPopup'
import _ from 'lodash'

class CellLocationMap extends Component {
  state = {
    hover: false,
    googleMapLoaded: false,
    map: null,
    maps: null,
    center: null,
    pulseIntervalTimer: null,
  }
  
  // Circle and pulse parameters
  numberOfBaseCircles = 20
  numberOfPulseCircles = 20
  fillColor = "rgb(0, 0, 255)"
  pulseRate = 100
  totalInterval = this.pulseRate * this.numberOfPulseCircles
  pulseDelay = 30
  cleanupDelay = this.pulseDelay / 3
  initialOpacity = 0.02
  pulseOpacity = 0.05
  initialGrowthRate = 1.01
  pulseGrowthRate = 1.02
  getZoomLevel = accuracy => {
    if (accuracy > 320000) return 5
    if (accuracy > 160000) return 6
    if (accuracy > 80000) return 7
    if (accuracy > 40000) return 8
    if (accuracy > 20000) return 9
    if (accuracy > 10000) return 10
    if (accuracy > 5000) return 11
    if (accuracy > 2500) return 12
    return 13
  }
  
  // Circle operations
  drawCircle = (radius, fillOpacity) => new this.state.maps.Circle({
    strokeColor: 0,
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: this.fillColor,
    center: this.state.center,
    map: this.state.map,
    radius,
    fillOpacity,
  })
  
  removeCircle = circle => () => circle.setMap(null)

  // beware of recursion
  pulseCircle = (numberOfCircle, radius) => () => {
    const circle = this.drawCircle(radius, this.pulseOpacity)

    if (this.numberOfPulseCircles > numberOfCircle) {
      setTimeout(this.pulseCircle(numberOfCircle + 1, radius * this.pulseGrowthRate), this.pulseDelay)
    }

    setTimeout(this.removeCircle(circle), this.cleanupDelay * (this.numberOfPulseCircles - numberOfCircle))
  }
  
  // TODO: get rid of lodash
  drawInitialCircles = radius => {
    let finalCircle = null
    _.forEach(_.range(this.numberOfBaseCircles), () => {
      finalCircle = this.drawCircle(radius * this.initialGrowthRate, this.initialOpacity)
    })
    return finalCircle
  }

  // Interval timer callback
  pulse = radius => () => {
    if (this.state.map.zoom <= this.getZoomLevel(radius)) {
      this.pulseCircle(0, radius)()
    }
  }

  // Callback for Google Maps Wrapper
  apiIsLoaded = (map, maps, center, accuracy) => {
    this.setState({
      center: center,
      map: map,
      maps: maps,
      googleMapLoaded: true,
    })

    const finalCircle = this.drawInitialCircles(accuracy)

    this.setState({pulseIntervalTimer: setInterval(this.pulse(finalCircle.radius), this.totalInterval)})
    
    // Set event listeners
    this.state.maps.event.addListener(finalCircle, 'mouseover', () => {
      this.setState({"hover": true})
    })

    this.state.maps.event.addListener(finalCircle, 'mouseout', () => {
      this.setState({"hover": false})
    })
  }


  render() {
    const {hover} = this.state
    const {data} = this.props

    if (!data) {
      return null
    }

    const accuracy = data.accuracy * 1.5
    const zoom = this.getZoomLevel(accuracy || 0)
    const center = {
      lat: data.latitude,
      lng: data.longitude
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{key: "AIzaSyCkvCvHNLhZHEgRsTs1TIkz34CpN8-aT2A"}}
        yesIWantToUseGoogleMapApiInternals={true}
        defaultCenter={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({map, maps}) => this.apiIsLoaded(map, maps, center, accuracy)}
      >
        <InfoPopup
          lat={data.latitude}
          lng={data.longitude}
          accuracy={data.accuracy}
          city={data.city}
          state={data.state}
          country={data.country}
          postcode={data.postcode}
          hover={hover}
        />
      </GoogleMapReact>
    )
  }
}

export default CellLocationMap
