// @flow
import React from 'react'
import moment from "moment";
import {Button, Grid, Typography, Paper} from "@material-ui/core";
import {object} from "prop-types";
import * as Sentry from "@sentry/browser";
import {withTranslation} from 'react-i18next';

import {BASE_URL, postFormdata} from 'utils';
import DateRangeSelector, {LAST_MONTH, LAST_WEEK, THIS_MONTH, THIS_WEEK} from "components/selectors/DateRangeSelector";
import GridLoader from "components/GridLoader";
import CustomerSelector from "components/selectors/CustomerSelector";
import TimezoneSelector from "components/selectors/TimezoneSelector";
import Select from "../../components/fields/Select";
import TextField from "../../components/fields/TextField";
import Checkbox from "../../components/fields/Checkbox";

const initialState = {
  values: {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    timezone: "",
    useRecordTimestamp: false,
    type: "",
    imsiStartRange: "",
    imsiEndRange: "",
    customerRef: "",
    sftpAccessRef: ""
  },
  errors: {
    startDate: null,
    endDate: null,
    timezone: null,
    useRecordTimestamp: null,
    type: null,
    imsiStartRange: null,
    imsiEndRange: null,
    customerRef: null,
    sftpAccessRef: null
  },
  message: null,
  loaded: true
};

const options = [
  {label: "Daily", value: "Daily"},
  {label: "Monthly", value: "Monthly"}
];

type P = {
  classes: object,
  report: object
}

type S = {
  values: object,
  errors: object,
  loaded: boolean
}

class CdrProvider1ReportCard extends React.PureComponent<P, S> {

  state = initialState;

  getLoader = (loaded) => {
    if (!loaded) return <GridLoader/>;
    else return <div/>
  };

  launchReport = (uri: string) => {
    const {values} = this.state;
    const data = {
      startDate: values.startDate.format("YYYYMMDD"),
      endDate: values.endDate.format("YYYYMMDD"),
      timezone: values.timezone,
      useRecordTimestamp: values.useRecordTimestamp,
      type: values.type,
      imsiStartRange: values.imsiStartRange,
      imsiEndRange: values.imsiEndRange,
      customerRef: values.customerRef,
      sftpAccessRef: values.sftpAccessRef
    }
    const endpoint = `${BASE_URL}${uri}`;

    this.setState({
      loaded: false
    });

    // call backend
    postFormdata(endpoint, data).then((response) => {
      this.setState({
        message: response.result,
        loaded: true
      });
    }).catch((err) => {
      Sentry.captureException(err);
      this.setState({
        message: err.message,
        loaded: true
      })
    });

  };

  onChange = (newData) => {
    this.setState((state) => ({
      ...state, values: {...state.values, ...newData}
    }))
  };

  render() {
    const {values, errors, message, loaded} = this.state;
    const {report, t} = this.props;
    return (
      <Grid item spacing={16}>
        <Paper>
          <h4 className="featured card-title">{t('generateReport')}</h4>
          <div className="resource-form">
            <Grid container>
              <Grid item xs={12}>
                <DateRangeSelector
                  startDate={values.startDate}
                  startDateId="cdrProvider1ReportStartDateId"
                  endDate={values.endDate}
                  endDateId="cdrProvider1ReportEndDateId"
                  presets={[LAST_WEEK, THIS_WEEK, LAST_MONTH, THIS_MONTH]}
                  onChange={this.onChange}/>
                <TimezoneSelector label={t('timezone')} name="timezone"
                                  value={values.timezone}
                                  error={errors.timezone}
                                  onChange={this.onChange}/>
                <Select
                  label={t('type')}
                  name="type"
                  options={options}
                  value={values.type}
                  onChange={this.onChange}/>
                <Checkbox name="useRecordTimestamp" value={values.useRecordTimestamp}
                          label={t('useRecordTimestamp')} onChange={this.onChange}/>
                <TextField
                  label={t('imsiStartRangeFilter')}
                  name="imsiStartRange"
                  value={values.imsiStartRange}
                  onChange={this.onChange}/>
                <TextField
                  label={t('imsiEndRangeFilter')}
                  name="imsiEndRange"
                  value={values.imsiEndRange}
                  onChange={this.onChange}/>
                <CustomerSelector label={t('customer')} name="customerRef" value={values.customerRef}
                                  error={errors.customerRef}
                                  onChange={this.onChange}/>
                <TextField
                  label={t('sftpAccess')}
                  name="sftpAccessRef"
                  value={values.sftpAccessRef}
                  onChange={this.onChange}/>
                {message &&
                  <Typography variant="subtitle1" color="primary">
                    {message}
                  </Typography>
                }
                <Button variant="contained" color="primary"
                        onClick={() => this.launchReport(report.uri)}>{t('generateReport')}</Button>
              </Grid>
              {this.getLoader(loaded)}
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

CdrProvider1ReportCard = withTranslation()(CdrProvider1ReportCard);
export default CdrProvider1ReportCard