// @flow
import React from 'react'
import map from 'lodash/map'
import {getSelfLink} from "../../../utils";
import DefaultSnackbar from 'components/DefaultSnackbar'
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import {object} from 'prop-types';

type MessageType = "success" | "error"

type P = {
  value: object,
  row: object,
  resourceName: string
}
type S = {
  anchorEl: ?Element,
  open: boolean,
  message: ?string;
  messageType: ?MessageType
}

class RowActionMenu extends React.Component<P, S> {
  state = {
    anchorEl: null,
    open: false,
    message: null,
    messageType: null
  };

  handleClick = (event: *) => {
    if (this.state.anchorEl !== event.currentTarget) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  renderMessage = (messageType: MessageType) => (message: string) => {
    this.setState({ open: true, message, messageType });
  };
  render() {
    const {anchorEl, open, message, messageType} = this.state;
    const {value, actions} = this.props.value;
    const actionProps = {
      value,
      row: this.props.row,
      onClick: this.handleClose,
      renderMessage: this.renderMessage,
      endpoint: getSelfLink(this.props.row)
    };
    return (
      <div >
        <Button onClick={this.handleClick}>
          <Icon>more_horiz</Icon>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          disableEnforceFocus={true}
          style={{"textTransform": "uppercase"}}
        >
          { map(actions, ({name, Component, ...props}) => <Component key={name} type="menuItem" {...actionProps} {...props} resourceName={this.props.resourceName}/> )}
        </Menu>
        <DefaultSnackbar open={open} onClose={this.onClose} message={message} messageType={messageType}/>
      </div>
    )
  }
}

export default RowActionMenu