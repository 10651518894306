// @flow
import React from 'react'
import {get, BASE_URL, getEmbeddedResource, getSelfLink} from 'utils'
import map from 'lodash/map'
import TextField from 'components/fields/TextField'
import {array, func, object} from "prop-types";
import AutoComplete from "components/fields/AutoComplete";
import Checkbox from "components/fields/Checkbox";
import { withTranslation } from 'react-i18next';

type S = {
  validationError: string,
  error: boolean,
  options: array<object>
}

type P = {
  name: string,
  site: string,
  gatewayHost: string,
  onChange: func,
  label: string,
  values : array<string>,
  errors: array<string>,
  validationErrors: array<string>,
  showNatBox: boolean
}

class NetworkField extends React.Component<P, S> {
  state = {
    validationError: "",
    error: false,
    options: []
  };
  componentWillReceiveProps(nextProps: P, nextContext: any): void {
    if (nextProps.values.nat !== this.props.values.nat) {
      this.validateCidr(nextProps)
    }
  }
  componentDidMount(): void {
    get(`${BASE_URL}/v1/gateway-hosts/search/findBySite?site=${this.props.site}`).then((response) => {
      this.setState({
        options: map(getEmbeddedResource(response, "gateway-hosts"), gatewayHost => ({"label": gatewayHost.name, "value": getSelfLink(gatewayHost)}))
      });
    }).catch((error => {
      console.log(error)
    }))
  }
  onBlur = () => this.validateCidr(this.props);

  validateCidr = (props: P) => {
    if (props.values.nat === false && (props.values.host && props.values.network)) {
      get(`${BASE_URL}/v1/gateway-hosts/command/validateNoNatCidr?cidr=${props.values.network}&gatewayHost=${props.values.host}`).then((response) => {
        this.setState({validationError: "", error: false})
      }).catch((error => {
        // Not the prettiest
        console.log(error)
        this.setState({validationError: error.details[0].message, error: true})
      }))
    } else {
      this.setState({validationError: "", error: false})
    }
  }
  render() {
    const {values, validationErrors, errors, t} = this.props;
    return (
      <React.Fragment>
        {this.props.showNatBox && <Checkbox
          name="nat"
          label={t('enableNat')}
          value={values.nat}
          onChange={this.props.onChange}
          error={errors.nat}
          validationError={validationErrors.nat}
        />}
        <AutoComplete
          name="host"
          label={t('host')}
          value={values.host}
          onChange={this.props.onChange}
          displayEmpty={false}
          cacheOptions={true}
          options={this.state.options}
          error={errors.host}
          validationError={validationErrors.host}
        />
        {values.host && <TextField
          name="network"
          label={t('network')}
          value={values.network}
          onChange={this.props.onChange}
          margin="normal"
          type="text"
          onBlur={this.onBlur}
          help="The CIDR range of the PGW subnet. eg. 100.64.9.0/24"
          validationError={this.state.validationError || validationErrors.network}
          error={this.state.error || errors.network}
        />}
      </React.Fragment>
    )
  }
}
NetworkField = withTranslation()(NetworkField);
export default NetworkField