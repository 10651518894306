import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const MaintenanceDialog = (props) => (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Maintenance Mode</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This site is currently in maintenance mode. Please check again later.
        </DialogContentText>
      </DialogContent>
    </Dialog>
)

export default MaintenanceDialog