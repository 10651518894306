import React, {PureComponent} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  error: {
    color: theme.palette.secondary.light
  }
})
class ErrorText extends PureComponent {
  render() {
    const {classes, children} = this.props;
    return (
      <div className={classes.error}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(ErrorText);