// @flow

import React from 'react';
import Select from 'react-select';
import AutoCompleteDefault from "components/fields/AutoCompleteDefault";
import {func, object} from "prop-types";

type P = {
  cachedOptions?: object,
  options: object,
  multiple?: boolean,
  value: string,
  validationError:string,
  name: string,
  onChange: func,
  label: string,
  error: string,
  help?: string,
  className?: string,
  readOnly?: boolean,
  displayEmpty?: boolean,
}


const AutoComplete = (props: P) => <AutoCompleteDefault Component={Select} {...props} />;

export default AutoComplete;