import React from 'react'
import * as d3 from 'd3';
import {filter, map, keys, values, size} from 'lodash'
import {formatBytes, cleanId} from 'utils'
import {func, object} from "prop-types";
import {color, otherColor} from "./colours";

type P = {
  id: string,
  onChange: func,
  data: object,
  getHighlight: func,
  getTooltip: func;
}
type S = {
  width: number,
  height: number
}



class StackedBarChart extends React.Component<P, S> {

  parseDate: func;
  xAxis: func;
  yAxis: func;
  container: object;

  state = {
    width: 0,
    height: 0
  };

  initChart = () => {
    this.drawChart()
  };
  getDate(index) {
    return this.parseDate(keys(this.props.data)[index])
  }
  drawChart = () => {
    const {id, data, onChange, formatDate} = this.props;
    this.parseDate = d3.utcParse("%Y-%m-%dT%H:%M%Z");
    let stackKeys = filter(keys(values(data)[0]), key => key !== "date");
    const m = size(data);

    const stack = d3.stack().keys(stackKeys).order(d3.stackOrderDescending);

    stack.value((d, key) => d[key].y);

    let count = 1;
    const stackValues = map(data, (d) => {
      const row = {};
      stackKeys.forEach(stackKey => {
        row[stackKey] = {x:count, y: +d[stackKey]}
      });
      count++;
      return row
    });

    this.layers = stack(stackValues);
    this.yStackMax = d3.max(this.layers, layer => d3.max(layer, d => d[1]));

    const margin = {top: 40, right: 150, bottom: 20, left: 80},
      width = this.state.width - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    this.x = d3.scaleBand()
      .domain(d3.range(m))
      .rangeRound([0, width], .08);

    this.y = d3.scaleLinear()
      .domain([0, this.yStackMax])
      .range([height, 0]);

    this.xAxis = d3.axisBottom(this.x).ticks(18).tickFormat(key => formatDate(this.getDate(key)) ).tickValues(
      this.x.domain().filter(
        function(d,i){ return !(i%4)})

    ).tickSize(-height);

    this.yAxis = d3.axisRight(this.y).tickFormat(formatBytes).tickSize(width);


    const svg = d3.select(`#${cleanId(id)}`)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    this.layer = svg.selectAll(".layer")
      .data(this.layers)
      .enter().append("g")
      .attr("class", "layer")
      .attr("layernum", (d, i) => i)
      .style("fill", (d, i) =>  i > 4 ? otherColor(d.key) : color(d.key));

    this.rect = this.layer.selectAll("rect")
      .data(d => d)
      .enter().append("rect")
      .attr("x", (d, i) => this.x(i))
      .attr("y", height || 0)
      .attr("width", this.x.bandwidth() - 1)
      .attr("height", 0)
      .on("mouseover", () => onChange({"tooltipVisible": true}))
      .on("mouseout", () => onChange({"tooltipVisible": false}))
      .on("mousemove", (d, i) => {
        onChange({"tooltip": {
            date:this.getDate(i),
            data:d,
            x:d3.event.clientX,
            y:d3.event.clientY,
          }});
      });

    this.rect.transition()
      .delay((d, i) => i * 10)
      .attr("y", d => this.y(d[1]))
      .attr("height", d => this.y(d[0]) - this.y(d[1]));


    svg.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + height + ")")
      .call(this.customXAxis);

    svg.append("g")
      .attr("class", "axis")
      .call(this.customYAxis)
      .append("text")
      .attr("x", 2)
      .attr("y", (this.y(this.y.ticks().pop()) + 0.5) || 0)
      .attr("dy", "0.32em")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "start");

    const legend = svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(stackKeys)
      .enter().append("g")
      .attr("transform", (d, i) => "translate(0," + i * 20 + ")");

    legend.append("rect")
      .attr("x", width + 96)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", (d,i) =>  i > 4 ? otherColor(d) : color(d));

    legend.append("text")
      .attr("x", width + 76)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text((d, i) => i > 5 ? "Other" : d);

    const tooltip = svg.append("g")
      .attr("class", "chart-tooltip")
      .style("display", "none");

    tooltip.append("g")
      .html(this.props.getTooltip());

    this.transitionStacked()
  };

  transitionStacked = () => {
    this.y.domain([0, this.yStackMax]);

    this.rect.transition()
      .duration(500)
      .delay((d, i) => i * 10)
      .attr("y", d => this.y(d[1]))
      .attr("height", d => this.y(d[0]) - this.y(d[1]))
      .transition()
      .attr("x", (d,i) => this.x(i))
      .attr("width", this.x.bandwidth()-1)
  };

  customYAxis = (g: object) => {
    g.call(this.yAxis);
    g.select(".domain").remove();
    g.selectAll(".tick line").attr("stroke", "#777").attr("stroke-dasharray", "2,2").attr("opacity", ".4");
    g.selectAll(".tick text").attr("x", -60);
  };
  customXAxis = (g: object) => {
    g.call(this.xAxis);
    g.select(".domain").remove();
    g.selectAll(".tick line").attr("stroke", "#777").attr("stroke-dasharray", "2,2").attr("opacity", ".4");
    g.selectAll(".tick text").attr("y", 8);
  };
  getContainer = (container: object) => {
    if (!this.container) {
      this.container = container;
      this.setState({width: container.offsetWidth, height: container.offsetWidth/3 }, () => {
        this.initChart()
      })
    }
  };
  render() {
    const {id} = this.props;
    return (
      <div ref={this.getContainer} style={{width:"100%", height:"100%"}}>
        <svg id={cleanId(id)} width={this.state.width} height={this.state.height}/>
      </div>
    )
  }
}


export default StackedBarChart