// @flow
import React from "react";
import {moduleButtons, resourceNames, detailModules, featureFlags} from "config";
import {connect} from "redux-zero/react";
import SessionHistory from "components/table/session/SessionHistory";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {withStyles} from '@material-ui/core/styles';
import ResourceCardExpansionPanel from "resource/detail/card/ResourceCardExpansionPanel";
import ResourceCardTable from "resource/detail/card/ResourceCardTable";
import ResourceCardChips from './ResourceCardChips';
import {withTranslation} from 'react-i18next';

import * as Sentry from "@sentry/browser";

import SubscriberImsiButton from "components/table/subImsi/SubscriberImsiButton";
import PrivateRadioButton from "components/table/enodeb/PrivateRadioButton";
import SftpAccessButton from "components/table/sftpAccess/SftpAccessButton";
import MonitoringSiteButton from 'components/table/monitoring/MonitoringSiteButton';
import ApiTokenButton from "components/table/apiToken/ApiTokenButton";
import GatewayHostButton from "components/table/gatewayHostFeature/GatewayHostButton";
import KeysetMappingButton from "components/table/keysetMapping/KeysetMappingButton";
import MonitoringButton from "components/table/monitoring/MonitoringButton";
import SmsCdr from "components/table/smscdr/SmsCdr";

const style = theme => ({
  cardRoot: {
    width: "100%",
    maxHeight: "600px",
    overflowY: "auto"
  },
  card: {
    width: "100%",
  },
  error: {
    padding: theme.spacing.unit * 2,
    textAlign: "center"
  }
});

type P = {
  model: string,
  data: object,
  isRoot: boolean,
  classes: object,
  rootValue: string,
  style?: object,
  user: object,
  openBottomDrawer: func
}

type S = {
  error: ?Error
}


class ResourceCard extends React.Component<P, S> {

  state = {
    error: null
  };

  FIELDS_TO_IGNORE = ["extensions", "subscriberSessions", "feature"];
  FIELDS_TO_CHIP = ["groups", "systems", "sites", "customer", "parent", "children", "system", "profile"];

  componentDidCatch(error, errorInfo) {
    this.setState({error});
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  getModule(model) {
    const detailModuleData = detailModules[model];
    if (detailModuleData) {
      return detailModuleData
    }
  }

  getContent = () => {
    const {model, data, isRoot, user} = this.props;
    let items;

    if (!data) {
      return null
    }

    if (this.FIELDS_TO_IGNORE.includes(model)) {
      return
    }

    const detailModule = this.getModule(model);
    if (detailModule) {
      if (user && detailModule.roles.includes(user.role)) {
        return detailModule.getComponent(data)
      }
      return null
    }

    if (isRoot && this.FIELDS_TO_CHIP.includes(model)) {
      items = <ResourceCardChips data={data} model={model}/>
    } else {
      items = <ResourceCardTable data={data} model={model} user={user}/>
    }

    if (!items || items.length === 0) {
      return null
    }

    return (
      <div>
        {items}
      </div>
    )
  };
  getActions = model => {
    const moduleProps = moduleButtons[model]
    if (model === "sessions") {
      return [<SessionHistory subscriber={this.props.rootValue} {...moduleProps} label="Session History" />]
    }
    if (model === "keysetMappingSiteFeature") {
      return [<KeysetMappingButton row={{feature:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "gatewayHostFeature") {
      return [<GatewayHostButton row={{feature:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "imsis") {
      return [<SubscriberImsiButton row={{subscriber:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "privateRadioSiteFeature") {
      return [<PrivateRadioButton row={{feature:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "sftpAccessCustomerFeature") {
      return [<SftpAccessButton row={{feature:this.props.rootValue}}   {...moduleProps} label="Add" />]
    }
    if (model === "monitoringSiteFeature") {
      return [<MonitoringButton row={{feature:this.props.rootValue}}  {...moduleProps} label="Add" />]
    }
    if (model === "apiTokenSiteFeature") {
      return [<ApiTokenButton row={{site:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "apiTokenAccountFeature") {
      return [<ApiTokenButton row={{account:this.props.rootValue}} {...moduleProps} label="Add" />]
    }
    if (model === "sms") {
      return [<SmsCdr subscriber={this.props.rootValue} {...moduleProps} label="Log History" />]
    }
    return []
  };

  getSecondPanel(model) {
    if (model !== 'monitoringSiteFeature') return null
    if (!this.props.data) return null
    if (!this.props.data.extensions) return null
    if (!detailModules['monitoringSiteFeature'].roles.includes(this.props.user.role)) return null

    const secondPanel = {}
    secondPanel.title = 'Monitoring'
    secondPanel.content = <ResourceCardTable data={{extensions: this.props.data.extensions}} model={model} user={this.props.user}/>;
    secondPanel.action = featureFlags.get('addSiteMonitor')
      ? [<MonitoringSiteButton value={this.props.rootValue} label={this.props.t('addSiteMonitor')}/>]
      : []

    return secondPanel
  }

  getFirstPanel(model) {
    const firstPanel = {}
    firstPanel.content = this.getContent()
    if (!firstPanel.content) return null

    firstPanel.title = resourceNames[model.toLowerCase()] || model
    if (firstPanel.title === this.props.t('Monitoring')) firstPanel.title = this.props.t('monitoringEndpoints')
    firstPanel.action = this.getActions(model)

    return firstPanel
  }

  render() {
    const {error} = this.state;
    const {model, classes, style} = this.props;
    let firstPanel = this.getFirstPanel(model)
    let secondPanel = this.getSecondPanel(model)
    // quick hack for monitoring
    if (secondPanel) {
      let p = firstPanel
      firstPanel = secondPanel
      secondPanel = p
    }

    return (
      <Grid item className={classes.cardRoot} style={style}>
        <Card className={classes.card}>
          {error && <p className={classes.error}>Could not load card</p>}
          {firstPanel && <ResourceCardExpansionPanel title={firstPanel.title} content={firstPanel.content} actions={firstPanel.action}/>}
          {secondPanel && <ResourceCardExpansionPanel title={secondPanel.title} content={secondPanel.content} actions={secondPanel.action}/>}
        </Card>
      </Grid>
    )
  }
}

const mapToProps = ({user}) => ({user: user || {}});

ResourceCard = connect(mapToProps)(ResourceCard)
ResourceCard = withTranslation()(ResourceCard)
ResourceCard = withStyles(style)(ResourceCard)
export default ResourceCard