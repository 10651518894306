// @flow
import React from 'react'
import Button from '@material-ui/core/Button';
import RelativeLink from "components/RelativeLink";
import { withTranslation } from 'react-i18next'

type P = {
  link: string,
  label: string
}

class NavButton extends React.PureComponent<P> {
  render() {
    const {link, label, t} = this.props;
      return (
        <RelativeLink className="body-font" to={`${link}`}>
          <Button variant="outlined">
            {t(label)}
          </Button>
        </RelativeLink>
      )
  }
}

export default withTranslation()(NavButton)
