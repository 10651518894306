import React, {PureComponent} from 'react';

type P = {
  className: string,
  errorMessage: string
}

class ErrorPage extends PureComponent<P> {
  render() {
    const {className, errorMessage} = this.props;
    return (
      <div className={className}>
        {errorMessage}
      </div>
    );
  }
}

export default ErrorPage;