// @flow
import React from 'react'
import {BASE_URL, filterByRole} from 'utils'
import {headings} from 'config'
import bulkActions from 'bulkActions'
import ResourceTable from './ResourceTable'
import {func, object} from "prop-types";

type P = {
  history: object,
  match: object,
  location: object,
  user: object,
  permissions: object,
}

type S = {
  polling: ?func
}

class ResourceList extends React.Component<P, S> {
  state = {
    polling: null
  };
  updatePoll = (poll: func) => {
    const urlHash = this.props.location.key;
    this.setState({polling: setTimeout(poll(urlHash), 5000)})
  };
  clearPoll = () => {
    this.setState({polling: clearTimeout(this.state.polling)})
  };
  componentWillUnmount() {
    this.clearPoll()
  }
  render() {
    const urlHash = this.props.location.key;
    const resourceName = this.props.match.params.resourceName;
    const TableComponent = headings[resourceName].listComponent || ResourceTable;
    const bulkActionsByResource = bulkActions[resourceName] || [];
    return (
      <TableComponent
        key={resourceName}
        resourceName={resourceName}
        baseUrl={`${BASE_URL}/v1/${resourceName}`}
        hasExportToCSV={headings[resourceName].exportCSV}
        hasSearch={headings[resourceName].search}
        bulkActions={filterByRole(bulkActionsByResource, this.props.user)}
        user={this.props.user}
        permissions={this.props.permissions}
        polling={this.state.polling}
        updatePoll={this.updatePoll}
        clearPoll={this.clearPoll}
        urlHash={urlHash}
      />
    )
  }
}

export default ResourceList
