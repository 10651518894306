// @flow
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {patch} from "utils";
import _get from "lodash/get";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type P = {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}

class DisablePrivateRadio extends React.PureComponent<P> {
  onClick = () => {
    const {endpoint, renderMessage, t} = this.props;
    patch(endpoint, {"status": "DISABLED"}).then((response) => {
      renderMessage("success")(t('privateRadioDisableSuccess'));
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], t('privateRadioDisableError')));
    })
  };
  render() {
    const {t} = this.props;
    return <MenuItem onClick={this.onClick}><span>{t('disable')}</span></MenuItem>
  }
}

export default withTranslation()(DisablePrivateRadio);
