// @flow
import React, {PureComponent} from 'react';

import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import LoginDialogHeader from "./LoginDialogHeader";
import LoginRouter from "./LoginRouter";

class LoginDialog extends PureComponent<*> {
  render() {
    return (
      <Dialog open={true}>
        <LoginDialogHeader/>
        <DialogContent>
          <LoginRouter/>
        </DialogContent>
      </Dialog>
    )
  }
}
export default LoginDialog