// @flow
import React from 'react'
import ResourceForm from 'resource/forms/ResourceForm'
import Extensions from 'components/fields/Extensions'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import Select from "../../../components/fields/Select";

let defaultData = {
  "name": null,
  "location": null,
  "type": null,
  "uuid": null,
  "extensions": {},
  "customer": null,
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "location": {
    "Component": TextField,
    "name": "location",
    "label": "Location"
  },
  "type": {
    "Component": Select,
    "options": [
      {
        "value": "xCore",
        "label": "xCore"
      },
      {
        "value": "xRouter",
        "label": "xRouter"
      }
    ],
    "name": "type",
    "label": "Type"
  },
  "uuid": {
    "Component": TextField,
    "name": "uuid",
    "label": "UUID",
  },
  "extensions": {
    "Component": Extensions,
  },
  "customer": {
    "Component": (props) => <TextField
      value={props.value.name}
      label="Customer"
      readOnly={true} />,
  }
};

const EditSiteForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default EditSiteForm
