import React, {PureComponent} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import {getLogoUrl} from "../../utils";

const styles = theme => ({
  dialogTitle: {
    paddingBottom: 0,
    textAlign: "center"
  },
  logoContainer: {
    margin: "0 auto",
    maxWidth: "140px",
    marginBottom: theme.spacing.unit
  },
  logo: {
    width: '100%'
  },
});


class LoginDialogHeader extends PureComponent {
  render() {
    const {classes} = this.props;
    return (
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <div className={classes.logoContainer}>
          <img src={getLogoUrl()}  className={classes.logo} alt="Expeto"/>
        </div>
      </DialogTitle>
    );
  }
}

export default withStyles(styles)(LoginDialogHeader);