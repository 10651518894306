import React, {Component} from 'react';
import ResourceCard from "../resource/detail/card/ResourceCard";
import Card from "@material-ui/core/Card";

class ScriptCardContainer extends Component {
  render() {
    // This extra card is weird but it gives us the right elevation
    return (
      <Card><ResourceCard {...this.props} style={{"maxHeight": "100%"}}/> </Card>
    );
  }
}

export default ScriptCardContainer;