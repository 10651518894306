// @flow
import React from 'react'
import ResourceForm from 'resource/forms/ResourceForm'
import CustomerSelect from 'resource/forms/accounts/fields/CustomerSelect'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import RoleSelect from "./fields/RoleSelect";

let defaultData = {
  "emailAddress": null,
  "password": null,
  "customer": null,
  "role": null
};

let superUserFields = {
  "emailAddress": {
    "name": "emailAddress",
    "Component": TextField,
    "label": "emailAddress",
    "errorName": "email_address"
  },
  "password": {
    "name": "password",
    "Component": TextField,
    "type": "password",
    "label": "password"
  },
  "role": {
    "name": "role",
    "Component": RoleSelect,
    "label": "role",
    "errorName": "role"
  },
  "customer": {
    "label": "customer",
    "labelPath": "name",
    "name": "customer",
    "Component": CustomerSelect,
    "relationship": "one",
    "selectDefault": true,
    "displayEmpty": false,
  },
};

const AccountForm = (props: object) => (
  <ResourceForm {...props} fields={superUserFields} defaultData={defaultData} />
);

export default AccountForm
