import React, {Component} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from "@material-ui/core/Typography";

export default (class FormatBoolean extends Component {
    getIcon = (value) => {
        if (value === "false") {
            return <ClearIcon/>
        }
        if (value === "true") {
            return <DoneIcon/>
        }
        return <ErrorIcon/>
    };

    render() {
        const {value} = this.props;
        return (
            <Typography align="left">{this.getIcon(value)}</Typography>
        );
    }
})
