// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import CustomerSelector from 'components/selectors/CustomerSelector';
import Extensions from 'components/fields/Extensions';
import {object} from "prop-types";


let defaultData = {
  "name":null,
  "extensions": {},
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "parent": {
    "name": "parent",
    "label": "Parent",
    "labelPath": "name",
    "Component": CustomerSelector,
    "relationship": "one",
    "updatable": false
  },
  "extensions": {
    "Component": Extensions,
  },
};

const CustomerForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={defaultData} />
);

export default CustomerForm
