// @flow
import React from 'react'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {func} from "prop-types";
import { connect } from "redux-zero/react";
import ConfirmButton from "../ConfirmButton";
import { useTranslation } from 'react-i18next';

const mapToProps = ({ disableEventPropagation }) => ({ disableEventPropagation });

const FormActions = connect(
  mapToProps
) (({disableEventPropagation, cancelLink, onSubmit, hideCancel}: {disableEventPropagation: boolean, cancelLink: string, onSubmit:func, hideCancel:boolean}) => {
  const { t } = useTranslation();
  const buttonProps = {variant:"contained", color:"primary", onClick:onSubmit, children: t('save')};
  return (
    <React.Fragment>
      {!disableEventPropagation
        ? <Button {...buttonProps} />
        : <ConfirmButton {...buttonProps}
                         confirmMessage={t('developerModeEventWarning')} />
      }
      {!hideCancel && <Link className="body-font" to={cancelLink}>
        <Button color="secondary">
          {t('cancel')}
        </Button>
      </Link>}
    </React.Fragment>
  )
});

export default FormActions