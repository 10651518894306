import {getHeaders, handleResponse} from 'utils/fetch/common';

export function del(url) {
  let buildURL = `${url}`;
  return fetch(buildURL, {
    method:"delete",
    credentials: 'include',
    headers: {
      ...getHeaders(),
    }
  }).then(handleResponse);
}