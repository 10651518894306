// @flow
import React from 'react';
import map from 'lodash/map';
import partial from 'lodash/partial';
import {get, getEmbeddedResource, getSelfLink, NestedSelector} from 'utils';
import {withStyles} from '@material-ui/core/styles';
import {array, func, object} from "prop-types";
import {searchKeysetByName, searchKeysetByCustomerAndName} from "utils/search";
import AsyncAutoComplete from "components/fields/AutoCompleteAsync";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

type P = {
  name: string,
  onChange: func,
  readOnly: boolean,
  label: string,
  value: object,
  valueLabel: string,
  error: string,
  validationError: string,
  help: string,
  displayEmpty: boolean
}
type S = {
  keysets: array,
  showkeysets: boolean,
  searchString: string
}

class KeysetSelector extends React.Component<P, S> {
  state = {
    keysets: [],
    showkeysets: false,
    searchString: "",
  };

  // if all we have is the self link, go and fetch the name so we can show it on the form
  componentWillMount(): void {
    const {name, value} = this.props;

    // if we already have the self link and the name
    const selfLink = getSelfLink(value);
    if (selfLink && value.name) {
      const keysets = [{
        "label": value.name,
        "value": selfLink
      }];
      // set options to the options we just fetch and bump the onChange so the component will update
      this.setState({keysets}, () => {
        this.onChange({[name]: selfLink});
      });
    }

    // value is not a self keyset link
    else if (value && value.includes && value.includes("keyset")) {
      get(value).then(response => {
        // we do not currently have the name of the keyset so go fetch it
        // we are going to set the existing keyset as an option so the select will show the name
        const selfLink = getSelfLink(response);
        const keysets = [{
          "label": response.name,
          "value": selfLink
        }];
        // set options to the options we just fetch and bump the onChange so the component will update
        this.setState({keysets}, () => {
          this.onChange({[name]: selfLink});
        });
      })
    }
  }

  loadOptions = (searchString, callback) => {
    const {name, displayEmpty, customer} = this.props;
    const searchFunction = customer ? partial(searchKeysetByCustomerAndName, customer) : searchKeysetByName;
    searchFunction(searchString).then((response) => {
      const keysets = map(getEmbeddedResource(response, "keysets"), keyset => ({
        "label": keyset.name,
        "value": getSelfLink(keyset)
      }));

      this.setState({"showkeysets": true, keysets}, () => {
        const oneAndOnlyOption = NestedSelector.getOptionIfOnlyOption(keysets);
        if (oneAndOnlyOption) {
          this.onChange({[name]: oneAndOnlyOption});
        }
      });
      if (displayEmpty === true) {
        keysets.push({"label": "None", "value": null});
      }
      callback(keysets)
    })
  };
  onChange = (newData) => {
    const {onChange, readOnly} = this.props;
    if (readOnly) {
      return
    }
    onChange(newData)
  };

  render() {
    const {label, value, valueLabel, error, validationError, help, readOnly, name, t} = this.props;
    const {keysets} = this.state;
    return (
      <div>
        <AsyncAutoComplete
          value={value}
          error={error}
          validationError={validationError}
          help={help}
          options={keysets}
          name={name}
          label={t(label)}
          onChange={this.onChange}
          readOnly={readOnly}
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions={true}
          cachedOptions={{value: valueLabel}}
        />
      </div>
    )
  }
}

KeysetSelector = withTranslation()(KeysetSelector);
KeysetSelector = withStyles(styles)(KeysetSelector);
export default KeysetSelector