import React from 'react';
import {FormInner} from "../../form/FormInner";
import {connect} from "redux-zero/react";
import bottomDrawerAction from "../bottomDrawerAction";
import MonitoringSiteForm from "resource/forms/monitoring/MonitoringSiteForm";
import OpenBottomDrawerButton from "../../row/actions/OpenBottomDrawerButton";

const BottomDrawer = (props) => (
  <FormInner>
    <MonitoringSiteForm {...props} />
  </FormInner>
)

export default connect(null, bottomDrawerAction(BottomDrawer))(OpenBottomDrawerButton)
