
export class NestedSelector {

  static getOptionIfOnlyOption(options, value=null) {
    if (value) {
      options = options[value]
    }
    return options && options.length === 1 ? options[0].value : null
  };

  static hasChanged(values, data, fieldName) {
    return data[fieldName] !== undefined && data[fieldName] !== values[fieldName]
  };

  static getPrepopulatedSearchString = (cachedOptions, value) => {
    if (!cachedOptions) {
      return ""
    }
    return cachedOptions[value] || ""
  }
}
