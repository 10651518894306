import {getHeaders, handleResponse} from 'utils/fetch/common';


export function patch(url, data) {
  return fetch(`${url}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
    },
    body: JSON.stringify(data)
  }).then(handleResponse);
}