// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';

let defaultData = {
  "name": null,
  "site": null
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "feature": {
    "Component": "text",
    "name": "feature",
    "label": "Feature"
  },
};

const ApiTokenForm = (props: object) => {
  return <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}}/>
}

export default ApiTokenForm
