import React, {Component} from 'react';
import {connect} from 'redux-zero/react'
import TextField from "./fields/TextField";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import {Link} from "react-router-dom";

import LoginDialogHeader from "./login/LoginDialogHeader";
import DialogContent from "@material-ui/core/es/DialogContent";
import UpdatePassword from "./login/UpdatePassword";
import Dialog from "@material-ui/core/es/Dialog";
import {BASE_URL, getUser, patch} from "../utils";
import {forEach} from 'lodash'
import DefaultSnackbar from "./DefaultSnackbar";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  resourceForm: {
    "padding": theme.spacing.unit * 2,
    "paddingBottom": theme.spacing.unit * 4,
    "background": "#fff"
  },
  resourceFormFields: {
    "max-width": "600px"
  },
  updatePassword: {
    "padding": `0px ${theme.spacing.unit * 2}px`,
    verticalAlign: "-webkit-baseline-middle"
  }
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: props.user.emailAddress,
      validationErrors: {},
      snackOpen: false,
      message: null,
      messageType: "success",
      showComponent: false
    }
  }
  openMenu = () => {
    this.setState({open: true})
  };
  closeMenu = () => {
    this.setState({open: false})
  };
  onChange = (data) => {
    this.setState({...this.state, ...data})
  };
  componentWillMount = () => {
    getUser(`${BASE_URL}/v1/accounts/command/currentUser?projection=withRole`).then((response) => {
      if (response !== undefined) {
        response.username = response.id;
        this.props.setUser(response);
        this.setState({emailAddress: response.emailAddress})
      }
    })
  };
  onSubmit = (e) => {
    e.preventDefault();
    patch(`${BASE_URL}/v1/accounts/${this.props.user.username}`, {"emailAddress": this.state.emailAddress})
      .then((response) => {
        this.props.updateEmailAddress(response.emailAddress)
        this.setState({snackOpen: true, message: "Email address successfully updated", messageType: "success"})
      })
      .catch((response) => {
        if (response.error === "ValidationFailure") {
          const validationErrors = {};
          forEach(response.details, error => validationErrors[error.property] = error.message);
          this.setState({validationErrors})
        }
      })
  };

  onSnackClose = () => {
    this.setState({snackOpen: false})
  }
  render() {
    const {classes, user, t} = this.props;
    const {validationErrors, emailAddress, open, snackOpen, message, messageType, showComponent} = this.state;
    return (
      <Card className={classes.resourceForm}>
        <h3>{t('accountDetails')}</h3>
        <form id="account-details-form" className={classes.resourceFormFields} onSubmit={this.onSubmit}>
          <TextField name="customer" label={t("customer")} value={user.customer ? user.customer.name : "Expeto"}
                     readOnly={true}/>
          <TextField name="emailAddress" label={t('emailAddress')} value={emailAddress} onChange={this.onChange}
                     validationError={validationErrors["emailAddress"]}
                     error={validationErrors["emailAddress"]}
          />
          <FormControl margin="none">
            <Button id="account-details-form-button" type="submit" variant="contained" color="primary">
              {t('save')}
            </Button>
          </FormControl>
          <FormControl margin="none">
            <Link id="open-update-password-dialog" className={classes.updatePassword} onClick={this.openMenu}>
              {t('updatePassword')}
            </Link>
          </FormControl>
        </form>
        <Dialog open={open}><LoginDialogHeader/>
          <DialogContent>
            <UpdatePassword/>
            <Link id="close-update-password-dialog" onClick={this.closeMenu}>
              {t('backToProfile')}
            </Link>
          </DialogContent>
        </Dialog>
        <DefaultSnackbar open={snackOpen} onClose={this.onSnackClose} message={message} messageType={messageType}/>
      </Card>
    );
  }
}

const actions = () => ({
  updateEmailAddress: (state, emailAddress) => ({user: {...state.user, emailAddress}}),
  setUser: (state, user) => ({user})
});
const mapToProps = ({user}) => ({user: user || {}});

Profile = connect(mapToProps, actions)(Profile);
Profile = withStyles(styles)(Profile);
Profile = withTranslation()(Profile);

export default Profile;