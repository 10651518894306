// @flow
import React from 'react'
import Button from '@material-ui/core/Button';
import {array, func, object} from "prop-types";
import { withTranslation } from "react-i18next";

type P = {
  onClick: func,
  children: ?array,
  confirmMessage: string,
  Component: object,
  t: object
}
type S = {
  confirmed: boolean
}

class ConfirmButton extends React.Component<P, S> {
  onClick: func;
  constructor(props: object) {
    super(props);
    this.state = {
      "confirmed": false
    };
    this.onClick = () => {
      if (this.state.confirmed) {
        this.setState({confirmed:false});
        props.onClick.apply(arguments)
      } else {
        this.setState({confirmed:true})
      }
    }
  }
  render() {
    const {children, confirmMessage, t, Component, ...props} = this.props;
    if (this.state.confirmed) {
      return <Component {...props} color="secondary" onClick={this.onClick}>{confirmMessage || t("areYouSure")}</Component>
    }
    return (
        <Component {...props} onClick={this.onClick}>{children}</Component>
    )
  }
}

ConfirmButton.defaultProps = {
  Component: Button
}

export default withTranslation()(ConfirmButton);
