export default {
  "list": {
    "default": [
      {"name": "new", "label": "new", "link": "/new"},
    ],
  },
  "detail": {
    "default": [
      {"name": "list", "label": "viewAll", "link": "/.."},
      {"name": "new", "label": "new", "link": "../new"},
      {"name": "edit", "label": "edit", "link": "/edit"},
      {"name": "delete", "label": "delete", "link": "/delete"},
      {"name": "history", "label": "history", "link": "/history"}
    ],
  },
  "new": {
    "default": [
      {"name": "list", "label": "viewAll", "link": "/.."}
    ]
  },
  "edit": {
    "default": [
      {"name": "list", "label": "viewAll", "link": "../.."},
      {"name": "detail", "label": "details", "link": "/.."},
      {"name": "history", "label": "history", "link": "../history"}
    ]
  },
  "history": {
    "default": [
      {"name": "list", "label": "viewAll", "link": "/../.."},
      {"name": "detail", "label": "details", "link": "/.."},
      {"name": "new", "label": "new", "link": "../../new"},
      {"name": "edit", "label": "edit", "link": "../edit"},
      {"name": "delete", "label": "delete", "link": "../delete"},
    ]
  }
}