// @flow
import React from 'react'
import { map, size, get } from 'lodash';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import Card from '@material-ui/core/Card';
import { object } from 'prop-types';
import MaterialTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import { Link } from "react-router-dom";

import { cleanSelfLink } from "utils";
import { useTranslation } from 'react-i18next';
import { headings } from "config"
import ActionProvider from "../../list/ActionProvider";

const DetailRow = (props) => {
  const {t} = useTranslation();
  if (!size(get(props.row, props.path))) {
    return <p style={{ textAlign: "center", paddingTop: "1rem" }}>{t('noExtensions')}</p>
  }
  return (
    <Card>
      <MaterialTable>
        <TableBody>
          {map(get(props.row, props.path), (value, key) => (
            <TableRow>
              <TableCell>{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MaterialTable>
    </Card>
  )
};

const wrapDetailRow = (path) => (props) => {
  return <DetailRow {...props} path={path} />
};

type P = {
  columns: object,
  rows: object,
  primaryColumns?: object,
  details: boolean,
  CustomActionProvider?: ?object
}

const PrimaryKeyLinkFormatter = ({ value }) => {
  return (
    <div>
      <Link to={cleanSelfLink(value.link, 2)}>{value.value}</Link>
    </div>
  )
};

const PrimaryKeyProviderLink = props => (
  <DataTypeProvider
    formatterComponent={PrimaryKeyLinkFormatter}
    {...props}
  />
);

class ModuleTable extends React.PureComponent<P> {
  render() {
    const { rows, resourceName, details } = this.props;
    const detailsPath = this.props.detailsPath || ["extensions"]
    const columns = this.props.columns || headings[resourceName].list
    const primaryColumns = get(headings, [resourceName, "primaryColumns"])
    return (
      <Grid
        rows={rows}
        columns={columns}>
        {details && <RowDetailState />}
        <Table />
        <TableHeaderRow />
        {!!primaryColumns && <PrimaryKeyProviderLink
          for={primaryColumns}
        />}
        <ActionProvider for={["actions"]} resourceName={resourceName} />
        {details && <TableRowDetail contentComponent={wrapDetailRow(detailsPath)} />}
      </Grid>
    )
  }
}

export default ModuleTable
