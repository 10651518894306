import React from 'react'

import {Button} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import {del, BASE_URL} from "utils"
import { useTranslation } from 'react-i18next';

import FormActions from "./FormActions";
import FormMessages from "./FormMessages";
import GridLoader from "../GridLoader";

const styles = theme => ({
    resourceForm: {
      "padding": theme.spacing.unit * 2,
      "paddingBottom": theme.spacing.unit * 4,
      "background": "#fff"
    },
    resourceFormFields: {
        "max-width": "600px"
    }
});

const deleteStoredEndpoint = (resourceName, resourceId) => () => {
    del(`${BASE_URL}/v1/${resourceName}/${resourceId}`)
      .then(window.location.reload)
}

let FormWrapper = ({loaded, children, classes, cancelLink, onSubmit, successMessage, errorMessage, hideCancel, resourceName, resourceId}) => {
  const {t} = useTranslation();
  return <div className={classes.resourceForm}>
    {loaded ? (
        <div className={classes.resourceFormFields}>
          {children}
        </div>
      )
      : <GridLoader/>}
    <FormActions onSubmit={onSubmit} cancelLink={cancelLink} hideCancel={hideCancel}/>
    {resourceName === 'monitoring-endpoints' && <Button onClick={deleteStoredEndpoint(resourceName, resourceId)}>{t('delete')}</Button>}
    {resourceName === 'sub-imsis' && <Button onClick={deleteStoredEndpoint(resourceName, resourceId)}>{t('delete')}</Button>}
    <FormMessages successMessage={successMessage} errorMessage={errorMessage}/>
  </div>
}

FormWrapper = withStyles(styles)(FormWrapper);
export default FormWrapper