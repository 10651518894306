// @flow
import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'

import controlConfig from 'configControls'
import {resourceNames} from 'config'
import {BASE_URL} from 'utils'
import ResourceHeader from 'resource/ResourceHeader';
import { rowActions } from 'bulkActions';

import NavButton from "components/NavButton";
const withControls = (view: string) => (WrappedComponent: *) => {

  return class extends React.Component<*> {
    getControls = () => {
      const props = {...get(this.props, "match.params", this.props), user:this.props.user};
      const {resourceName} = props;
      const controlsByView = get(controlConfig, [view, resourceName], get(controlConfig, [view, "default"], []));
      const filteredControls = filter(controlsByView, control => this.props.permissions[resourceName].includes(control.name));
      return [
        ...map(filteredControls, control => control && <NavButton key={control.name} {...control}/>),
      ]
    };
    getRowActions = () => {
      const {resourceName} = get(this.props, "match.params", this.props);

      if (this.props.user !== "SUPERUSER" || view !== "detail" || !rowActions[resourceName]) {
        return null
      }

      return {
        "actions":rowActions[resourceName],
        "value": this.getSelfLink()
      }
    };
    getSelfLink = () => {
      return `${BASE_URL}/v1${this.props.match.url}`
    };
    render() {
      const props = get(this.props, "match.params", this.props);
      const {resourceName} = props;
      return (
        <React.Fragment>
          <ResourceHeader key={resourceName} title={resourceNames[resourceName]} controls={this.getControls()} rowActions={this.getRowActions()} view={view} />
          <WrappedComponent user={this.props.user} {...props} />
        </React.Fragment>
      )
    }
  }
};
export default withControls