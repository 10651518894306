import React from 'react';
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {BASE_URL} from "../utils";
import {map} from 'lodash'
import {withTranslation} from "react-i18next";
import {getLocale} from "../utils/locales";

const styles = theme => ({
  resourceForm: {
    "padding": theme.spacing.unit * 2,
    "paddingBottom": theme.spacing.unit * 4,
    "background": "#fff"
  },
});

let UpdateProfileFromPrompts = ({classes, prompts, t}) => {
  const locale = getLocale().split('-')[0]

    return (
      <Card className={classes.resourceForm}>
        <h3>{t('updateProfile')}</h3>
        { map(prompts, (prompt) => {
            const accountPrompt = `update${prompt.charAt(0).toUpperCase()+ prompt.slice(1)}`
            return (
                <div>
                 <a href={`${BASE_URL}/profile-update?prompt=${prompt}&locale=${locale}`}>{t(accountPrompt)}</a>
                </div>
            )
        })}
      </Card>
    )
}
UpdateProfileFromPrompts = withStyles(styles)(UpdateProfileFromPrompts);
UpdateProfileFromPrompts = withTranslation()(UpdateProfileFromPrompts)
export default UpdateProfileFromPrompts;
