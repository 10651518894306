import React, {Component} from 'react';
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

const errorColor = "#d62728";
const neutralColor = "#7f7f7f";
const warnColor = "#ff7f0e";
const happyColor = "#1f77b4";
const successColor = "#2ca02c";

const styles = theme => ({
  chip: {
    borderRadius: "4px",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "bolder",
    padding: "4px 8px",
    height: "auto"
  },
  label: {
    padding: "0",
  },
  errorChip: {
    borderColor: errorColor,
    backgroundColor: errorColor
  },
  neutralChip: {
    borderColor: neutralColor,
    backgroundColor: neutralColor
  },
  warnChip: {
    borderColor: warnColor,
    backgroundColor: warnColor
  },
  happyChip: {
    borderColor: happyColor,
    backgroundColor: happyColor
  },
  successChip: {
    borderColor: successColor,
    backgroundColor: successColor
  },
});

const errors = [
  "Connected(PGW)",
  "Disconnected(PGW)",
  "Error(PGW)",
  "Error(HSS)",
  "Unregistered"
];


const neutral = [
  "Deleted",
  "Inactive",
  "Unknown"
];

const warning = [
  "Disconnected"
];

const happy = [
  "Provisioned",
  "Registered(HSS)",
];

const success = [
  "Connected(3g)",
  "Connected(4g)",
  "Connected(5g)"
];

export default withStyles(styles)(class FormatStatus extends Component {

  getClass = (text) => {
    if (errors.includes(text)) {
      return this.props.classes.errorChip
    }
    if (neutral.includes(text)) {
      return this.props.classes.neutralChip
    }
    if (warning.includes(text)) {
      return this.props.classes.warnChip
    }
    if (happy.includes(text)) {
      return this.props.classes.happyChip
    }
    if (success.includes(text)) {
      return this.props.classes.successChip
    }
  };

  getText = (text, ipAddress) => {
   if (success.includes(text) && ipAddress) {
     return `${ipAddress} (${text.split("(")[1].substring(0,2)})`
   }
   if (!text) {
     return "Unknown"
   }
   return text
  };

  render() {
    const {classes, text, ipAddress} = this.props;
    return (
      <Chip className={`${classes.chip} ${this.getClass(this.getText(text, null))}`} classes={classes} label={this.getText(text, ipAddress)} variant="outlined"/>
    );
  };
})

