import React from 'react'
import {BASE_URL, patch, post} from 'utils'

import GroupForm from 'resource/forms/groups/GroupForm';
import ProfileForm from 'resource/forms/profiles/ProfileForm';
import EditSubscriberForm from 'resource/forms/subscribers/EditSubscriberForm';
import SystemForm from 'resource/forms/systems/SystemForm';
import AccountForm from 'resource/forms/accounts/AccountForm';
import ConfigForm from 'resource/forms/configs/ConfigForm';
import ScriptForm from 'resource/forms/scripts/ScriptForm';
import CustomerForm from 'resource/forms/customers/CustomerForm';
import DeviceForm from 'resource/forms/devices/DeviceForm';
import CarrierForm from "resource/forms/carriers/CarrierForm";
import SubscriberImsiForm from "resource/forms/subImsis/SubscriberImsiForm";

import withControls from 'hoc/withControls';
import Paper from '@material-ui/core/Paper';
import {object} from "prop-types";
import EditFileForm from "./forms/files/EditFileForm";
import KeysetForm from "./forms/keysets/KeysetForm";
import SiteEditForm from "./forms/sites/SiteEditForm";
import ReportForm from "./forms/report/ReportForm";


type P = {
  resourceName: string,
  resourceId: string,
  user: object,
  history: object
}

export class ResourceEdit extends React.PureComponent<P> {
  getEndpoint = (resourceName: string, resourceId: string) => {
    // This is a pretty bold assumption
    switch(resourceName) {
      case "server-configs":
        return `${BASE_URL}/v1/${resourceName}`;
      default:
        return `${BASE_URL}/v1/${resourceName}/${resourceId}`
    }

  };

  getComponent = (resourceName: string) => {
    switch(resourceName) {
      case "subs":
        return EditSubscriberForm;
      case "sites":
        return SiteEditForm;
      case "sys":
        return SystemForm;
      case "accounts":
        return AccountForm;
      case "sub-groups":
        return GroupForm;
      case "sub-profiles":
        return ProfileForm;
      case "interceptor-scripts":
        return ScriptForm;
      case "server-configs":
        return ConfigForm;
      case "customers":
        return CustomerForm;
      case "devices":
        return DeviceForm;
      case "carriers":
        return CarrierForm;
      case "files":
        return EditFileForm;
      case "keysets":
        return KeysetForm;
      case "sub-imsis":
        return SubscriberImsiForm;
      case "reports":
        return ReportForm;
      default:
        return null
    }
  };

  getOnSubmit = (resourceName: string) => {
    switch(resourceName) {
      case "server-configs":
        return post;
      default:
        return patch
    }
  };

  render() {
    const {resourceName, resourceId, history, user} = this.props;
    if (!resourceName || !resourceId) {
      return null
    }
    const endpoint = this.getEndpoint(resourceName, resourceId);

    const Component = this.getComponent(resourceName);

    const onSubmit = this.getOnSubmit(resourceName);
    return (
      <Paper>
        <Component
          endpoint={endpoint}
          onSubmit={onSubmit}
          resourceName={resourceName}
          resourceId={resourceId}
          successMessage={`Your ${resourceName} was successfully updated`}
          history={history}
          isSuperUser={user === "SUPERUSER"}
        />
      </Paper>
    )
  }
}
ResourceEdit = withControls("edit")(ResourceEdit);
export default ResourceEdit