import React from 'react'
import { useTranslation } from 'react-i18next'

const listStyle = {listStyle: "none", paddingLeft: "0"}
const popupStyle = {
  border: "solid 1px #ccc",
  borderRadius: "4px",
  backgroundColor: "#fff",
  position: "absolute",
  userSelect: "none",
  transform: "translate(-50%, -112.5%)",
  cursor: "pointer",
  zIndex: 1,
  padding: "16px",
  wordWrap: "none",
  whiteSpace: "pre",
}

const InfoPopup = props => {
  const {t} = useTranslation()
  return <React.Fragment>
    {props.hover &&
    <div style={popupStyle}>
      <ul style={listStyle}>
        <li><p>{t('postalCode')}: {props.postcode}</p></li>
        <li><p>{t('city')}: {props.city}</p></li>
        <li><p>{t('state')}: {props.state}</p></li>
        <li><p>{t('country')}: {props.country}</p></li>
        <li><p>{t('accuracy')}: {props.accuracy}</p></li>
      </ul>
    </div>
    }
  </React.Fragment>
}

export default InfoPopup
