import React from "react";
import { Grid as ResourceGrid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { flatten, map, pull } from "lodash";
import { canShow, getSelfLink, isNotObject } from "utils";
import { details } from "config";
import { withTranslation } from "react-i18next";

class ResourceCardTable extends React.PureComponent<P> {
  createRows(data, resourceName, user) {
    const rows = pull(flatten(map(details[resourceName], (field, key) => {
      if (key === "extensions") {
        return map(data[key], (v, k) => {
          return {"key": k, "value": v}
        })
      }
      // Check if the field is defined for a specific role or not
      if(canShow(field, user)) {
        if (key === "selfUrl") {
          return {
            "key": this.props.t(field.label),
            "value": field.Component ? <field.Component value={getSelfLink(data)}/> : getSelfLink(data)
          }
        }
        if (isNotObject(data[key])) {
          return {
            "key": this.props.t(field.label),
            "value": field.Component ? <field.Component value={data[key]}/> : data[key]
          }
        }
        if (!data[key]) {
          return {
            "key": this.props.t(field.label),
            "value": ""
          }
        }
        return null;
      }
      return null
    })), null);

    return rows
  }
  getColumns() {
    return [
      {
        title: this.props.t("Key"),
        name: "key",
        getCellValue: row => row && row.key
      },
      {
        title: this.props.t("Value"),
        name: "value",
        getCellValue: row => row && row.value
      },
    ]
  }
  
  render() {
    const {data, model, user} = this.props;
    const rows = this.createRows(data, model, user)
    const columns = this.getColumns()

    return (
      <ResourceGrid
        rows={rows}
        columns={columns}>
        <Table columnExtensions={ [
          {columnName: 'key', width: 300 },
          {columnName: 'type'},
        ]}/>
        <TableHeaderRow />
      </ResourceGrid>
    )
  }
}

ResourceCardTable = withTranslation()(ResourceCardTable)
export default ResourceCardTable