import BulkUpdateSubscriberStore from 'components/table/actions/BulkUpdateSubscriberStore'
import BulkDeleteSubscriberStore from 'components/table/actions/BulkDeleteSubscriberStore'
import BulkImportSubscribers from 'components/table/actions/bulkImport/BulkImportSubscribers'
import ClearCache from 'components/table/actions/ClearCache'
import HazelcastClusterDebug from 'components/row/actions/HazelcastClusterDebug';
import SiteCheck from 'components/row/actions/SiteCheck';

import ClearAllCaches from 'components/row/actions/ClearAllCaches'
import RecreateSubscriber from 'components/row/actions/RecreateSubscriber';
import CancelSubscriber from 'components/row/actions/CancelSubscriber';
import UpdateSubscriber from 'components/row/actions/UpdateSubscriber';
import FetchSubscriber from 'components/row/actions/FetchSubscriber';

import RecreateSite from 'components/row/actions/RecreateSite';
import RecreateGateway from 'components/row/actions/RecreateGateway';
import FetchSite from 'components/row/actions/FetchSite';
import UpdateSite from 'components/row/actions/UpdateSite';
import ResetPassword from "./components/row/actions/ResetPassword";
import EnablePrivateRadio from "./components/row/actions/EnablePrivateRadio";
import DisablePrivateRadio from "./components/row/actions/DisablePrivateRadio";
import RevokeToken from "./components/row/actions/RevokeToken";
import AddGatewayHostFeature from "./components/row/actions/AddGatewayHostFeature";
import AddPrivateRadioFeature from "./components/row/actions/AddPrivateRadioFeature";
import AddKeysetMappingFeature from "./components/row/actions/AddKeysetMappingFeature";
import AddApiTokenSiteFeature from "./components/row/actions/AddApiTokenSiteFeature";
import AddApiTokenAccountFeature from "./components/row/actions/AddApiTokenAccountFeature";
import AddSftpAccessCustomerFeature from "./components/row/actions/AddSftpAccessCustomerFeature";
import CheckSftpAccess from "./components/row/actions/CheckSftpAccess";
import AddMonitoringSiteFeature from './components/row/actions/AddMonitoringSiteFeature';
import GatewayHostButton from "./components/table/gatewayHostFeature/GatewayHostButton";
import KeysetMappingButton from "./components/table/keysetMapping/KeysetMappingButton";
import MonitoringButton from "./components/table/monitoring/MonitoringButton";
import DeleteButton from "./components/row/actions/DeleteButton";
import SubscriberImsiButton from "./components/table/subImsi/SubscriberImsiButton";

const bulkActions = {
  "subs": [
    {"Component": BulkUpdateSubscriberStore, "name": "bulkUpdate", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]},
    {"Component": BulkDeleteSubscriberStore, "name": "bulkDelete", "roles": ["SUPERUSER", "ADMIN"]},
    {"Component": BulkImportSubscribers, "name": "import", "roles": ["SUPERUSER", "ADMIN"]}
  ],
  "sites": [
    {"Component": ClearCache, "name": "clearCache", "roles": ["SUPERUSER"]}
  ]
};

export const rowActions = {
  "subs": [
    {"Component": RecreateSubscriber, "name": "recreateSubscriber", "roles": ["SUPERUSER"]},
    {"Component": CancelSubscriber, "name": "cancelSubscriber", "roles": ["SUPERUSER"]},
    {"Component": UpdateSubscriber, "name": "updateSubscriber", "roles": ["SUPERUSER"]},
    {"Component": FetchSubscriber, "name": "fetchSubscriber", "roles": ["SUPERUSER"]}
  ],
  "sites": [
    {"Component": ClearAllCaches, "name": "clearCache", "roles": ["SUPERUSER"]},
    {"Component": HazelcastClusterDebug, "name": "clusterDebug", "roles": ["SUPERUSER"]},
    {"Component": SiteCheck, "name": "SiteCheck", "roles": ["SUPERUSER"]},
    {"Component": RecreateSite, "name": "recreateSite", "roles": ["SUPERUSER"]},
    {"Component": UpdateSite, "name": "updateSite", "roles": ["SUPERUSER"]},
    {"Component": FetchSite, "name": "fetchSite", "roles": ["SUPERUSER"]},
    {"Component": AddGatewayHostFeature, "name": "addGatewayHostFeature", "roles": ["SUPERUSER"]},
    {"Component": AddPrivateRadioFeature, "name": "addPrivateRadioFeature", "roles": ["SUPERUSER"]},
    {"Component": AddKeysetMappingFeature, "name": "addKeysetMappingFeature", "roles": ["SUPERUSER"]},
    {"Component": AddApiTokenSiteFeature, "name": "addApiTokenFeature", "roles": ["SUPERUSER"]},
    {"Component": AddMonitoringSiteFeature, "name": "addMonitoringSiteFeature", "roles": ["SUPERUSER"]},
  ],
  "customers": [
    {"Component": AddSftpAccessCustomerFeature, "name": "addSftpAccessCustomerFeature", "roles": ["SUPERUSER"]}
  ],
  "accounts": [
    {"Component": ResetPassword, "name": "resetPassword", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]},
    {"Component": AddApiTokenAccountFeature, "name": "addApiTokenFeature", "roles": ["SUPERUSER"]},
  ],
  "private-radios": [
    {"Component": EnablePrivateRadio, "name": "provision", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]},
    {"Component": DisablePrivateRadio, "name": "unprovision", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]},
    {"Component": DeleteButton, "messageNamePrefix": "privateRadio", "name": "delete", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]}
  ],
  "gateway-hosts": [
    {"Component": GatewayHostButton, "name": "edit", "edit": true, "label": "Edit", "roles": ["SUPERUSER"]},
    {"Component": DeleteButton, "messageNamePrefix": "gatewayHost", "name": "delete", "roles": ["SUPERUSER"]}

  ],
  "keyset-mappings": [
    {"Component": KeysetMappingButton, "name": "edit", "edit": true, "label": "Edit", "roles": ["SUPERUSER"]},
    {"Component": DeleteButton, "messageNamePrefix": "keysetMapping", "name": "delete", "roles": ["SUPERUSER"]}
  ],
  "tokens": [
    {"Component": RevokeToken, "name": "revoke", "label": "Revoke", "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"]},
  ],
  "imsis": [
    {"Component": SubscriberImsiButton, "name": "edit", "edit": true, "label": "Edit", "roles": ["SUPERUSER"]},
  ],
  "monitoring-endpoints": [
    {"Component": MonitoringButton, "name": "edit", "edit": true, "label": "Edit", "roles": ["SUPERUSER"]},
    {"Component": DeleteButton, "messageNamePrefix": "monitoringEndpoint", "name": "delete", "roles": ["SUPERUSER"]}
  ],
  "gateways": [
    {"Component": RecreateGateway, "name": "recreateGateway", "roles": ["SUPERUSER"]},
  ],
  "sftp-accesses": [
    {"Component": CheckSftpAccess, "name": "check", "roles": ["SUPERUSER", "ADMIN"]}
  ]
};
export default bulkActions