import React from 'react'
import {get} from 'utils'
import _get from 'lodash/get'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});


class PrivateRadioSiteFeature extends React.Component {
  componentWillMount(): void {
    if (this.props.value.includes("sites")) {
      get(`${this.props.value}/features`).then(response => {
        const feature = _get(response, ["_embedded", "site-feature-private-radios", 0, "_links", "self", "href"]);
        this.props.onChange({feature})
      })
    }

  }
  render() {
    return null
  }
}

PrivateRadioSiteFeature = withStyles(styles)(PrivateRadioSiteFeature);
export default PrivateRadioSiteFeature