// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import {patch} from "utils";
import _get from 'lodash/get'
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type P = {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}

class EnablePrivateRadio extends React.PureComponent<P> {
  onClick = () => {
    const {endpoint, renderMessage, t} = this.props;
    patch(endpoint, {"status": "ENABLED"}).then((response) => {
      renderMessage("success")(t('privateRadioEnableSuccess'));
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], t('privateRadioEnableError')));
    })
  };
  render() {
    const {t} = this.props;
    return <MenuItem onClick={this.onClick}><span>{t('enable')}</span></MenuItem>
  }
}

export default withTranslation()(EnablePrivateRadio);
