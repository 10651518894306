import React from 'react';
import {connect} from "redux-zero/react";
import {FormInner} from "components/form/FormInner";
import GatewayHostForm from "resource/forms/gatewayHosts/GatewayHostForm";
import bottomDrawerAction from "../bottomDrawerAction";
import OpenBottomDrawerButton from "../../row/actions/OpenBottomDrawerButton";

const BottomDrawer = (props) => (
  <FormInner>
    <GatewayHostForm {...props} />
  </FormInner>
)

export default connect(null, bottomDrawerAction(BottomDrawer))(OpenBottomDrawerButton)
