// @flow
import {withStyles} from "@material-ui/core";
import StyledCard from "./StyledCard";

const styles = theme => ({
  "className": {
    "padding": theme.spacing.unit * 2,
    "height": "100%",
    "overflow": "visible"
  }
});

const ChartContainer = withStyles(styles)(StyledCard);
export default ChartContainer