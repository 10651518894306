import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { useTranslation } from 'react-i18next';

function FileField(props) {
  const onDrop = useCallback(acceptedFiles => {
      props.onChange({"file": acceptedFiles[0]})
  }, [props]);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

  const {t} = useTranslation();

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>{t('dragNdrop')}</p>
      </div>
      {files.length > 0 &&
        <aside>
            <h4>{t('files')}</h4>
            <ul>{files}</ul>
        </aside>}
    </section>
  );
}
export default FileField
