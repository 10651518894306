// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import Extensions from 'components/fields/Extensions';

let defaultData = {};

let fields = {
  "extensions": {
    "Component": Extensions,
  }
};

const MonitoringForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}} />
);

export default MonitoringForm
