// @flow
import React from 'react'
import moment from 'moment'
import { sumBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import {BASE_URL, get} from "utils";
import MetricTile from "components/home/MetricTile";
import {Link} from "react-router-dom";
import {object} from "prop-types";
import { withTranslation } from "react-i18next"

type P = {

}
type S = {
  key: ?moment,
  subscribersByStatus: object,
  systemsCount: object,
  sitesCount: object,
}

class CustomerReport extends React.Component<P, S> {
  state = {
    key: null,
    subscribersByStatus: null,
    systemsCount: null,
    sitesCount: null
  };
  componentDidMount() {
    this.loadReport()
  }
  loadReport() {
    get(`${BASE_URL}/v1/customers/command/getCustomerReport`).then((response) => {
        this.setState( {key:moment(), ...response})
      }
    )
  }
  render() {
    const {t} = this.props;
    const {subscribersByStatus, sitesCount, systemsCount, key} = this.state
    let totalSubs = 0;
    if (subscribersByStatus) {
      totalSubs = sumBy(subscribersByStatus, d => d.count);
    }
    return (
      <Grid container spacing={16}>
        <Grid item xs={4}>
          {key &&
            <Link className="body-font" to="/subs">
              <MetricTile>
                <h2>{totalSubs}</h2>
                <h5>{t('subscribers')}</h5>
              </MetricTile>
            </Link>
          }
        </Grid>
        <Grid item xs={4}>
          {key &&
            <Link className="body-font" to="/sys">
              <MetricTile>
                <h2>{systemsCount}</h2>
                <h5>{t('systems')}</h5>
              </MetricTile>
            </Link>
          }
        </Grid>
        <Grid item xs={4}>
          {key &&
            <Link className="body-font" to="/sites">
              <MetricTile>
                <h2>{sitesCount}</h2>
                <h5>{t('sites')}</h5>
              </MetricTile>
            </Link>
          }
        </Grid>
      </Grid>
    )
  }
}
export default withTranslation()(CustomerReport)