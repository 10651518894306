// @flow
import React from 'react'
import { withTranslation } from 'react-i18next';

import {BASE_URL, postFormdata} from 'utils';

import {withStyles} from '@material-ui/core/es/styles';
import Input from '@material-ui/core/Input';

import ConfirmButton from 'components/ConfirmButton';
import TableAction from 'components/table/actions/TableAction'
import SubscriberStoreSelector from 'resource/forms/subscribers/fields/SubscriberStoreSelector';
import KeysetSelector from "resource/forms/keysetMappings/fields/KeysetSelector";
import Checkbox from "components/fields/Checkbox";
import GridLoader from 'components/GridLoader';
import ColumnsMappingTable from 'components/table/actions/bulkImport/ColumnMappingTable';
import Extensions from "components/fields/Extensions";
import Select from "components/fields/Select";
import type {TableActionProps} from "types";

import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import {object} from "prop-types";

const styles = theme => ({
    inputType: {
        padding: `${theme.spacing.unit * 2}px 0`
    },
    fileInputWrapper: {
        margin: `${theme.spacing.unit * 2}px 0`
    },
    stepper: {
        width: '100%'
    },
    columnMappingTable: {
        width: '100%'
    },
    button: {
        marginTop: `${theme.spacing.unit * 2}px`,
        marginRight: `${theme.spacing.unit * 2}px`
    },
    actionsContainer: {
        marginBottom: `${theme.spacing.unit * 2}px`,
        textAlign: 'center'
    },
    resetContainer: {
        padding: `${theme.spacing.unit * 3}px 0`
    }
});

const initialState = {
    activeStep: 0,
    header: {
        file: null,
        columnsMapping: null,
        lineNumber: null,
        content: null
    },
    values: {
        customer: null,
        feature: null,
        groups: null,
        profile: null,
        keyset: null,
        status: "Active",
        extensions: null,
        overwriteIfExisting: false
    },
    errors: {
        profile: null,
        feature: null,
        keyset: null
    },
    summary: {
        content: null,
        errors: null
    },
    loaded: true
};

type S = {
    activeStep: number,
    header: object,
    values: object,
    errors: object,
    summary: object,
    loaded: boolean,
}

class BulkImportSubscribers extends React.Component<TableActionProps, S> {
    state = initialState;

    onSubmit = () => {
        const {values, header} = this.state;
        const {renderMessage} = this.props;
        const endpoint = `${BASE_URL}/v1/subs/command/bulkImport`;

        this.setState({
            loaded: false
        });
        let postValues = {
            subscriberStoreFeature: values.feature,
            subscriberProfile: values.profile,
            file: header.file,
            keyset: values.keyset,
            status: values.status,
            extensions: JSON.stringify(values.extensions),
            overwriteIfExisting: values.overwriteIfExisting
        };
        postFormdata(endpoint, postValues).then((response) => {
            this.setState({errors: {}, summary: {content: response.summary}, loaded: true});
            this.handleNext();
            renderMessage("success")("Subscribers Imported");
            this.props.closeDrawer()
        }).catch((error) => {
            this.setState({
                summary: {content: error.summary, errors: error.errors}, loaded: true
            });
            this.handleNext();
            renderMessage("error")("Subscriber import has failed")
        })
    };

    onFileChange = (e) => {
        e.preventDefault();
        this.handleReset();

        const {renderMessage} = this.props;
        const endpoint = `${BASE_URL}/v1/subs/command/bulkImportFormat`;
        const file = e.target.files[0];

        if (file != null) {
            this.setState({header: {file: file}, loaded: false});

            postFormdata(endpoint, {file: file}).then((response) => {
                let header = response.header;
                this.setState(
                    {
                        errors: {},
                        summary: {content: response.summary},
                        header: {
                            columnsMapping: header.mappingDefinitions,
                            lineNumber: header.lineNumber,
                            content: header.content,
                            file: file,
                        },
                        loaded: true
                    });
                renderMessage("success")("File format is recognized by the application");
            }).catch((error) => {
                this.setState({
                    summary: {content: error.summary, errors: error.header ? error.header.errors : null},
                    loaded: true
                });
                renderMessage("error")("Retrieving columns format has failed");
            })
        }
    };

    onChange = (newData) => {
        this.setState((state) => ({
            ...state, values: {...state.values, ...newData}
        }));
    };

    getSteps = (t) => {
        return [t('selectFile'), t('import'), t('summary')];
    };

    getColumnsMapping = (header) => {
        if (header.columnsMapping) return <ColumnsMappingTable data={header.columnsMapping}/>;
        else return <div/>;
    };

    getLoader = (loaded) => {
        if (!loaded) return <GridLoader/>;
        // else return <div/>
    };

    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return this.getStep1();
            case 1:
                return this.getStep2();
            case 2:
                return this.getStep3();
            default:
                return 'Unknown step';
        }
    };

    getStep1 = () => {
        const {header, summary} = this.state;
        const {classes, t} = this.props;

        return (
          <>
              <Input type="file" name="file" onChange={this.onFileChange}
                     classes={{inputType: classes.inputType}} className={classes.fileInputWrapper}/>
              <Typography variant="caption">{summary.content}</Typography>
              {summary.errors &&
                <Typography variant="caption" color="error">
                    <ErrorList items={summary.errors}/>
                </Typography>
              }
              {this.getColumnsMapping(header)}
              <div className={classes.actionsContainer}>
                  <Button disabled={!header.columnsMapping} variant="contained" color="primary"
                          onClick={this.handleNext}
                          className={classes.button}>
                      {t('next')}
                  </Button>
              </div>
          </>
        );
    };

    getStep2 = () => {
        const {values, errors} = this.state;
        const {classes,t} = this.props;
        return (
          <>
              <SubscriberStoreSelector values={values} errors={errors} validationErrors={errors} help={{
                  "groups": "Optional",
                  "customer": "Owner of the subscribers. Required",
                  "system": "Required",
                  "profile": "Required",
              }} cachedOptions={{}} onChange={this.onChange}/>
              <KeysetSelector name="keyset" value={values.keyset} label={t('keyset')} onChange={this.onChange} help={"Required"} />
              <Select name="status" value={values.status} label={t('subscriberStatus')} onChange={this.onChange}
                      options={[{"value": "Active", "label": t('active')}, {"value": "Inactive", "label": t('inactive')}]}
                      displayEmpty={false}/>
              <Extensions name="extensions" value={values.extensions} onChange={this.onChange}/>
              <Checkbox name="overwriteIfExisting" value={values.overwriteIfExisting} label={t('overwriteIfExisting')} onChange={this.onChange}/>
              <div className={classes.actionsContainer}>
                  <Button onClick={this.handleBack} className={classes.button}>
                      {t('back')}
                  </Button>
                  <ConfirmButton
                    disabled={!values.feature || !values.profile || !values.keyset}
                    variant="contained"
                    color="primary"
                    onClick={this.onSubmit}
                    confirmMessage="Import subscribers?"
                  >{t('submit')}</ConfirmButton>
              </div>
          </>
        );
    };

    getStep3 = () => {
        const {summary} = this.state;
        const {classes, t} = this.props;

        return (
          <>
              <Typography variant="caption">{summary.content}</Typography>
              {summary.errors && summary.errors.map(
                (row, index) => Array.isArray(row)
                  ? (
                    <Typography key={index} variant="caption" color="error">Line {index+1} :
                        <ErrorList items={row}/>
                    </Typography>
                  )
                  : (
                    <Typography key={index} variant="caption" color="error">Line {row.lineNumber} :
                        <ErrorList items={row.errors}/>
                    </Typography>
                  )
              )}
              <div className={classes.actionsContainer}>
                  <Button onClick={this.handleBack} className={classes.button}>
                      {t('back')}
                  </Button>
                  <Button onClick={this.handleReset} className={classes.button} variant="contained" color="primary">
                      {t('newBulkImport')}
                  </Button>
              </div>
          </>
        );
    };

    handleNext = () => {
        this.setState((currentState) => ({activeStep: currentState.activeStep + 1}));
    };

    handleBack = () => {
        this.setState((currentState) => ({activeStep: currentState.activeStep - 1}));
    };

    handleReset = () => {
        this.setState(initialState);
    };

    render() {
        const {togglePanel, name, classes, t, ...props} = this.props;
        const {activeStep, loaded} = this.state;
        const steps = this.getSteps(t);
        return (
            <TableAction togglePanel={togglePanel} name={name} {...props} label={t("bulkImport")}>
                <div className={classes.stepper}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {this.getStepContent(index)}
                                </StepContent>
                            </Step>
                        ))}
                        {this.getLoader(loaded)}
                    </Stepper>
                </div>
            </TableAction>
        )
    }
}

const ListItem = ({ value }) => {
    return <li>{value}</li>;
};

const ErrorList = ({ items }) => {
    return (
      <ul>
          {items.map((item, index) =>
            <ListItem key={index} value={item} />
          )}
      </ul>
    );
};

BulkImportSubscribers = withStyles(styles)(BulkImportSubscribers);
export default withTranslation()(BulkImportSubscribers)
