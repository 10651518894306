// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import Select from 'components/fields/Select'
import {object} from "prop-types";


let defaultData = {
    "id": null,
    "brandName": null,
    "modelName": null,
    "deviceType": null,
    "os": null,
    "source": null
};

let fields = {
    "id": {
        "Component": TextField,
        "name": "id",
        "label": "TAC",
        "updatable": false
    },
    "brandName": {
        "Component": TextField,
        "name": "brandName",
        "label": "Brand name"
    },
    "modelName": {
        "Component": TextField,
        "name": "modelName",
        "label": "Model name"
    },
    "deviceType": {
        "Component": Select,
        "options": [
            {
                "value": "Handheld",
                "label": "Handheld"
            },
            {
                "value": "Portable Include Pda",
                "label": "Portable(include PDA)"
            },
            {
                "value": "Laptop",
                "label": "Laptop"
            },
            {
                "value": "Module",
                "label": "Module"
            },
            {
                "value": "Phone",
                "label": "Phone"
            },
            {
                "value": "Dongle",
                "label": "Dongle"
            },
            {
                "value": "Iot Device",
                "label": "IoT Device"
            },
            {
                "value": "Wearable",
                "label": "Wearable"
            },
            {
                "value": "Band",
                "label": "Band"
            },
            {
                "value": "Camera",
                "label": "Camera"
            },
            {
                "value": "Console",
                "label": "Console"
            },
            {
                "value": "Drone",
                "label": "Drone"
            },
            {
                "value": "E Reader",
                "label": "E-reader"
            },
            {
                "value": "Feature Phone",
                "label": "Feature Phone"
            },
            {
                "value": "Headphones",
                "label": "Headphones"
            },
            {
                "value": "Locator",
                "label": "Locator"
            },
            {
                "value": "Modem",
                "label": "Modem"
            },
            {
                "value": "Monitor",
                "label": "Monitor"
            },
            {
                "value": "Mp 3 Mp 4",
                "label": "MP3 & MP4"
            },
            {
                "value": "Router",
                "label": "Router"
            },
            {
                "value": "Smartphone",
                "label": "Smartphone"
            },
            {
                "value": "Smartwatch",
                "label": "Smartwatch"
            },
            {
                "value": "Speaker",
                "label": "Speaker"
            },
            {
                "value": "Tablet",
                "label": "Tablet"
            },
            {
                "value": "TV",
                "label": "TV"
            },
            {
                "value": "Vacuum",
                "label": "Vacuum"
            },
            {
                "value": "unknown",
                "label": "unknown"
            },
        ],
        "name": "deviceType",
        "label": "Device type"
    },
    "os": {
        "Component": TextField,
        "name": "os",
        "label": "OS"
    },
    "source": {
        "Component": Select,
        "options": [
            {
                "value": "manual",
                "label": "Manual"
            },
            {
                "value": "imei_info",
                "label": "IMEI Info API"
            }
        ],
        "name": "source",
        "label": "Source",
        "updatable": false
    }
};

const DeviceForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default DeviceForm
