// @flow
import React, {Component} from 'react';
import {connect} from "redux-zero/react";

import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Link, withRouter} from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import LoginButton from "./LoginButton";
import {getUser, postLogin} from "../../utils/fetch";
import {BASE_URL} from "../../utils";
import {loadAccessRules} from "../../config";
import { withTranslation } from 'react-i18next';
import SelectLocale from "../../containers/SelectLocale"

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "250px"
  },
  helperMessage: {
    textAlign: "center",
    width: "250px",
    lineHeight: 1.5
  }
});

type P = {
  onSubmit: func,
  classes: array,
  history: object,
  setUser: func,
}

type S = {
  username: string,
  password: string,
  error: boolean,
}

const actions = (store) => ({
  setUser: (state, user) => {
    loadAccessRules(user);
    return {user, attemptedLogin: true}
  },
  setAttemptedLogin: (state) => {
    return {attemptedLogin: true}
  }
});

const BAD_LANDING_PAGES = ["/logout", "/login", "/", "/maintenance"];

class Login extends Component<P, S> {
  state = {
    username: "",
    password: "",
    error: false
  };
  onSubmit = (e) => {
    const {history, setUser, setAttemptedLogin} = this.props;
    e.preventDefault();
    postLogin(`${BASE_URL}/login`, this.state).then((response) => {
      if (response.status === "success") {
        getUser(`${BASE_URL}/v1/accounts/command/currentUser?projection=withRole`).then((response) => {
          if (response !== undefined) {
            response.username = response.id;
            if (history.location.pathname && !BAD_LANDING_PAGES.includes(history.location.pathname)) {
              history.push(history.location.pathname)
            } else {
              history.push("/home");
            }
            setUser(response)
          } else {
            setAttemptedLogin()
          }
        })
      } else {
        this.setState({error: true});
        setAttemptedLogin()
      }
    })
  };

  onChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {username, password, error} = this.state;
    const {classes, t} = this.props;
    return (
      <React.Fragment>
        {error &&
        <FormHelperText id="error-message" className={classes.helperMessage} error={error}>{t('loginErrorMessage')}</FormHelperText>
        }
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField
            id="standard-name"
            label={t('name')}
            value={username}
            onChange={this.onChange('username')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="standard-password"
            label={t('password')}
            value={password}
            onChange={this.onChange('password')}
            className={classes.textField}
            margin="normal"
            type="password"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Link id="forgot-link" to="/forgot">{t('forgotMyPassword')}</Link>
          <LoginButton text={t('logIn')}/>
          <SelectLocale/>
        </form>
      </React.Fragment>
    )
  }
}

Login = connect(null, actions)(Login);
Login = withRouter(Login);
Login = withStyles(styles)(Login);
Login = withTranslation()(Login);

export default Login