// @flow
import React from 'react'

import ResourceForm from 'resource/forms/ResourceForm'
import SubscriberStoreSelector from 'resource/forms/subscribers/fields/SubscriberStoreSelector';
import Select from 'components/fields/Select'
import Extensions from 'components/fields/Extensions'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import {mapNestedFields} from "utils";
import KeysetSelector from "../keysetMappings/fields/KeysetSelector";

const defaultData = {
  "groups": [],
  "imsi": null,
  "msisdn": null,
  "iccid": null,
  "k": null,
  "status": "Active",
  "type": "Production",
  "keysetMapping": null,
  "extensions": {},
  "feature": null,
  "profile": null,
  "customer": null,
};

const fieldsSuperUser = {
  "imsi": {
    "Component": TextField,
    "name": "imsi",
    "label": "IMSI",
    "errorName": "id",
    "updatable": false,
    "required": true
  },
  "msisdn": {
    "Component": TextField,
    "name": "msisdn",
    "label": "MSISDN"
  },
  "iccid": {
    "Component": TextField,
    "name": "iccid",
    "label": "ICCID"
  },
  "k": {
    "Component": TextField,
    "name": "k",
    "label": "K",
    "updatable": false,
  },
  "opc": {
    "Component": TextField,
    "name": "opc",
    "label": "OPC",
    "updatable": false,
  },
  "keyset": {
    "Component": props =>
      <KeysetSelector
        {...props}
        displayEmpty={true}
      />,
    "name": "keyset",
    "label": "Keyset"
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status",
    "label": "Status"
  },
  "type": {
    "Component": Select,
    "options": [
      {
        "value": "Production",
        "label": "Production"
      },
      {
        "value": "Test",
        "label": "Test"
      }
    ],
    "name": "type",
    "label": "Type"
  },
  "extensions": {
    "Component": Extensions,
  },
  "groups": {
    "relationship": "many",
    "labelPath": "name"
  },
  "profile": {
    "relationship": "one",
    "labelPath": "name"
  },
  "customer": {
    "relationship": "one",
    "labelPath": "name"
  },
  "feature": {
    "label": "System",
    "Component": props =>
      <SubscriberStoreSelector
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
      />,
    "labelPath": "system.name",
    "relationship": "one",
    "nestedFields": [
      "groups",
      "profile",
      "feature",
      "customer"
    ]
  },
};

const fieldsAdmin = {
  "imsi": {
    "Component": TextField,
    "name": "imsi",
    "errorName": "id",
    "updatable": false
  },
  "msisdn": {
    "Component": TextField,
    "name": "msisdn"
  },
  "iccid": {
    "Component": TextField,
    "name": "iccid"
  },
  "k": {
    "Component": TextField,
    "name": "k",
    "label": "K",
    "updatable": false
  },
  "opc": {
    "Component": TextField,
    "name": "opc",
    "label": "OPC",
    "updatable": false
  },
  "keyset": {
    "Component": props =>
      <KeysetSelector
        {...props}
        displayEmpty={true}
      />,
    "name": "keyset",
    "label": "Keyset"
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status"
  },
  "extensions": {
    "Component": Extensions,
  },
  "groups": {
    "relationship": "many",
    "labelPath": "name"
  },
  "profile": {
    "relationship": "one",
    "labelPath": "name"
  },
  "customer": {
    "relationship": "one",
    "labelPath": "name"
  },
  "feature": {
    "label": "system",
    "Component": (props) =>
      <SubscriberStoreSelector
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
      />,
    "labelPath": "system.name",
    "relationship": "one",
    "nestedFields": [
      "groups",
      "profile",
      "feature",
      "customer"
    ]
  },
};

class SubscriberForm extends React.PureComponent<object> {
  render() {
    return <ResourceForm {...this.props} fields={this.props.isSuperUser ? fieldsSuperUser : fieldsAdmin}
                         defaultData={defaultData}/>
  }
}

export default SubscriberForm
