export function formatInterval (startTime, stopTime) {
  const start = new Date(startTime)
  let stop
  if (stopTime) {
    stop = new Date(stopTime)
  } else {
    stop = new Date()
  }

  const secondsElapsed = Math.round((stop.getTime() - start.getTime()) / 1000)

  const years = Math.floor(secondsElapsed / (3600*24*365))
  const days = Math.floor(secondsElapsed / (3600*24)) - 365*years
  const hours = Math.floor(secondsElapsed / 3600) - 24*days - 24*365*years
  const minutes = Math.floor(secondsElapsed / 60) - 60*hours - 24*60*days - 365*24*60*years
  const seconds = Math.floor(secondsElapsed) - 60*minutes - 60*hours - 24*60*days - 365*24*60*years

  const y = years ? (years + 'y ') : ''
  const d = days ? (days + 'd ') : ''
  const h = hours ? (hours + 'h ') : ''
  const m = minutes ? (minutes + 'm') : ''
  const s = seconds ? (seconds + 's') : ''

  const result = y + d + h + m
  return result ? result : s
}