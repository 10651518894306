// @flow
import React from 'react'
import RowAction from './RowAction'
import {BASE_URL} from "utils";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type rowAction =  {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}

class CancelSubscriber extends React.PureComponent<rowAction> {
  render() {
    const {value, t, ...props} = this.props;
    return <RowAction
      {...props}
      endpoint={`${BASE_URL}/v1/subs/command/cancelSubscriber?subscriber=${value}`}
      message={t("subscriberCancelled")}
      label={t('cancel')}
    />
  }
}

CancelSubscriber = withTranslation()(CancelSubscriber);
export default CancelSubscriber
