// @flow
import React from 'react'
import ResourceForm from 'resource/forms/ResourceForm'
import Extensions from 'components/fields/Extensions'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import GatewayHostFeature from "./fields/GatewayHostFeature";

let defaultData = {
  "name": null,
  "url": null,
  "extensions": {},
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "url": {
    "Component": TextField,
    "name": "url",
    "label": "Url"
  },
  "feature": {
    "Component": GatewayHostFeature,
    "name": "feature",
    "label": "Feature"
  },
  "extensions": {
    "Component": Extensions,
  }
};

const GatewayHostForm = (props: object) => {
  return <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}}/>
};

export default GatewayHostForm
