// @flow
import React from 'react'
import moment from "moment";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {object} from "prop-types";
import * as Sentry from "@sentry/browser";
import {withTranslation} from 'react-i18next';

import {BASE_URL, post} from 'utils';
import DateRangeSelector, {LAST_MONTH, LAST_WEEK, THIS_MONTH, THIS_WEEK} from "components/selectors/DateRangeSelector";
import GridLoader from "components/GridLoader";
import CustomerSelector from "components/selectors/CustomerSelector";
import TimezoneSelector from "components/selectors/TimezoneSelector";
import Checkbox from "../../components/fields/Checkbox";
import TextField from "../../components/fields/TextField";

const initialState = {
  values: {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    timezone: "",
    customerRef: "",
    customerExtensions: "",
    sftpAccessRef: "",
    unfoldCustomer: false,
    writeRowCount: false
  },
  errors: {
    startDate: null,
    endDate: null,
    timezone: null,
    customerRef: null,
    customerExtensions: null,
    sftpAccessRef: null,
    unfoldCustomer: null,
    writeRowCount: null
  },
  message: null,
  loaded: true
};

type P = {
  classes: object,
  style?: object,
  report: object
}

type S = {
  values: object,
  errors: object,
  loaded: boolean
}

class CustomerUsageReportCard extends React.PureComponent<P, S> {

  state = initialState;

  getLoader = (loaded) => {
    if (!loaded) return <GridLoader/>;
    else return <div/>
  };

  launchReport = (uri: string) => {
    const {values} = this.state;
    const data = {
      startDate: values.startDate.format("YYYYMMDD"),
      endDate: values.endDate.format("YYYYMMDD"),
      timezone: values.timezone,
      customerRef: values.customerRef,
      customerExtensions: values.customerExtensions,
      sftpAccessRef: values.sftpAccessRef,
      unfoldCustomer: values.unfoldCustomer,
      writeRowCount: values.writeRowCount
    }
    const endpoint = `${BASE_URL}${uri}`;

    this.setState({
      loaded: false
    });

    // call backend
    post(endpoint, data).then((response) => {
      this.setState({
        message: response.result,
        loaded: true
      });
    }).catch((err) => {
      Sentry.captureException(err);
      this.setState({
        message: err.message,
        loaded: true
      })
    });

  };

  onChange = (newData) => {
    this.setState((state) => ({
      ...state, values: {...state.values, ...newData}
    }))
  };

  render() {
    const {values, errors, message, loaded} = this.state;
    const {report, t} = this.props;

    if (!report) {
      return null;
    }

    return (
      <Grid item spacing={16}>
        <Paper>
          <h4 className="featured card-title">{t('generateReport')}</h4>
          <Grid container className="resource-form">
              <Grid item xs={12}>
                <DateRangeSelector
                  startDate={values.startDate}
                  startDateId="customerUsageReportStartDateId"
                  endDate={values.endDate}
                  endDateId="customerUsageReportEndDateId"
                  presets={[LAST_WEEK, THIS_WEEK, LAST_MONTH, THIS_MONTH]}
                  onChange={this.onChange}/>
                <TimezoneSelector label={t('timezone')} name="timezone"
                                  value={values.timezone}
                                  error={errors.timezone}
                                  onChange={this.onChange}/>
                <CustomerSelector label={t('customer')} name="customerRef"
                                  value={values.customerRef}
                                  error={errors.customerRef}
                                  onChange={this.onChange}/>
                <TextField
                  label={t('customerExtensionsToExtract')}
                  name="customerExtensions"
                  value={values.customerExtensions}
                  onChange={this.onChange}/>
                <Checkbox name="unfoldCustomer" value={values.unfoldCustomer}
                          label={t('thisCustomerAndAllChildren')}
                          onChange={this.onChange}/>
                <Checkbox name="writeRowCount" value={values.writeRowCount} label={t('footerRowCount')}
                          onChange={this.onChange}/>
                {message &&
                  <Typography variant="subtitle1" color="primary">
                    {message}
                  </Typography>
                }
                <Button variant="contained" color="primary"
                        onClick={() => this.launchReport(report.uri)}>{t('generateReport')}</Button>
              </Grid>
              {this.getLoader(loaded)}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

CustomerUsageReportCard = withTranslation()(CustomerUsageReportCard);
export default CustomerUsageReportCard