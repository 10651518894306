// @flow
import React from 'react';
import {BASE_URL, cleanSelfLink, get, getSelfLink, mapDict, patch} from 'utils';
import map from 'lodash/map';
import _get from 'lodash/get';
import keys from 'lodash/keys';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import {func, object} from "prop-types";
import FormWrapper from "components/form/FormWrapper";

type P = {
  resourceName: string,
  resourceId: string,
  fields: *,
  data: *,
  onSubmit: func,
  endpoint: string,
  successMessage: string,
  history: *,
  transformSubmitData: func,
  onSuccess: func,
  hideCancel?: ?boolean
}
type S = {
  loaded: boolean,
  editing: boolean,
  data: object,
  labels: object,
  patchData: object,
  validationErrors: object,
  relationships: object,
  schema: object,
  successMessage: ?string,
  errorMessage: ?string,
}
const wrapForm = (WrappedComponent: *) => {

  return class extends React.Component<P, S> {

    constructor(props: object) {
      super(props);
      this.state = {
        loaded: false,
        editing: false,
        data: props.defaultData,
        labels: {},
        patchData: {},
        validationErrors: {},
        successMessage: null,
        errorMessage: null,
        relationships: {},
        schema: {}
      };
    }

    componentWillMount() {
      const {resourceName, resourceId} = this.props;
      this.getResourceSchema(resourceName);
      resourceId
        ? this.fetchResource(resourceName, resourceId)
        : this.setState({loaded: true})
    };

    getResourceBaseURL = (resourceName: string) => `${BASE_URL}/v1/profile/${resourceName}`;

    getResourceURL = (resourceName: string, resourceId: string) => `${BASE_URL}/v1/${resourceName}/${resourceId}?projection=deep`;

    getManyRelationship = (value: object) => map(value, val => this.getOneRelationship(val));

    getOneRelationship = (value: object) => getSelfLink(value);

    getManyLabels = (value: object, path: string) => map(value, val => this.getOneLabel(val, path));

    getOneLabel = (value: object, path: string) => _get(value, path, null);

    getResourceSchema = (resourceName: string) => {
      const resourceURL = this.getResourceBaseURL(resourceName);
      get(resourceURL).then((response) => {
        const schema = find(response.alps.descriptor, item => item.id.endsWith("representation"));
        const fields = {};
        map(schema.descriptor, field => fields[field.name] = _get(field, ["doc", "value"]));
        this.setState({schema: fields})
      })
    }

    fetchResource = (resourceName: string, resourceId: string) => {
      const {fields} = this.props;
      const resourceURL = this.getResourceURL(resourceName, resourceId);

      get(resourceURL).then((response) => {
        const data = {...this.props.data};
        const labels = {}
        forEach(response, (value, key) => {
          const field = fields[key];
          if (field) {
            const fieldKey = field.name || key;
            data[fieldKey] = field.relationship
              ? field.relationship === "many"
                ? this.getManyRelationship(value)
                : this.getOneRelationship(value)
              : value;
            labels[fieldKey] = field.relationship
              ? field.relationship === "many"
                ? this.getManyLabels(value, field.labelPath)
                : this.getOneLabel(value, field.labelPath)
              : value;
          }
        });

        data["extensions"] = response.extensions;
        this.setState({
          data,
          labels,
          loaded: true,
          editing: true
        })
      })
    };

    onSubmit = () => {
      const {onSubmit, endpoint, successMessage, history, transformSubmitData} = this.props;
      const {data, patchData} = this.state;

      const defaultState = {
        validationErrors: {},
        successMessage: null,
        errorMessage: null,
      };
      let postData = onSubmit === patch ? patchData : data;
      postData = transformSubmitData ? transformSubmitData(postData) : postData;
      onSubmit(endpoint, postData).then(response => {
        if (this.props.onSuccess) {
          this.props.onSuccess(response)
        } else {
          this.setState({...defaultState, successMessage});
          const resourceUrl = cleanSelfLink(getSelfLink(response), 2);
          history.push(resourceUrl)
        }
      }).catch(response => {
        const validationErrors = response.details && mapDict(response.details, "property", "message");
        const errorMessage = response.message;
        this.setState({
          ...defaultState,
          validationErrors,
          errorMessage
        })
      })
    };
    getWrapperProps = () => {
      const {successMessage, errorMessage, loaded} = this.state;
      const {resourceName, hideCancel, resourceId} = this.props;
      return {
        successMessage,
        errorMessage,
        loaded,
        cancelLink: `/${resourceName}`,
        onSubmit: this.onSubmit,
        hideCancel,
        resourceName,
        resourceId
      }
    };
    onChange = (newData: object) => {
      const key = keys(newData)[0];

      const patchData = {...this.state.patchData, ...newData};
      const data = {...this.state.data, ...newData};

      // if we have an empty string set to null
      if (newData[key] === "") {
        data[key] = null;
        patchData[key] = null
      }

      this.setState({
        data,
        patchData
      })
    };

    render() {
      const {data, labels, schema, validationErrors, editing} = this.state;
      return (
        <FormWrapper {...this.getWrapperProps()}>
          <WrappedComponent {...this.props}
                            schema={schema}
                            data={data}
                            labels={labels}
                            validationErrors={validationErrors}
                            editing={editing}
                            onChange={this.onChange}/>
        </FormWrapper>
      )
    }
  }
};
export default wrapForm