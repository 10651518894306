// @flow
import React from "react";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
  "className": {
    "position": "fixed",
    "pointerEvents": "none",
    "background": "#ffffff",
    "padding": "8px",
    "border": "1px #ccc solid",
    "transition": "opacity .2s",
    "borderRadius": "4px"
  }
})

const Tooltip = ({classes, children, left, top, opacity}) => (
  <div className={classes.className} style={{left: `${left+12}px`, top: `${top}px`, opacity}}>
    {children}
  </div>
)

export default withStyles(styles)(Tooltip)