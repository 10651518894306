import {get, map} from 'lodash'

export function cleanSelfLink(link, depth=1) {
  const parts = link.split("/");
  let urlParts = [];
  for (let i = 1; i < depth+1; i++) {
    urlParts.unshift(parts[parts.length - i])
  }
  return `/${urlParts.join("/")}`
}

export function getResourceId(value) {
  const intermediate = value.split('{')[0]
  const urlComponents = intermediate.split('/')
  return urlComponents[urlComponents.length - 1]
}


export function getReference(resource) {
  return cleanSelfLink(getSelfLink(resource)).split("/")[1]
}
export function removeUriTemplating(link) {
  const parts = link.split("{");
  return parts[0]
}

export const getSelfLink = (resource) => getLink(resource, "self");

export const getLink = (resource, name) => removeUriTemplating(get(resource, ["_links", name, "href"], ""));


export const relativizeURL = (path) => {
  let completePath;
  let url = window.location.pathname.split("/");
  url = url.slice(2, url.length);

  map(path.split("/"), route => {
    if (route === "..") {
      url = url.slice(0, url.length-1)
    } else if (route) {
      url.push(route)
    }
  });
  try {
    completePath = url.join("/")
  } catch(err) {
    completePath = url
  }
  if (completePath.endsWith("/")) {
    completePath.splice(completePath.length-1)
  }
  if (completePath.indexOf("/?") > -1) {
    let firstHalf = completePath.slice(0, completePath.indexOf("/?"))
    let secondHalf = completePath.slice(completePath.indexOf("/?")+1, completePath.length)
    completePath = firstHalf + secondHalf
  }

  return `/${completePath}`
};