import React, {Component} from 'react';
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

const errorColor = "#d62728";
const neutralColor = "#7f7f7f";
const warnColor = "#ff7f0e";
const happyColor = "#1f77b4";
const successColor = "#2ca02c";

const styles = theme => ({
  chip: {
    borderRadius: "4px",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "bolder",
    padding: "4px 8px",
    height: "auto"
  },
  label: {
    padding: "0",
  },
  errorChip: {
    borderColor: errorColor,
    backgroundColor: errorColor
  },
  neutralChip: {
    borderColor: neutralColor,
    backgroundColor: neutralColor
  },
  warnChip: {
    borderColor: warnColor,
    backgroundColor: warnColor
  },
  happyChip: {
    borderColor: happyColor,
    backgroundColor: happyColor
  },
  successChip: {
    borderColor: successColor,
    backgroundColor: successColor
  },
});

const neutral = [
  "Disabled"
];

const happy = [
  "Enabled",
];

const success = [
  "Connected",
];

export default withStyles(styles)(class FormatStatus extends Component {

  getClass = (text) => {
    if (neutral.includes(text)) {
      return this.props.classes.neutralChip
    }
    if (happy.includes(text)) {
      return this.props.classes.happyChip
    }
    if (success.includes(text)) {
      return this.props.classes.successChip
    }
  };

  getText = (isConnected, isEnabled) => {
    if (!isEnabled) {
      return "Disabled"
    }
   if (isConnected) {
     return "Connected"
   }
   return "Enabled"
  };

  render() {
    const {classes, isConnected, isEnabled} = this.props;
    const text = this.getText(isConnected, isEnabled);
    return (
      <Chip className={`${classes.chip} ${this.getClass(text)}`} classes={classes} label={text} variant="outlined"/>
    );
  };
})

