// @flow
import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import ErrorText from "./ErrorText";
import { useTranslation } from 'react-i18next';

const ErrorLoader = (props: *) => {
  const {t} = useTranslation();
  return <Table.NoDataCell {...props} getMessage={() => <ErrorText>{t('unexpectedFetchError')}</ErrorText>} />;
}

export default ErrorLoader