// @flow
import React from 'react'
import ConfirmButton from 'components/ConfirmButton';
import {BASE_URL, post} from 'utils';
import TableAction from 'components/table/actions/TableAction';
import {object} from "prop-types";
import {TableActionProps} from 'types'
import {withTranslation} from 'react-i18next';

type S = {
  validationErrors: object,
  errors: object
}

class BulkDeleteSubscriberStore extends React.Component<TableActionProps, S> {
  state = {
    validationErrors: {},
    errors: {}
  };
  onClick = () => {
    const endpoint = `${BASE_URL}/v1/subs/command/bulkDelete`;
    let values = {
      subscribers:this.props.selectedItems
    };
    post(endpoint, values).then((response) => {
      this.props.renderMessage("success")("Subscribers deleted");
      this.props.changeSelection([]);
      this.props.closeDrawer()
    }).catch((error) => {
      this.props.renderMessage("error")(error.message)
    })

  };
  render() {
    const {togglePanel, name, classes, selectedItems, t, ...props} = this.props;
    return (
      <TableAction togglePanel={togglePanel} name={name} {...props} label={t("bulkDelete")}>
        <div>
          <ConfirmButton
              variant="contained"
              color="primary"
              onClick={this.onClick}
              confirmMessage={t('delete') + ' ' + selectedItems.length + ' ' + t('subscribersQuestion')}
          >{t('submit')}</ConfirmButton>
        </div>
      </TableAction>
    )
  }
}

export default withTranslation()(BulkDeleteSubscriberStore)
