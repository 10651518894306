// @flow
import React from 'react'
import map from 'lodash/map'
import memoize from 'lodash/memoize'
import partial from 'lodash/partial'

import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {array, func, object} from "prop-types";


const styles = theme => ({
  valueField: {
    marginLeft: theme.spacing.unit,
  },
  error: {
    color: 'red'
  }
});

let Extension = ({name, value, onChange, removeField, classes}) => (
  <div className="form-inline my-1">
    <TextField
      readOnly
      disabled
      value={name}
      inputProps={{
        name: "key"
      }}
    />
    <TextField
      value={value}
      inputProps={{
        name: "value"
      }}
      onChange={onChange}
      className={classes.valueField}
    />
    <IconButton value={name} onClick={removeField}>
      <Icon>delete</Icon>
    </IconButton>
  </div>
);

Extension = withStyles(styles)(Extension);

type P = {
  values: array,
  classes: object,
  value: object,
  onChange: func,
}
type S = {
  name: string,
  value: string,
  error: boolean
}

class Extensions extends React.Component<P, S> {
  state = {
    name: "",
    value: "",
    error: ""
  };

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  };

  addField = (e) => {
    e.preventDefault();
    const {name, value} = this.state;
    if (name !== "" && value !== "") {
      this.pushField();
      this.setState({name: "", value: "", error: ""})
    } else {
      this.setState({error: this.props.t('neitherFieldEmptyError')})
    }
  };

  pushField = () => {
    const value = {...this.props.value, [this.state.name]: this.state.value}
    const sanitizedValues = this.sanitizeValues(value)
    this.props.onChange({extensions: sanitizedValues})
  };

  removeField = (key, e) => {
    let value = {...this.props.value};
    value[key] = null
    this.props.onChange({extensions: value})
  };

  modifyField = (key, e) => {
    let value = {...this.props.value, [key]: e.target.value}
    this.props.onChange({extensions: value})
  };

  sanitizeValues = memoize((values) => {
    if (!values) return null

    return Object
      .keys(values)
      .reduce((sanitizedValues, key) => {
        sanitizedValues[key?.trim()] = values[key]?.trim()

        return sanitizedValues
      }, {})
})

  render() {
    const {value = {}, classes, t} = this.props;
    const {name} = this.state;
    
    return (
      <div className="my-3">
        <label>{t('extensions')}</label>
        <div>
          {map(this.sanitizeValues(value), (value, key) => {
            return value
              ? <Extension key={key} name={key} value={value} onChange={partial(this.modifyField, key)}
                           removeField={partial(this.removeField, key)} classes={classes}/>
              : null
          })}
          <div className="form-inline my-1">
            <TextField
              value={name}
              inputProps={{
                name: "name"
              }}
              onChange={this.onChange}
            />
            <TextField
              value={this.state.value}
              inputProps={{
                name: "value"
              }}
              onChange={this.onChange}
              className={classes.valueField}
              onBlur={this.addField}
            />
            <IconButton value={name} onClick={this.addField}>
              <Icon>add</Icon>
            </IconButton>
          </div>
          <div className={classes.error}>
            {this.state.error || this.props.validationError}
          </div>
        </div>
      </div>
    )
  }
}

Extensions = withStyles(styles)(Extensions);
Extensions = withTranslation()(Extensions);

export default Extensions
