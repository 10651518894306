// @flow
import React from 'react'
import TextField from 'components/fields/TextField';
import CodeEditor from 'components/fields/CodeEditor';
import ResourceForm from 'resource/forms/ResourceForm';
import ScriptTypeSelect from 'resource/forms/scripts/fields/ScriptTypeSelect';
import Checkbox from "components/fields/Checkbox";
import {object} from "prop-types";
import CustomerSelect from "../accounts/fields/CustomerSelect";
import Extensions from "../../../components/fields/Extensions";
import VendorSelect from "./fields/VendorSelect";

const fields = {
  "name":{
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "type": {
    "Component": ScriptTypeSelect,
    "name": "type",
    "label": "Type",
    "updatable": false,
    "displayEmpty": false
  },
  "customer": {
    "label": "Customer",
    "labelPath": "name",
    "name": "customer",
    "Component": CustomerSelect,
    "relationship": "one",
    "selectDefault": true,
    "displayEmpty": false,
  },
  "validate": {
    "Component": Checkbox,
    "name": "validate",
    "label": "Validate script",
  },
  "vendor": {
    "Component": VendorSelect,
    "updatable": true,
    "displayEmpty": false,
    "name": "vendor",
    "label": "Network Function Vendor"
  },
  "extensions": {
    "Component": Extensions,
  },
  "body": {
    "Component": CodeEditor,
    "mode": "groovy",
    "name": "body",
    "label": "Body"
  }
};

const defaultData = {
  "name":"",
  "body":"",
  "validate": true,
  "customer": null,
  "vendor": "",
  "extensions": {},
};

const ConfigForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default ConfigForm