import React from 'react';
import MonitoringForm from "resource/forms/monitoring/MonitoringForm";
import {FormInner} from "components/form/FormInner";
import {connect} from "redux-zero/react";
import bottomDrawerAction from "../bottomDrawerAction";
import OpenBottomDrawerButton from "../../row/actions/OpenBottomDrawerButton";

const BottomDrawer = (props) => (
  <FormInner>
    <MonitoringForm {...props} />
  </FormInner>
)

export default connect(null, bottomDrawerAction(BottomDrawer))(OpenBottomDrawerButton)