import {filter, forEach, map} from "lodash"
import _get from "lodash/get";

export function mapDict(list, key, value) {
  if (!list) {
    return {}
  }
  let dict = {};

  map(list, item => dict[item[key]] = _get(item, value));
  return dict
}

export function isNotObject(item) {
  return typeof item !== typeof {};
}

export function isObject(item) {
  return typeof item === typeof {};
}

export const filterByRole = (items, role) => filter(items, item => item.roles.includes(role));

export const mapNestedFields = (nestedFields) => {
  const values = {};
  const errors = {};
  const validationErrors = {};
  const help = {};
  const cachedOptions = {};

  forEach(nestedFields, (value, key) => {
    values[key] = value.value;
    errors[key] = value.error;
    validationErrors[key] = value.validationError;
    help[key] = value.help;
    cachedOptions[key] = {[value.value]: value.valueLabel};
  });
  return {
    values, errors, validationErrors, help, cachedOptions
  }
};

export const rollUpSystems = (customer) => {
  let customerTree = _get(customer, ["systems"], []);
  forEach(customer.children, child => {
    customerTree = [...customerTree, ...rollUpSystems(child)]
  });
  return customerTree.length > 0 ? customerTree : []
};