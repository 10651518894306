import React, {Component} from 'react';
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

const errorColor = "#d62728";
const neutralColor = "#7f7f7f";
const warnColor = "#ff7f0e";
const happyColor = "#1f77b4";
const successColor = "#2ca02c";

const styles = theme => ({
  chip: {
    borderRadius: "4px",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "bolder",
    padding: "4px 8px",
    height: "auto"
  },
  label: {
    padding: "0",
  },
  errorChip: {
    borderColor: errorColor,
    backgroundColor: errorColor
  },
  neutralChip: {
    borderColor: neutralColor,
    backgroundColor: neutralColor
  },
  warnChip: {
    borderColor: warnColor,
    backgroundColor: warnColor
  },
  happyChip: {
    borderColor: happyColor,
    backgroundColor: happyColor
  },
  successChip: {
    borderColor: successColor,
    backgroundColor: successColor
  },
});

export default withStyles(styles)(class FormatStatus extends Component {

  getClass = (isConnected) => {
    if (isConnected) {
      return this.props.classes.successChip
    }
    return this.props.classes.errorChip
  };

  getText = (isConnected, ipAddress) => {
    if (isConnected && ipAddress) {
      return ipAddress
    }
    return "Disconnected"
  };

  render() {
    const {classes, isConnected, ipAddress} = this.props;
    if (isConnected === null) {
      return <div />
    }
    const label = this.getText(isConnected, ipAddress);
    return (
      <Chip className={`${classes.chip} ${this.getClass(isConnected)}`} classes={classes} label={label} variant="outlined"/>
    );
  };
})

