// @flow
import React from 'react';
import {BASE_URL, post} from "utils";
import _get from 'lodash/get';
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from 'react-i18next';
import {func} from "prop-types";

type rowAction =  {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}

class HazelcastClusterDebug extends React.PureComponent<rowAction> {
  onClick = () => {
    const {renderMessage, onClick, value} = this.props;

    const data = {
      "siteUri": value,
    };

    post(`${BASE_URL}/v1/sites/command/dumpClusterMemory`, data).then((response) => {
      renderMessage("success")("Cluster debugged")
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], "There was an error"))
    }).finally(
      onClick()
    )
  };
  render() {
    return <MenuItem onClick={this.onClick}><span>{this.props.t('debugClusterMemory')}</span></MenuItem>
  }
}

export default withTranslation()(HazelcastClusterDebug)
