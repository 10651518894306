// @flow
import React from 'react'
import ConfirmButton from 'components/ConfirmButton';
import {BASE_URL, post} from "utils";
import Select from 'components/fields/Select';
import TableAction from 'components/table/actions/TableAction'
import {object} from "prop-types";
import type {TableActionProps} from "types";
import { withTranslation } from 'react-i18next';


const options = [
  {label: "All Caches", value: null},
  {label: "SUBSCRIBER_ADD", value: "SUBSCRIBER_ADD"},
  {label: "SUBSCRIBER_MODIFY", value: "SUBSCRIBER_MODIFY"},
  {label: "SUBSCRIBER_MOVE", value: "SUBSCRIBER_MOVE"},
  {label: "SUBSCRIBER_FETCH", value: "SUBSCRIBER_FETCH"},
  {label: "SUBSCRIBER_DELETE", value: "SUBSCRIBER_DELETE"},
  {label: "SUBSCRIBER_MOVE", value: "SUBSCRIBER_MOVE"},
  {label: "GATEWAY_ADD", value: "GATEWAY_ADD"},
  {label: "GATEWAY_MODIFY", value: "GATEWAY_MODIFY"},
  {label: "GATEWAY_DELETE", value: "GATEWAY_DELETE"},
];

type S = {
  cacheName: ?string
}
class ClearCache extends React.Component<TableActionProps, S> {
  state = {
    "cacheName": null,
  };
  onClick = () => {
    const endpoint = `${BASE_URL}/v1/sites/command/clearCache`
    let values = {
      siteUris: this.props.selectedItems,
      cacheName: this.state.cacheName
    };
    post(endpoint, values).then((response) => {
      this.props.renderMessage("success")(this.props.t("cacheCleared"))
    }).catch((error) => {
      this.props.renderMessage("error")(error.message)
    })
  };
  onChange = (newData: object) => {
    this.setState(newData)
  };
  getConfirmMessage = () => {
    const cacheName = this.state.cacheName;
    const numberOfSites = this.props.selectedItems;
    const {t} = this.props;
    return cacheName
        ? t('clearCacheForNumberOfSites',{cacheName: cacheName, length: numberOfSites.length})
        : t('clearAllCachesForNumberOfSites', {length: numberOfSites.length})
  }
  render() {
    const cacheName = this.state.cacheName
    const {t} = this.props;
    return (
      <TableAction {...this.props} label={t('clearCache')}>
        <div>
          <Select
              options={options}
              onChange={this.onChange}
              value={cacheName}
              name="cacheName"
              label={t('cacheName')}
              help={t('allCachesBlank')}
          />
          <ConfirmButton
              variant="contained"
              color="primary"
              onClick={this.onClick}
              confirmMessage={this.getConfirmMessage()}
          >{t('submit')}</ConfirmButton>
        </div>
      </TableAction>
    )
  }
}

ClearCache = withTranslation()(ClearCache);

export default ClearCache
