import React, {PureComponent} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {BASE_URL} from "../../utils";
import LoginButton from "./LoginButton";
import { withTranslation } from 'react-i18next';

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    width: "400px"
  },
  helperMessage: {textAlign: "center"},
  loginLink: {
    display: "block",
    margin: "0 auto",
  }
};

class DefaultLoginMessagePage extends PureComponent {
  render() {
    const {classes, helperMessage, t} = this.props;
    const helperTitle = t(`${helperMessage}Header`, { defaultValue: '' })

    // TODO: translate helper message
    return (
      <div className={classes.form}>
        {helperTitle
          ? <h2 className={classes.helperMessage}>{helperTitle}</h2>
          : null
        }
        <p id="reset-message" className={classes.helperMessage}>{t(helperMessage)}</p>
        <a className={classes.loginLink} id="login-link" href={`${BASE_URL}/login`}>
          <LoginButton text={t('goToLogin')}/>
        </a>
      </div>
    )
  }
}

DefaultLoginMessagePage = withTranslation()(DefaultLoginMessagePage)
DefaultLoginMessagePage = withStyles(styles)(DefaultLoginMessagePage)

export default DefaultLoginMessagePage
