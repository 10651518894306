// @flow
import React, {Component} from 'react';
import {connect} from "redux-zero/react";

import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {BASE_URL, getUser} from 'utils'
import {loadAccessRules} from "../config";
import 'App.css';
import ResourceEdit from 'resource/ResourceEdit'
import ResourceDetail from 'resource/detail/ResourceDetail'
import ResourceDelete from 'resource/ResourceDelete'
import ResourceHistory from 'resource/ResourceHistory'
import ResourceList from 'resource/list/ResourceList'
import ResourceNew from 'resource/ResourceNew'
import Home from 'resource/Home'

import Logout from 'components/login/Logout'
import UserAwareRoute from "components/UserAwareRoute";
import {func} from "prop-types";
import LoginDialog from "./login/LoginDialog";
import Profile from "./Profile";
import {getPoweredBy} from "../utils/theme";
import FetchUser from "./login/FetchUser";
import GridLoader from "./GridLoader";
import MaintenanceDialog from "./dialogs/MaintenanceDialog";
import UpdateProfileFromPrompts from "./UpdateProfileFromPrompts";

type P = {
  history: *,
  setUser: func,
  role: string,
  className: string,
  config: *,
  attemptedLogin: boolean
}
type S = {
  loggedIn: boolean,
  attemptedGetUser: boolean
}

const actions = (store) => ({
  setUser: (state, user) => {
    loadAccessRules(user);
    return {user}
  }
});


class Page extends Component<P, S> {

  onSubmit: func;

  state = {
    loggedIn: false,
    attemptedGetUser: false
  };

  attemptedGetUser = () => {
    this.setState({attemptedGetUser: true})
  };

  getUser  = () => {
    if (this.props.config) {
      getUser(`${BASE_URL}/v1/accounts/command/currentUser?projection=withRole`, this.props.config).then((response) => {
        if (response !== undefined) {
          response.username = response.id;
          this.props.setUser(response)
        }
        this.attemptedGetUser()
      })
    }
  };

  render() {
    const {role, className, config, attemptedLogin} = this.props;
    const {attemptedGetUser} = this.state;
    const showLogin = config ? config["showLogin"] : false;
    const ProfileComponent = config ? config["updateProfilePrompts"] ? () => <UpdateProfileFromPrompts prompts={config["updateProfilePrompts"]} /> : Profile : Profile

    if (!config) {
      return <GridLoader />
    }
    return (
      <React.Fragment>
        <div className={className}>
          {!role && !attemptedGetUser && <GridLoader/>}
          {!role && (
            <Switch>
              <Route path='/maintenance'>
                { (!attemptedLogin) ? <LoginDialog/> : <MaintenanceDialog />}
              </Route>
              <Route path={['/error', '/logged-out', '/already-activated', '/user-not-found', '/link-expired']}>
                <LoginDialog/>
              </Route>
              <Route path='/'>
                { showLogin && attemptedGetUser ? <LoginDialog/> : <FetchUser getUser={this.getUser}/>}
              </Route>
            </Switch>
          )}
          {role && <Switch>
            {/*This path array here is duplicated in LoginRouter*/}
            <Redirect exact strict from='/' to="/home"/>
            <Route path={["/login", "/forgot", "/update-password", "/already-activated", "/link-expired"]} component={LoginDialog}/>
            <Route path='/logout' component={Logout}/>
            <Route path='/profile' component={ProfileComponent}/>
            <Route path='/home' component={Home}/>
            <UserAwareRoute name='new' path='/:resourceName/new' Component={ResourceNew}/>
            <UserAwareRoute name='list' path='/:resourceName/search' Component={ResourceList}/>
            <UserAwareRoute name='history' path='/:resourceName/:resourceId/history' Component={ResourceHistory}/>
            <UserAwareRoute name='edit' path='/:resourceName/:resourceId/edit' Component={ResourceEdit}/>
            <UserAwareRoute name='delete' path='/:resourceName/:resourceId/delete' Component={ResourceDelete}/>
            <UserAwareRoute name='detail' path='/:resourceName/:resourceId' Component={ResourceDetail}/>
            <UserAwareRoute name='list' path='/:resourceName' Component={ResourceList}/>
          </Switch>
          }
          {getPoweredBy()}
        </div>
      </React.Fragment>
    )
  }
}

const mapToProps = ({config, attemptedLogin, ...props}) => ({config, attemptedLogin});
Page = connect(mapToProps, actions)(Page);
Page = withRouter(Page);

export default Page