// @flow
import React, {Component} from 'react'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { Link } from 'react-router-dom'
import map from 'lodash/map'

const Anchor = ({children, ...props}) => (
    <a {...props}>{children}</a>
)

class ListItemLink extends React.Component {
  renderInternalLink = itemProps => <Link to={this.props.to} {...itemProps} />;
  renderExternalLink = itemProps => <Anchor href={this.props.to} {...itemProps} />;

  render() {
    const { icon, label, secondary, open, external } = this.props;
    return (
    <ListItem button component={external ? this.renderExternalLink : this.renderInternalLink}>
      {icon && <Icon>{icon}</Icon>}
      {open && <ListItemText inset primary={label} secondary={secondary} /> }
    </ListItem>
    );
  }
}

const drawerWidth = 180
const styles = theme => ({
  drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 9,
      },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
});
class SideNavigation extends Component {
  render() {
    const { role, sideNav, classes, t} = this.props;
    return (
        <Drawer
            variant="permanent"
            open={this.props.open}
            classes={{
                paper: classNames(classes.drawerPaper, !this.props.open && classes.drawerPaperClose)
            }}
        >
          <div className={classes.toolbarIcon}>
              <IconButton onClick={this.props.toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
          </div>
          <Divider />
          { map(sideNav, row => row.roles.includes(role) && <ListItemLink key={row.label} label={t(row.label)} to={row.url} icon={row.icon} external={row.external} open={this.props.open}/>)}
        </Drawer>
    )
  }
}

SideNavigation = withTranslation()(withStyles(styles)(SideNavigation))
export default SideNavigation