// @flow
import React from "react";
import {BASE_URL, post} from "utils";
import MenuItem from "@material-ui/core/MenuItem";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type rowAction =  {
    value: string,
    label: string,
    onClick: func,
    renderMessage: func
}


class CheckSftpAccess extends React.PureComponent<rowAction> {
    onClick = () => {
        const {renderMessage, value} = this.props;
        const data = {
            "sftpAccessUri": value,
        };
        post(`${BASE_URL}/v1/sftp-accesses/command/checkSftpAccess`, data).then((response) => {
            renderMessage("success")(response)
        }).catch((error) => {
            renderMessage("error")(error)
        })
    };
    render() {
        const {t} = this.props;
        return <MenuItem onClick={this.onClick}><span>{t('runSftpAccessCheck')}</span></MenuItem>
    }
}

export default withTranslation()(CheckSftpAccess);
