// @flow
import React from 'react'
import ResourceForm from 'resource/forms/ResourceForm'
import {BASE_URL, get, getEmbeddedResource, mapNestedFields} from 'utils';
import Select from 'components/fields/Select'
import TextField from 'components/fields/TextField'
import CustomerSelector from 'components/selectors/CustomerSelector';
import CustomerSiteSelector from './fields/CustomerSiteSelector';
import NetworkField from './fields/NetworkField';
import {array, object} from "prop-types";
import map from 'lodash/map'
import AutoComplete from "components/fields/AutoComplete";
import _get from "lodash/get";
import find from "lodash/find";
import first from "lodash/first";
import Checkbox from "../../../components/fields/Checkbox";

const templateEndpoint = `${BASE_URL}/v1/interceptor-scripts/search/findByEvent?event=SYSTEM_ADD`;

const defaultSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status",
    "displayEmpty": false,
    "label": "Status"
  },
  "customer": {
    "label": "Customer",
    "labelPath": "name",
    "name": "customer",
    "Component": CustomerSelector,
    "relationship": "one",
    "updatable": false
  },
};

// select name, status, customer, site, and network
const expetoSystemCreate1Fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status",
    "displayEmpty": false,
    "label": "Status"
  },
  "customer": {
    "label": "Customer",
    "name": "customer",
    "labelPath": "name",
    "Component": (props: object) =>
      <CustomerSiteSelector
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
      />,
    "relationship": "one",
    "updatable": false,
    "nestedFields": [
      "site",
      "customer"
    ]
  },
  "network": {
    "Component": TextField,
    "name": "network",
    "label": "Network",
    "help": "The CIDR range of the PGW subnet. eg. 100.64.9.0/24"
  },
  "nat": {
    "Component": Checkbox,
    "name": "nat",
    "label": "Nat"
  },
  "addToAshburnHlr": {
    "Component": Checkbox,
    "name": "addToAshburnHlr",
    "label": "Add To Ashburn Hlr"
  },
  "site": {
    "labelPath": "name",
  }
};

const coldsmokeSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/28273453`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const evenioSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/139812301`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const telnyxPrivateSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
        <NetworkField
            site={`${BASE_URL}/v1/sites/143375002`}
            {...mapNestedFields(props.nestedFields)}
            onChange={props.onChange}
            {...props}
            showNatBox={true}
        />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};


const mitreSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/91144451`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const avanadeSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/166384951`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const necCorpSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/134814401`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const podNuc01SystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/59236901`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const podNuc02SystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/63530601`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const ccrSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/44336151`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};
const podgroupAwsSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/64529001`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};


const rogersPrivateSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/78002002`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const rogersPublicSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/78002001`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={true}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const rogersPublicPrivateSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/78002002`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network",
      "nat"
    ]
  }
};

const demoSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": TextField,
    "name": "network",
    "label": "Network",
    "help": "The CIDR range of the PGW subnet. eg. 100.64.9.0/24"
  }
};

const telnyxSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status",
    "label": "Status",
    "displayEmpty": false
  },
  "customer": {
    "label": "Customer",
    "name": "customer",
    "Component": (props: object) =>
      <CustomerSiteSelector
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
      />,
    "relationship": "one",
    "updatable": false,
    "nestedFields": [
      "site",
      "customer"
    ]
  },
  "site": {
    "labelPath": "name",
  },
  "gateway": {
    "Component": () => <div>
      <br/>
      <p><strong>Gateway details:</strong></p>
    </div>
  },
  "apn": {
    "Component": TextField,
    "name": "APN",
  },
  "network": {
    "Component": TextField,
    "name": "Network",
  },

  "telnyx-bgp-asn": {
    "Component": TextField,
    "name": "telnyx-bgp-asn",
  },
  "telnyx-bgp-container-ip": {
    "Component": TextField,
    "name": "telnyx-bgp-container-ip",
  },
  "telnyx-bgp-external-bridge-ip": {
    "Component": TextField,
    "name": "telnyx-bgp-external-bridge-ip",
  },
  "telnyx-bgp-dns": {
    "Component": TextField,
    "name": "telnyx-bgp-dns",
  },
  "telnyx-customer-network": {
    "Component": TextField,
    "name": "telnyx-customer-network",
  },
};


const demoPartitionedSystemCreateFields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "network": {
    "Component": (props: object) =>
      <NetworkField
        site={`${BASE_URL}/v1/sites/66507401`}
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        {...props}
        showNatBox={false}
      />,
    "name": "network",
    "nestedFields": [
      "host",
      "network"
    ]
  }
};

type S = {
  template: string,
  templateOptions: array
}

class SystemCreateFormByTemplate extends React.Component<object, S> {
  state = {
    template: "ExpetoSystemCreate1",
    templateOptions: []
  };

  componentWillMount(): void {
    get(templateEndpoint).then(response => {
      const templateOptions = map(getEmbeddedResource(response, "interceptor-scripts"),
        value => ({
          "label": value.name,
          "value": value.name
        }));

      const expetoTemplate = find(templateOptions, ["label", "ExpetoSystemCreate1"]);
      const initTemplate = (expetoTemplate != null ? expetoTemplate.label : _get(first(templateOptions), "label"));
      this.setState({ templateOptions: templateOptions, template: initTemplate})
    })
  }

  static getFieldsByTemplate(template: string) {
    switch (template) {
      case "DefaultSystemCreate":
        return defaultSystemCreateFields;
      case "TelnyxSystemCreate":
        return telnyxSystemCreateFields;
      case "ExpetoSystemCreate1":
        return expetoSystemCreate1Fields;
      case "Demo4SystemCreate":
        return demoSystemCreateFields;
      case "Demo5SystemCreate":
        return demoSystemCreateFields;
      case "Demo10SystemCreate":
        return demoSystemCreateFields;
      case "Demo11SystemCreate":
        return demoSystemCreateFields;
      case "FacebookDemoSystemCreate":
        return demoSystemCreateFields;
      case "FedWireAws":
        return demoSystemCreateFields;
      case "FedWireBaremetals":
        return demoSystemCreateFields;
      case "FedWireAwsAndBaremetals":
        return demoSystemCreateFields;
      case "Accuv":
        return demoSystemCreateFields;
      case "SnowconeOnlySystem":
        return demoSystemCreateFields;
      case "GlobalGigDemoSystemCreate":
        return demoSystemCreateFields;
      case "ColdsmokeSystemCreate":
        return coldsmokeSystemCreateFields;
      case "CcrSystemCreate":
        return ccrSystemCreateFields;
      case "PodNuc01SystemCreate":
        return podNuc01SystemCreateFields;
      case "PodNuc02SystemCreate":
        return podNuc02SystemCreateFields;
      case "PodgroupAwsSystemCreate":
        return podgroupAwsSystemCreateFields;
      case "DemoPartitionedSystemCreate":
        return demoPartitionedSystemCreateFields;
      case "RogersPrivateSystemCreate":
        return rogersPrivateSystemCreateFields;
      case "RogersPublicSystemCreate":
        return rogersPublicSystemCreateFields;
      case "RogersSystemCreate":
        return rogersPublicPrivateSystemCreateFields;
      case "MitreSysCreate":
        return mitreSystemCreateFields;
      case "NECCorpSystemCreate":
        return necCorpSystemCreateFields;
      case "EvenioSystemCreate":
        return evenioSystemCreateFields;
      case "TelnyxPrivateSystemCreate":
        return telnyxPrivateSystemCreateFields;
      case "AvanadeSystemCreate":
        return avanadeSystemCreateFields;
      default:
        return null
    }
  }

  onChangeTemplate = (newData: object) => {
    this.setState(newData)
  };

  transformSubmitData = (data: object) => {
    return {
      "template": data.template,
      "args": {...data}
    }
  };

  render() {
    const {template, templateOptions} = this.state;
    const fields = SystemCreateFormByTemplate.getFieldsByTemplate(template);
    const endpoint = `${BASE_URL}/v1/sys/command/createSystem`;
    return (
      <React.Fragment>
        {templateOptions.length > 1 &&
        <div style={{"padding": "15px"}}>
          <AutoComplete name="template" onChange={this.onChangeTemplate} value={template} options={templateOptions}/>
        </div>
        }
        {fields && <ResourceForm
          key={template}
          {...this.props}
          endpoint={endpoint}
          fields={fields}
          defaultData={
            {
              "nat": true,
              "addToAshburnHlr": true,
              template
            }
          }
          transformSubmitData={this.transformSubmitData}
        />}
      </React.Fragment>
    )
  }
}

const SystemForm = (props: object) => (
  <SystemCreateFormByTemplate {...props} />
);
export default SystemForm
