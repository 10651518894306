// @flow
import React from 'react'
import Paper from '@material-ui/core/Paper';

import {withStyles} from '@material-ui/core/styles';
import RowActionMenu from "../components/row/actions/RowActionMenu";
import { withTranslation } from 'react-i18next';
import {object} from "prop-types";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit*2,
    borderRadius: "4px",
    border: "solid 1px #fff"
  },
  resourcePageHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    background: "#fff",
  },
  resourcePageControls: {
    display: "flex",
    justifyContent: "space-between"
  }
});

type P = {
  title: string,
  controls: object,
  classes: object,
  rowActions: object
}
class ResourceHeader extends React.PureComponent<P> {
  render() {
    const {title, controls, classes, rowActions, t} = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.resourcePageHeader}>
          <h2>{t(title)}</h2>
          <div className={classes.resourcePageControls}>
            <div>{controls}</div>
            { rowActions && <RowActionMenu value={rowActions} />}
          </div>
        </div>
      </Paper>
    )
  }
}
ResourceHeader = withTranslation()(withStyles(styles)(ResourceHeader));
export default ResourceHeader
