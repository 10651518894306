// @flow
import React from 'react'
import {BASE_URL, get} from "utils";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";
import _get from "lodash/get";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmButton from "../../ConfirmButton";
import {withRouter} from "react-router-dom";

type P = {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func
}
class RevokeApiToken extends React.PureComponent<P> {
  onClick = () => {
    const {renderMessage, t, value, location} = this.props;
    let ref = ""
    let model = ""
    if (location.pathname.includes("accounts")) {
      model = "accounts"
      ref = `account=${BASE_URL}/v1${location.pathname}`
    } else {
      model = "sites"

      ref = `site=${BASE_URL}/v1${location.pathname}`
    }
    get(`${BASE_URL}/v1/${model}/command/deleteToken?name=${value}&${ref}`).then((response) => {
      renderMessage("success")(t('apiTokenDeleteSuccess'));
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], t('apiTokenDeleteError')));
    })
  };
  render() {
    const {t} = this.props;
    return <ConfirmButton component={MenuItem} onClick={this.onClick} confirmMessage={t('confirmDelete')}><span>{t('delete')}</span></ConfirmButton>
  }
}

export default withTranslation()(withRouter(RevokeApiToken))
