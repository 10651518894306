import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { withTranslation } from "react-i18next"
import { Icon, Menu, MenuItem } from "@material-ui/core"
import { getSupportedLanguages, setLanguage } from "../utils/locales/index"

const styles = theme => ({
  text: {
    color: 'black',
    marginRight: '24px',
    cursor: 'pointer'
  },
  arrow: {
    verticalAlign: "top"
  },
});

class SelectLocale extends React.Component {
  state = {
    anchorEl: null
  }
  openMenu = event => {
    this.setState( { anchorEl: event.currentTarget } )
  }
  selectLanguage = language => {
    setLanguage(language)
    this.handleClose()
  }
  handleClose = () => {
    this.setState( { anchorEl: null } )
  }
  render() {
    const { classes, t } = this.props;
    const { anchorEl } = this.state;
    return (
      <React.Fragment>
        <span
          id="language-menu-link"
          onClick={this.openMenu}
          className={classes.text}
        >
          {t('selectLanguage')}
          <span className={classes.arrow}>
            <Icon>arrow_drop_down</Icon>
          </span>
        </span>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
        >
          {getSupportedLanguages().map(language => (
            <MenuItem onClick={this.selectLanguage.bind(null, language)}>
              {language}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )
  }
}

SelectLocale = withTranslation()(SelectLocale)
SelectLocale = withStyles(styles)(SelectLocale)

export default SelectLocale
