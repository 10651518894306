// @flow
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
});

let DefaultSnackbar = ({open, onClose, message, classes, messageType}) => (
  <Snackbar
    anchorOrigin={{ vertical:"top", horizontal:"right" }}
    open={open}
    onClose={onClose}
    autoHideDuration={6000}
  >
    <SnackbarContent className={classes[messageType]} message={<span>{message}</span>}/>
  </Snackbar>
);

DefaultSnackbar = withStyles(styles)(DefaultSnackbar);
export default DefaultSnackbar