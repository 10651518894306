// @flow
export function formatIMEI(imei: string): string {
  if (!imei) {
    return '';
  }
  if (imei.length > 14) {
    return imei.substring(0,14)
  }
  return imei
}
