import React from "react";
import RelativeLink from "../../components/RelativeLink";
import {cleanSelfLink} from "../../utils/links";

const PrimaryKeyFormatter = ({value}) => {
  return (
    <div>
      <RelativeLink to={cleanSelfLink(value.link)}>{value.value}</RelativeLink>
    </div>
  )
};

export default PrimaryKeyFormatter