import {get} from "../fetch";
import {BASE_URL} from "../index";
import {timezoneSearch} from "../time/timezonesData";

export const searchCustomerByName = (searchString, projection = "withSystems") => get(`${BASE_URL}/v1/customers/search/findByName?projection=${projection}&size=10&searchString=${searchString}`);
export const searchSubscriberByDeepSearch = (searchString, projection = "brief") => get(`${BASE_URL}/v1/subs/search/findByDeepSearchBrief?projection=${projection}&size=10&searchString=${searchString}`);
export const searchKeysetByName = (searchString, projection = "brief") => get(`${BASE_URL}/v1/keysets/search/findByName?projection=${projection}&size=10&searchString=${searchString}`);
export const searchKeysetByCustomerAndName = (customer, searchString, projection = "brief") => get(`${BASE_URL}/v1/keysets/search/findByCustomerAndName?projection=${projection}&size=10&customer=${customer}&name=${searchString}`);
export const findAllRoles = (searchString, projection = "brief") => get(`${BASE_URL}/v1/account-roles/command/findAllRoles?projection=${projection}`);

export const searchCarrierByOperator = (searchString) => get(`${BASE_URL}/v1/carriers/search/findByOperator?size=10&searchString=${searchString}`);
export const searchTimezone = async (searchString) => timezoneSearch(searchString)