// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import {get} from "utils";
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get'
import {func} from "prop-types";

type MessageType = "success" | "error"

type P = {
  endpoint: string,
  errorMessage?: string,
  message: string,
  renderMessage: func<MessageType>,
  onClick: func,
  label: string
}

class RowAction extends React.PureComponent<P> {
  onClick = () => {
    const {endpoint, message, errorMessage, renderMessage, onClick, t} = this.props;
    get(endpoint).then((response) => {
      renderMessage("success")(t(message));
      onClick()
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], errorMessage || t("error")));
      onClick()
    })
  };
  render() {
    const {label, t} = this.props;
    return <MenuItem onClick={this.onClick}><span>{t(label)}</span></MenuItem>
  }
}

export default withTranslation()(RowAction)
