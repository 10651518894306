// @flow
import {BASE_URL, get, getEmbeddedResource} from 'utils'
import map from 'lodash/map';
import Select from 'components/fields/Select';
import withOptions from 'hoc/withOptions';

const endpoint = `${BASE_URL}/v1/interceptor-scripts/command/getEvents`;

export const fetchResourceOptions = (endpoint: string, resourceName: string) => () => {
  return get(endpoint).then(response => {
    return map(getEmbeddedResource(response, resourceName),
      val => ({"label": val, "value": val})
    )
  })
};
const ScriptTypeSelect = withOptions(fetchResourceOptions(endpoint, "interceptorScriptTypes"))(Select);
export default ScriptTypeSelect