import React from 'react';
import SftpAccessForm from "../../../resource/forms/sftpAccess/SftpAccessForm";
import {FormInner} from "../../form/FormInner";
import {connect} from "redux-zero/react";
import bottomDrawerAction from "../bottomDrawerAction";
import Button from "@material-ui/core/Button";


const BottomDrawer = (props) => (
  <FormInner>
    <SftpAccessForm {...props} />
  </FormInner>
)


const SftpAccessButton = (props) => <Button onClick={props.openBottomDrawer} variant="outlined">{props.label}</Button>
export default connect(null, bottomDrawerAction(BottomDrawer))(SftpAccessButton)