// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles';

import MaterialTextField from '@material-ui/core/TextField';
import {array, func} from "prop-types";

import { withTranslation } from 'react-i18next';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
  },
});

type P = {
  name: string,
  onChange: func,
  onBlur: func,
  label: string,
  value :string,
  error: string,
  validationError: string,
  help: string,
  className: string,
  readOnly: boolean,
  displayEmpty: boolean,
  type: string,
  variant: string,
  rows: array,
  multiline: boolean
}

class TextField extends React.PureComponent<P> {
  onChange = (e) => {
    this.props.onChange({[this.props.name] :e.target.value})
  };
  getHelpText = () => {
    const {help, validationError} = this.props;
    let helpTextWithError = [help, validationError].join(' - ');
    return helpTextWithError.endsWith(' - ')
      ? help
      : helpTextWithError
  };
  render() {
    const {label, name, value, error, type, variant, rows, multiline, readOnly, onBlur, t} = this.props;
    return (
      <MaterialTextField
        multiline={multiline}
        rows={rows}
        variant={variant || 'standard'}
        inputProps={{name}}
        label={t(label)}
        value={value || ''}
        onChange={this.onChange}
        fullWidth={true}
        helperText={this.getHelpText()}
        margin='normal'
        error={error}
        type={type || 'text'}
        disabled={readOnly}
        onBlur={onBlur}
      />
    )
  }
}

TextField = withTranslation()(TextField);
TextField = withStyles(styles)(TextField);
export default TextField