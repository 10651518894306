import React from 'react'
import {Component} from 'react'
import {Chip} from '@material-ui/core'
import FormatStatus from '../utils/format/FormatStatus'
import {formatBytes} from '../utils/format/formatBytes'
import {formatInterval} from '../utils/format/formatInterval'
import {formatDate} from '../utils/format/formatDate'
import {withStyles, Card} from '@material-ui/core'
import {withTranslation} from 'react-i18next'

const style = theme => ({
  spread: {
    display: 'flex',
    width: '100%',
    height: '50%',
    padding: '8px',
    fontSize: '0.8125rem',
    justifyContent: "space-between",
    fontWeight: "bold",
  },
  pad: {
    padding: '8px',
  },
  levelText: {
    padding: '8px',
    'padding-bottom': '2px',
  },
  chip: {
    background: 'white',
    padding: "6px 0px",
    height: "auto",
  },
  label: {
    padding: "0",
  },
  statusLabel: {
    paddingBottom: "8px",
    fontWeight: "normal",
    fontSize: '1rem',

    color: "rgba(0, 0, 0, 0.54)",
  },
})

class StatusPane extends Component {
  render() {
    const {state, session, classes, t} = this.props


    if (!session) {
      return null
    }
    // If we don't have a pgw session or the pgw session is closed show time disconnected
    const timeConnectedLabel = !session.usageCdrId || session.stopTime ? t('timeSinceConnected') : t('timeConnected')

    // If this is state provisioned it will never have been connected.
    const timeConnected = session.state !== "Provisioned"
      ? session.stopTime
        ? formatInterval(session.stopTime)
        : formatInterval(session.startTime)
      : t("Never")

    // Only use the last seen on a usageCdr
    const lastSeen = session.usageCdrId && session.lastSeen ? formatDate(session.lastSeen) : "Never"

    return (
      <div className={classes.pad}>
        <Card className={classes.spread}>
          <div className={classes.pad}>
            <div className={classes.statusLabel}>
              {t('status')}
            </div>
            <FormatStatus text={state} ipAddress={session.ip4Address} />
          </div>
          <div className={classes.pad}>
            <div className={classes.statusLabel}>
              {timeConnectedLabel}
            </div>
             <Chip className={classes.chip} label={timeConnected} classes={classes} />
          </div>
          <div className={classes.pad}>
            <div className={classes.statusLabel}>
              {t('lastSeen')}
            </div>
            <Chip className={classes.chip} label={lastSeen} classes={classes} />
          </div>
          <div className={classes.levelText}>
            <div className={classes.statusLabel}>
              {t('usage')}
            </div>
            <Chip className={classes.chip} label={formatBytes(session.upload + session.download) || '0 B'} classes={classes} />
          </div>
        </Card>
      </div>
    )
  }
}

StatusPane = withTranslation()(StatusPane)
StatusPane = withStyles(style)(StatusPane)
export default StatusPane
