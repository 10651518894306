import _get from "lodash/get"
import {BASE_URL, patch, post} from "utils";

export default (drawerComponent) => (store, ownProps) => ({
  openBottomDrawer: (state, e) => {
    e.stopPropagation()
    const defaultData = _get(ownProps, ["row"])
    const onSubmit = ownProps.edit ? patch : post
    const endpoint = _get(ownProps, ["endpoint"], `${BASE_URL}/v1/${ownProps.resourceName}`)
    return {bottomDrawer: {
        ...state.bottomDrawer,
        Component: drawerComponent,
        componentProps: {
          ...ownProps,
          endpoint,
          onSubmit,
          onSuccess: (response) => ownProps.onSuccess ? ownProps.onSuccess(response) : window.location.reload(false),
          defaultData
        },
        open: true
      }
    }
  }
});