import React from 'react';
import {array, func} from 'prop-types';
import {NestedSelector} from "../../utils";
import AsyncAutoComplete from "../fields/AutoCompleteAsync";
import {searchTimezone} from "../../utils/search";
import {withStyles} from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import {getDefaultTimezone} from "../../utils/time/timezonesData";

const styles = theme => ({
    formControl: {
        display: 'flex',
    },
    nestedControl: {
        marginLeft: theme.spacing.unit * 3,
    },
});

type P = {
    name: string,
    onChange: func,
    readOnly: boolean,
    label: string,
    value: string,
    valueLabel: string,
    error: string,
    validationError: string,
    help: string
}
type S = {
    timezones: array,
    showTimezones: boolean,
    searchString: string
}

class TimezoneSelector extends React.Component<P,S> {
    state = {
        timezones: [],
        showTimezones: false,
        searchString: "",
    };

    loadOptions = (searchString, callback) => {
        const {name} = this.props;

        searchTimezone(searchString).then((response) => {
            const timezones = response

            this.setState({"showTimezones": true, timezones}, () => {
                const oneAndOnlyOption = NestedSelector.getOptionIfOnlyOption(timezones);
                if (oneAndOnlyOption) {
                    this.onChange({[name]: oneAndOnlyOption});
                }
            });
            callback(timezones)
        })
    };

    onChange = (newData) => {
        const {onChange, readOnly} = this.props;
        if (readOnly) {
            return
        }
        onChange(newData)
    };

    render() {
        const {label, value, valueLabel, error, validationError, help, readOnly, name, t} = this.props;
        const {timezones} = this.state;

        return (
            <AsyncAutoComplete
                value={value}
                error={error}
                validationError={validationError}
                help={help}
                options={timezones}
                name={name}
                label={t(label)}
                onChange={this.onChange}
                readOnly={readOnly}
                displayEmpty={false}
                cacheOptions={true}
                loadOptions={this.loadOptions}
                defaultOptions={true}
                cachedOptions={{value:valueLabel}}
            />
        )
    }
}

TimezoneSelector = withTranslation()(TimezoneSelector);
TimezoneSelector = withStyles(styles)(TimezoneSelector);
export default TimezoneSelector;