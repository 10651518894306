// @flow
import React from 'react'
import map from 'lodash/map'
import {BASE_URL, get, getSelfLink} from 'utils'
import {withStyles} from '@material-ui/core/styles';
import {func, object} from "prop-types";
import AutoComplete from "components/fields/AutoComplete";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

type Option = {
  label: string,
  value: string,
}
type S = {
  siteOptions: Option[],
  searchString: string
}

type P = {
  resourceId: string,
  value: string,
  error: string,
  validationError: string,
  classes: object,
  onChange: func,
  cachedOptions: object
}

class CustomerSitesSelector extends React.Component<P, S> {
  state = {
    siteOptions: [],
    searchString: ""
  };

  rollUpSites(customer) {
    let sites = map(customer.sites, site => ({...site, customer: customer}));
    if (!customer.parent) {
      return sites
    }
    sites = sites.concat(this.rollUpSites(customer.parent));
    return sites
  }

  componentWillMount() {
    const {resourceId} = this.props;
    if (!resourceId) {
      return
    }
    get(`${BASE_URL}/v1/sys/${resourceId}?projection=withCustomerAndSubFeature&size=100`).then((response) => {
      const sites = this.rollUpSites(response.customer);
      const siteOptions = map(sites, site => ({
        label: `${site.name} - ${site.customer.name}`,
        value: getSelfLink(site)
      }));
      this.setState({siteOptions})
    })
  }

  onChange = state => (newData) => {
    const {onChange} = this.props;
    onChange(newData)
  };

  render() {
    const onChange = this.onChange(this.state);
    const {value, error, validationError, classes, cachedOptions, t} = this.props;
    const {siteOptions} = this.state;
    return (
      <div>
        <AutoComplete
          value={value}
          error={error}
          validationError={validationError}
          options={siteOptions}
          name="sites"
          label={t('sites')}
          onChange={onChange}
          className={classes.formControl}
          multiple
          cachedOptions={cachedOptions}
        />
      </div>
    )
  }
}

CustomerSitesSelector = withTranslation()(CustomerSitesSelector);
CustomerSitesSelector = withStyles(styles)(CustomerSitesSelector);
export default CustomerSitesSelector