import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
  state = {
    open: false,
    done: false
  };

  handleClickOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })

  executes = () => {
    this.props.execute()
    this.setState({ open: false, done: true })
  }

  toggleOpen = () => {
    if(this.state.open) return this.setState({ open: false })
    this.setState({ open: true })
  }

  render() {
    const {title, content, confirmText, cancelText} = this.props
    const {done}  = this.state
    if (done) return null
    return (
      <Button onClick={this.toggleOpen}>
        {confirmText}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {cancelText}
            </Button>
            <Button onClick={this.executes} color="primary" autoFocus>
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </Button>
    );
  }
}

export default AlertDialog