// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "redux-zero/react";
import * as Sentry from '@sentry/browser';
import store from 'store.js'

const WrappedApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);


Sentry.init({
  blacklistUrls: ["localhost", "127.0.0.1"],
  dsn: "https://48d0ae04fda84f5080ef01590f6cbfba@sentry.io/1424464"
});

const container = document.getElementById('root');
if (container) {
  ReactDOM.render(<WrappedApp />, container);
}

