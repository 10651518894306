// @flow
import React from 'react'

import {withStyles} from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';

import ValidationError from '../ValidationError'
import {func} from "prop-types";

const styles = theme => ({
    formControl: {
        display: 'flex',
    },
});


type P = {
    name: string,
    label: string,
    value: bool,
    validationError: string,
    onChange: func,
    disabled: bool
};

class Checkbox extends React.PureComponent<P> {
    onChange = (e) => {
        this.props.onChange({[this.props.name] :e.target.checked})
    };

    render() {
        const {name, label, value, validationError, disabled} = this.props;
        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <MaterialCheckbox
                            onChange={this.onChange}
                            name={name}
                            value={name}
                            checked={value}
                            color="primary"
                            disabled={disabled}
                        />
                    }
                    label={label}/>
                <ValidationError error={validationError}/>
            </FormGroup>
        )
    }
}

Checkbox = withStyles(styles)(Checkbox);
export default Checkbox