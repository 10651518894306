// @flow
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'

class FetchUser extends PureComponent<*> {
  componentWillMount() {
    this.props.getUser()
  }
  render(){
    return (<div></div>)
  }
}
FetchUser = withTranslation()(FetchUser)
export default FetchUser