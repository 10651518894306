// @flow
import React, {Component} from 'react';
import {array, func} from "prop-types";
import {Link} from "react-router-dom";

import {withStyles} from '@material-ui/core/styles';
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from '@material-ui/core/TextField';
import {BASE_URL, get} from "utils";
import LoginButton from "./LoginButton";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "250px"
  },
  helperMessage: {textAlign: "center"}
});

type P = {
  onSubmit: func,
  classes: array,
}
type S = {
  emailAddress: string,
  emailSent: boolean
}

class ForgotPassword extends Component<P, S> {
  state = {
    emailAddress: "",
    emailSent: false
  };
  onSubmit = (e) => {
    e.preventDefault();
    get(`${BASE_URL}/v1/accounts/command/createPasswordResetToken?emailAddress=${this.state.emailAddress}`);
    this.setState({emailSent: true})
  };
  onChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  render() {
    const {emailAddress, emailSent} = this.state;
    const {classes, t} = this.props;
    return (
      <React.Fragment>
        {emailSent && <FormHelperText id="reset-message" className={classes.helperMessage}>{t('passwordResetEmailSent')}</FormHelperText>}
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField
            id="email-address"
            label="Email"
            value={emailAddress}
            onChange={this.onChange('emailAddress')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <Link id="login-link" to="/login">{t('backToLogin')}</Link>
          <LoginButton text={t('requestPasswordResetEmail')}/>
        </form>
      </React.Fragment>
    )
  }
}

ForgotPassword = withStyles(styles)(ForgotPassword);
ForgotPassword = withTranslation()(ForgotPassword);

export default ForgotPassword
