import {getHeaders, handleResponse, serializeFormdata, handleLogin} from 'utils/fetch/common';

export function post(url, data) {
  return fetch(`${url}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders()
    },
    body: JSON.stringify(data)
  }).then(handleResponse);
}

export function postLogin(url, data) {
  return fetch(`${url}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getHeaders(),
    },
    body: JSON.stringify(data)
  }).then(handleLogin);
}
export function postFormdata(url, data) {
  return fetch(`${url}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...getHeaders()
    },
    body: serializeFormdata(data)
  }).then(handleResponse);
}
