import PoweredBy from "../../components/PoweredBy";
import React from "react";


const isRogersHost = () => {
  return window.location.hostname.includes("rogers")
};

export const getPoweredBy = () => {
  if (isRogersHost()) {
    return <PoweredBy />
  }
  return null
};

export const getLogoUrl = () => {
  if (isRogersHost()) {
    return "/images/rogers/logo.png"
  }
  return "/images/default/logo.png"
};