// @flow
import React, { PureComponent } from 'react';
import {BASE_URL, get} from '../../utils';


class Logout extends PureComponent<*> {
  componentWillMount() {
    get(`${BASE_URL}/logout`).then((response) => {
      window.location = "/ui/logged-out"
    })
  }
  render(){
    return (
      <div />
    )
  }
}

export default Logout