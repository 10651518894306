import React from 'react';
import moment from "moment";
import {filter, get, map} from "lodash";
import {BASE_URL, formatBytes, formatDate, formatIMEI, formatTemplatedLink, getReference, getSelfLink} from 'utils';
import CustomerTable from 'components/table/CustomerTable';
import UsageBarChart from "components/charts/UsageBarChart";
import DevicePieChart from "components/charts/DevicePieChart";
import CellLocationMapContainer from "./components/maps/CellLocationMapContainer";

import FormatStatus from "./utils/format/FormatStatus";
import FormatPrivateRadioStatus from "./utils/format/FormatPrivateRadioStatus";
import FormatGatewayStatus from "./utils/format/FormatGatewayStatus";
import Checkbox from "./components/fields/Checkbox";
import FormatBoolean from "./utils/format/FormatBoolean";
import FormatFile from "./utils/format/FormatFile";
import ModuleTable from "./resource/detail/modules/ModuleTable";
import FormatToken from "./utils/format/FormatToken";
import FormatSiteStatus from "./utils/format/FormatSiteStatus";
import ScriptActive from "./resource/forms/scripts/fields/ScriptActive";
import {getReportCard} from "./resource/forms/report/ReportTypes";

export const featureFlags = new Map();
featureFlags.set('displayMonitoringSiteFeature', true)
featureFlags.set('addSiteMonitor', false)

// We should eventually move this out of being a global variable
export let currentPermissions = {};

export const currentPageData = {};

export const loadAccessRules = (currentUser) => {
  map(initRules, rule => rule(currentUser));
};

export const initRules = [
  (currentUser) => {
    currentPermissions = currentUser.permissions
  },
  (currentUser) => {
    if (!currentUser.canCreateSystem && currentPermissions["sys"]) {
      currentPermissions["sys"] = currentPermissions["sys"].filter(v => v !== "new")
    }
  }
];

export const sideNav = [
  {
    "label": "home",
    "url": "/home",
    "icon": "home",
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "subscribers",
    "url": "/subs",
    "icon": "smartphone",
    "search": true,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "sites",
    "url": "/sites",
    "icon": "router",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "systems",
    "url": "/sys",
    "icon": "laptop",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "groups",
    "url": "/sub-groups",
    "icon": "label",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "profiles",
    "url": "/sub-profiles",
    "icon": "settings_input_composite",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "accounts",
    "url": "/accounts",
    "icon": "person",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "customers",
    "url": "/customers",
    "icon": "people",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
  {
    "label": "devices",
    "url": "/devices",
    "icon": "devices",
    "search": false,
    "roles": ["SUPERUSER"],
  },
  {
    "label": "carriers",
    "url": "/carriers",
    "icon": "settings_input_antenna",
    "search": false,
    "roles": ["SUPERUSER"],
  },
  {
    "label": "reports",
    "url": "/reports",
    "icon": "leaderboard",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"],
  },
  {
    "label": "files",
    "url": "/files",
    "icon": "description",
    "search": true,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"],
  },
  {
    "label": "notifications",
    "url": "/notifications",
    "icon": "flash_on",
    "search": false,
    "roles": ["SUPERUSER"],
  },
  {
    "label": "scripts",
    "url": "/interceptor-scripts",
    "icon": "code",
    "search": false,
    "roles": ["SUPERUSER"],
  },
  {
    "label": "configuration",
    "url": "/server-configs",
    "icon": "folder",
    "search": false,
    "roles": ["SUPERUSER"],
  },
  {
    "label": "audit",
    "url": "/authentication-events",
    "icon": "menu_book",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN"],
  },
  {
    "label": "keysets",
    "url": "/keysets",
    "icon": "vpn_key",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN"],
  },
  {
    "label": "logout",
    "url": "/logout",
    "icon": "logout",
    "search": false,
    "roles": ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY", "CUSTOMER_READ_ONLY"],
  },
];

export const resourceNames = {
  sys: "System",
  subs: "Subscribers",
  "sub-groups": "Groups",
  "sub-profiles": "Profiles",
  sites: "Sites",
  accounts: "Accounts",
  "interceptor-scripts": "Scripts",
  "system-template-scripts": "System Templates",
  "customers": "Customers",
  "gatewayfeature": "Gateways",
  "notifications": "Notifications",
  "devices": "Devices",
  "carriers": "Carriers",
  "files": "Files",
  "private-radios": "Private Radios",
  "gatewayhostfeature": "Gateway Hosts",
  "privateradiositefeature": "Private Radios",
  "keysetmappingsitefeature": "Keysets",
  "apitokensitefeature": "Api Tokens",
  "apitokenaccountfeature": "Api Tokens",
  "keysets": "Keysets",
  "imsis": "IMSIs",
  "sub-imsis": "Subscriber IMSI",
  "keyset-mappings": "Keyset Mappings",
  "sftp-accesses": "SFTP Accesses",
  "sftpaccesscustomerfeature": "SFTP Accesses",
  "monitoringsitefeature": "Monitoring",
  "monitoring-endpoint": "Monitoring Endpoints",
  "sms": "SMS Event Logs",
  "reports": "Reports",
};

export const detailViews = {
  session: {
    ipAddress: {
      label: "Address",
    },
    imei: {
      label: "IMEI",
    },
    state: {
      label: "State",
    },
    site: {
      label: "Site",
    },
    system: {
      label: "System",
    },
    lastSeenTimestamp: {
      label: "Last seen",
    },
  },
  profile: {
    name: {
      label: "Name",
    },
    defaultApn: {
      label: "Default apn",
    },
    downloadBitRate: {
      label: "Download Bit Rate",
    },
    uploadBitRate: {
      label: "Upload Bit Rate",
    },
  },
  subscriber: {
    imsi: {
      label: "IMSI"
    },
    msisdn: {
      label: "MSISDN"
    },
    status: {
      label: "Status"
    },
    type: {
      label: "Type"
    },
    iccid: {
      label: "ICCID"
    },
  },
  groups: {
    name: {
      label: "Name",
    }
  },
  sms: {}
};

export const headings = {
  "sub-imsis": {
    "list": [
      {
        title: "IMSI",
        name: "imsi",
        getCellValue: row => get(row, "imsi")
      },
    ],
  },
  subs: {
    "endpoint": "/search/findByDeepSearchBrief",
    "getDefaultParams": row => ({
      // get the days since the start of the month so we can calculate billing period
      "maxAgeAggregation": moment.duration(moment().diff(moment().startOf("month"))).days()
    }),
    "getSelfLink": (row) => {
      return `/v1/subs/search/findByImsiWithSessionReport?searchString=${row.imsi}`
    },
    "search": true,
    "exportCSV": true,
    "exportCSVEndpoint": "/command/exportToCsvByDeepSearch",
    "searchShortcuts": [
      {
        "match": (searchString) => ["3G", "4G"].includes(searchString.toUpperCase()),
        "transform": (searchString) => `status=${searchString}`
      }
    ],
    "list": [
      {
        title: "IMSI",
        name: "imsi",
        getCellValue: row => {
          return {
            "value": get(row, "imsi"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customerName"),
      },
      {
        title: "System",
        name: "system",
        path: "session.system",
        getCellValue: row => get(row, "systemName"),
      },
      {
        title: "Site",
        name: "site",
        path: "session.site",
        getCellValue: row => get(row, "siteName"),
      },
      {
        title: "Device",
        name: "device",
        path: "session.device",
        getCellValue: row => get(row, "modelName"),
      },
      {
        title: "Usage",
        name: "usage",
        path: "session.usage",
        getCellValue: row => {
          const upload = get(row, "upload", 0);
          const download = get(row, "download", 0);
          return formatBytes(upload + download, 3)
        },
      },
      {
        title: "Status",
        name: "status",
        path: "session.status",
        getCellValue: row => get(row, "state") && <FormatStatus text={get(row, "state")} ipAddress={get(row, "ipAddress")}/>
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "visualizations": [
      data => {
        const cellLocation = get(data, "sessions.0.cellLocation");
        return <CellLocationMapContainer data={cellLocation}/>
      },
      data => <UsageBarChart
        key={`usage-${data.imsi}`}
        id={`usage-${data.imsi}`}
        endpoint={`/v1/subs/command/getUsageReportByImsi?reference=${data.imsi}&`}
        groupByOptions={[
          {"value": "system", "label": "System"},
          {"value": "site", "label": "Site"},
          {"value": "direction", "label": "Direction"}
        ]}/>
    ],
    "primaryColumns": ["imsi"],
    "audit": {
      "endpoint": (entity, changedFields, username) =>
        `${BASE_URL}/v1/subs/command/getWithRevisions?entity=${entity}&changedFields=${changedFields}&username=${username}&projection=deep&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Revision Type",
          name: "revisionType",
          getCellValue: (row) => get(row, "revisionType")
        },
        {
          title: "IMSI",
          name: "imsi",
          getCellValue: (row) => get(row, "entity.imsi"),
        },
        {
          title: "Customer",
          name: "customer",
          getCellValue: (row) => get(row, "entity.customer.name"),
        },
        {
          title: "System",
          name: "system",
          getCellValue: (row) => get(row, "entity.system.name"),
        },
        {
          title: "Profile",
          name: "profile",
          getCellValue: (row) => get(row, "entity.profile.name"),
        },
        {
          title: "Status",
          name: "status",
          getCellValue: (row) => get(row, "entity.status"),
        },
      ]
    }
  },
  "private-radios": {
    "list": [
      {
        title: "Radio ID",
        name: "enodebId",
        getCellValue: row => {
          return {
            "value": get(row, "enodebId"),
            "link": getSelfLink(row)
          }
        }
      },
      {
        title: "IP Address",
        name: "ipAddress",
        getCellValue: row => get(row, "ipAddress")
      },
      {
        title: "Status",
        name: "status",
        getCellValue: row => <FormatPrivateRadioStatus isConnected={get(row, "connected")} isEnabled={get(row, "status") === "Enabled"}/>
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "primaryColumns": ["enodebId"]
  },
  device: {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      }
    ]
  },
  sites: {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "getSelfLink": (row) => {
      return `/v1/sites/search/findByImsiWithSessionReport?searchString=${row.id}`
    },
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      },
      {
        title: "Status",
        name: "status",
        path: "lastSeen",
        getCellValue: row => <FormatSiteStatus lastSeen={get(row, "lastSeen")} ipAddress={get(row, "ipAddress")}/>
      },
      {
        title: "Type",
        name: "type",
        path: "type",
        getCellValue: row => get(row, "type"),
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "primaryColumns": ["name"],
    "visualizations": [
      data => <UsageBarChart
        key={`usage-${getReference(data)}`}
        id={`usage-${getReference(data)}`}
        endpoint={`/v1/subs/command/getUsageReportBySite?reference=${getSelfLink(data)}&`}
        groupByOptions={[
          {"value": "system", "label": "System"},
          {"value": "subscriber", "label": "Subscriber"},
          {"value": "direction", "label": "Direction"}
        ]}/>
    ],
    "audit": {
      "endpoint": (searchString, changedFields, username) =>
        `${BASE_URL}/v1/sites/command/getWithRevisions?entity=${searchString}&changedFields=${changedFields}&username=${username}&projection=brief&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Status",
          name: "status",
          getCellValue: (row) => get(row, "entity.status")
        },
      ]
    }
  },
  "gateway-hosts": {
    "list": [
      {
        id: "name",
        title: "Name",
        name: "name",
        getCellValue: row => get(row, "name")
      },
      {
        id: "url",
        title: "URL",
        name: "url",
        getCellValue: row => get(row, "url")
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
  },
  keysets: {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        id: "customer",
        title: "Customer",
        name: "customer",
        getCellValue: row => get(row, "customer.name")
      },
    ],
    "primaryColumns": ["name"]
  },
  accounts: { // TODO ask Hayden about this
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Email",
        id: "Id",
        name: "id",
        getCellValue: row => {
          return {
            "value": get(row, "emailAddress"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "detail": null,
    "primaryColumns": ["id"],
    "audit": {
      "endpoint": (searchString, changedFields, username) =>
        `${BASE_URL}/v1/accounts/command/getWithRevisions?entity=${searchString}&changedFields=${changedFields}&username=${username}&projection=brief&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Email Address",
          name: "emailAddress",
          getCellValue: (row) => get(row, "entity.emailAddress")
        },
      ]
    }
  },
  sys: {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      },
      {
        title: "Networks",
        name: "networks",
        path: "gatewayFeature",
        getCellValue: row => map(get(row, "gatewayFeature.gateways", []), gateway => gateway.network).join(", ")
      },
      {
        title: "Status",
        name: "status",
        path: "status",
        getCellValue: row => get(row, "status"),
      },
    ],
    "detail": null,
    "primaryColumns": ["name"],
    "visualizations": [
      data => <UsageBarChart
        key={`usage-${getReference(data)}`}
        id={`usage-${getReference(data)}`}
        endpoint={`/v1/subs/command/getUsageReportBySystem?reference=${getSelfLink(data)}&`}
        groupByOptions={[
          {"value": "site", "label": "Site"},
          {"value": "subscriber", "label": "Subscriber"},
          {"value": "direction", "label": "Direction"}
        ]}
        groupBy="subscriber"
      />,
      data => <DevicePieChart key={data.name} id="subscriberPie" groupByOptions={[
        {"value": "status", "label": "Status"}
      ]} filterKey="system" filterValue={getReference(data)} defaultGroupBy="status"/>
    ],
    "audit": {
      "endpoint": (searchString, changedFields, username) =>
        `${BASE_URL}/v1/sys/command/getWithRevisions?entity=${searchString}&changedFields=${changedFields}&username=${username}&projection=brief&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Status",
          name: "status",
          getCellValue: (row) => get(row, "entity.status")
        },
      ]
    }
  },
  "usageCdrs": {
    "list": [
      {
        title: "IMEI",
        name: "imei",
        getCellValue: row => formatIMEI(get(row, "imei"))
      },
      {
        title: "Site",
        name: "site",
        getCellValue: row => get(row, "site.name"),
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "system.name"),
      },
      {
        title: "IP Address",
        name: "ip4Address",
        getCellValue: row => get(row, "ip4Address")
      },
      {
        title: "Usage",
        name: "usage",
        getCellValue: row => formatBytes(get(row, "upload", 0) + get(row, "download", 0), 3),
      },
      {
        title: "State",
        name: "state",
        getCellValue: row => get(row, "state")
      },
      {
        title: "Start Time",
        name: "startTime",
        getCellValue: row => formatDate(get(row, "startTime"))
      },
      {
        title: "Duration",
        name: "duration",
        getCellValue: row => {
          const lastSeen = moment(get(row, "stopTime"));
          const startTime = moment(get(row, "startTime"));
          const diff = moment.duration(startTime.diff(lastSeen));
          if (diff.isValid()) {
            return diff.humanize()
          }
        }
      },
    ]
  },
  "allSessions": {
    "list": [
      {
        title: "IMEI",
        name: "imei",
        getCellValue: row => formatIMEI(get(row, "imei"))
      },
      {
        title: "Site",
        name: "site",
        getCellValue: row => get(row, "siteName"),
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "systemName"),
      },
      {
        title: "Subscriber",
        name: "subscriber",
        getCellValue: row => get(row, "subscriberId")
      },
      {
        title: "Record Timestamp",
        name: "recordTimestamp",
        getCellValue: row => formatDate(get(row, "recordTimestamp"))
      },
      {
        title: "Start Time",
        name: "startTime",
        getCellValue: row => formatDate(get(row, "startTime"))
      },
      {
        title: "End Time",
        name: "endTime",
        getCellValue: row => formatDate(get(row, "stopTime"))
      },
      {
        title: "Country",
        name: "country",
        getCellValue: row => get(row, "country")
      },
      {
        title: "Operator",
        name: "operator",
        getCellValue: row => get(row, "operator")
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      }
    ]
  },
  "gateways": {
    "list": [
      {
        title: "Network",
        name: "network",
        getCellValue: row => get(row, "network"),
      },
      {
        title: "APN",
        name: "apn",
        getCellValue: row => get(row, "apn"),
      },
      {
        title: "Site",
        name: "site",
        getCellValue: row => get(row, "site"),
      },
      {
        title: "Host",
        name: "host",
        getCellValue: row => get(row, "host"),
      },
      {
        title: "Ip Address",
        name: "ipAddress",
        getCellValue: row => <FormatGatewayStatus isConnected={get(row, "connected")} ipAddress={get(row, "ipAddress")}/>
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => getSelfLink(row)
      }
    ]
  },
  "sessions": {
    "list": [
      {
        title: "Site",
        name: "site",
        getCellValue: row => get(row, "site"),
      },
      {
        title: "State",
        name: "state",
        getCellValue: row => get(row, "state"),
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "system"),
      },
      {
        title: "IP Address",
        name: "ip4Address",
        getCellValue: row => get(row, "ip4Address"),
      },
      {
        title: "Last Seen",
        name: "lastSeen",
        getCellValue: row => formatDate(get(row, "lastSeen"))
      },
    ]
  },
  "sub-groups": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "system.name"),
      },
      {
        title: "Customer",
        name: "customer",
        getCellValue: row => get(row, "customer.name"),
      },
    ],
    "primaryColumns": ["name"],
  },
  "interceptor-scripts": {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      },
      {
        title: "Customer",
        name: "customer",
        getCellValue: row => get(row, "customer.name"),
      },
      {
        title: "Validate",
        name: "validate",
        path: "validate",
        getCellValue: row => get(row, "validate").toString() && <FormatBoolean value={get(row, "validate").toString()}/>
      },
      {
        title: "Active",
        name: "active",
        path: "active",
        getCellValue: row => get(row, "name") && <ScriptActive scriptName={get(row, "name")} scriptType={get(row, "type")}/>
      },
      {
        title: "NF Vendor",
        name: "vendor",
        getCellValue: row => get(row, "vendor")
      },
    ],
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "detail": null,
    "primaryColumns": ["name"],
    "sort": "name",
    "pageSize": 100
  },
  "sub-profiles": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "system.name"),
      },
      {
        title: "Customer",
        name: "customer",
        getCellValue: row => get(row, "customer.name"),
      },
    ],
    "primaryColumns": ["name"],
    "audit": {
      "endpoint": (searchString, changedFields, username) =>
        `${BASE_URL}/v1/sub-profiles/command/getWithRevisions?entity=${searchString}&changedFields=${changedFields}&username=${username}&projection=brief&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Name",
          name: "name",
          getCellValue: (row) => get(row, "entity.name")
        },
        {
          title: "Default APN",
          name: "defaultApn",
          getCellValue: (row) => get(row, "entity.defaultApn")
        },
        {
          title: "Upload Bit Rate",
          name: "uploadBitRate",
          getCellValue: (row) => get(row, "entity.uploadBitRate")
        },
        {
          title: "Download Bit Rate",
          name: "downloadBitRate",
          getCellValue: (row) => get(row, "entity.downloadBitRate")
        },
      ]
    }
  },
  "server-configs": {
    "list": [
      {
        title: "Key",
        name: "key",
        getCellValue: row => {
          return {
            "value": get(row, "key"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Last updated",
        name: "asOf",
        getCellValue: row => formatDate(get(row, "asOf"))
      }
    ],
    "detail": [],
    "primaryColumns": ["key"]
  },
  "customers": {
    "search": false,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": getSelfLink(row)
          }
        }
      },
      {
        title: "Sites",
        name: "sites",
        getCellValue: row => row.siteCount
      },
      {
        title: "Systems",
        name: "systems",
        getCellValue: row => row.systemCount
      },
      {
        title: "Subscribers",
        name: "subscribers",
        getCellValue: row => row.subscriberCount
      },
    ],
    "detail": [
      {"name": "Customer", "view": "customer"}
    ],
    "visualizations": [
      data => <UsageBarChart
        key={`usage-${getReference(data)}`}
        id={`usage-${getReference(data)}`}
        endpoint={`/v1/subs/command/getUsageReportByCustomer?reference=${getSelfLink(data)}&`}
        groupByOptions={[
          {"value": "system", "label": "System"},
          {"value": "site", "label": "Site"},
          {"value": "subscriber", "label": "Subscriber"},
          {"value": "customer", "label": "Customer"},
          {"value": "direction", "label": "Direction"}
        ]}
        groupBy="system"
      />,
      data => <DevicePieChart key={data.name} id="subscriberPie" groupByOptions={[
        {"value": "system", "label": "System"},
        {"value": "status", "label": "Status"},
        {"value": "site", "label": "Site"}
      ]} filterKey="customer" filterValue={getReference(data)}/>
    ],
    "primaryColumns": ["name"],
    "listComponent": CustomerTable,
    "audit": {
      "endpoint": (searchString, changedFields, username) =>
        `${BASE_URL}/v1/customers/command/getWithRevisions?entity=${searchString}&changedFields=${changedFields}&username=${username}&projection=brief&size=50`,
      "columns": [
        {
          title: "Revision",
          name: "revision",
          getCellValue: (row) => moment(get(row, "revision.timestamp")).format(),
        },
        {
          title: "Revision Source",
          name: "ipAddress",
          getCellValue: (row) => get(row, "revision.ip")
        },
        {
          title: "User",
          name: "username",
          getCellValue: (row) => get(row, "revision.username")
        },
        {
          title: "Name",
          name: "name",
          getCellValue: (row) => get(row, "entity.name")
        },
      ]
    }
  },
  "sftp-accesses": {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Host",
        name: "hostname",
        getCellValue: row => get(row, "hostname")
      },
      {
        title: "Port",
        name: "port",
        getCellValue: row => get(row, "port")
      },
      {
        title: "Username",
        name: "username",
        getCellValue: row => get(row, "username")
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "primaryColumns": ["name"]
  },
  "notifications": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Hash",
        name: "hash",
        getCellValue: row => {
          return {
            "value": get(row, "hash"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Consumed",
        name: "consumed",
        path: "consumed",
        getCellValue: row => get(row, "consumed").toString() && <FormatBoolean value={get(row, "consumed").toString()}/>
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      },
      {
        title: "Issued at",
        name: "issued",
        getCellValue: row => formatDate(get(row, "issued"))
      },
      {
        title: "Subscriber",
        name: "subs",
        getCellValue: row => get(row, "subscriber.imsi")
      },
      {
        title: "System",
        name: "system",
        getCellValue: row => get(row, "system.name"),
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      }
    ],
    "detail": [],
    "primaryColumns": ["hash"]
  },
  "devices": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "TAC",
        name: "tac",
        getCellValue: row => {
          return {
            "value": get(row, "id"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Brand",
        name: "brand_name",
        getCellValue: row => get(row, "brandName")
      },
      {
        title: "Model",
        name: "model_name",
        getCellValue: row => get(row, "modelName")
      },
      {
        title: "Type",
        name: "device_type",
        getCellValue: row => get(row, "deviceType")
      },
      {
        title: "OS",
        name: "os",
        getCellValue: row => get(row, "os")
      }
    ],
    "detail": null,
    "primaryColumns": ["tac"]
  },
  "carriers": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "PLMN (MCC/MNC)",
        name: "plmn",
        getCellValue: row => {
          return {
            "value": get(row, "mcc") + "/" + get(row, "mnc"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Operator",
        name: "operator",
        getCellValue: row => get(row, "operator")
      },
      {
        title: "Mode",
        name: "mode",
        getCellValue: row => get(row, "mode")
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      },
      {
        title: "Country code",
        name: "country_code",
        getCellValue: row => get(row, "countryCode")
      },
      {
        title: "Country",
        name: "country",
        getCellValue: row => get(row, "countryName")
      }
    ],
    "detail": null,
    "primaryColumns": ["plmn"]
  },
  "files": {
    "endpoint": "/search/findByDeepSearch",
    "search": true,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Source",
        name: "source",
        getCellValue: row => get(row, "source")
      },
      {
        title: "Description",
        name: "description",
        getCellValue: row => get(row, "description")
      },
      {
        title: "Last update",
        name: "lastUpdate",
        getCellValue: row => formatDate(get(row, "lastUpdate"))
      },
      {
        title: "Customer",
        name: "customer",
        path: "customer.name",
        getCellValue: row => get(row, "customer.name"),
      },
      {
        title: "Download",
        name: "download",
        getCellValue: row => <FormatFile selfUrl={formatTemplatedLink(get(row, "_links"))}/>
      }
    ],
    "detail": [],
    "primaryColumns": ["name"]
  },
  "allBatches": {
    "list": [
      {
        title: "#",
        name: "instanceId",
        getCellValue: row => get(row, "instanceId")
      },
      {
        title: "Creation Time",
        name: "createTime",
        getCellValue: row => formatDate(get(row, "createTime"))
      },
      {
        title: "Start Time",
        name: "startTime",
        getCellValue: row => formatDate(get(row, "startTime"))
      },
      {
        title: "End Time",
        name: "endTime",
        getCellValue: row => formatDate(get(row, "endTime"))
      },
      {
        title: "Status",
        name: "status",
        getCellValue: row => get(row, "status")
      }
    ]
  },
  "authentication-events": {
    "list": [
      {
        title: "Email Address",
        name: "emailAddress",
        getCellValue: row => get(row, "emailAddress")
      },
      {
        title: "IP Address",
        name: "ipAddress",
        getCellValue: row => get(row, "ip")
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      },
      {
        title: "First Attempt",
        name: "firstAttempt",
        getCellValue: row => get(row, "firstAttempt")
      },
      {
        title: "Last Attempt",
        name: "lastAttempt",
        getCellValue: row => get(row, "lastAttempt")
      },
      {
        title: "Number of Attempts",
        name: "numAttempts",
        getCellValue: row => get(row, "numAttempts")
      },
    ],
  },
  "tokens": {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => get(row, "name")
      },
      {
        title: "Token",
        name: "value",
        getCellValue: row => <FormatToken value={ get(row, "lastFour") } />
      },
      {
        title: "Expiry",
        name: "expiryDate",
        getCellValue: row => formatDate(get(row, "expiryDate"))
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => get(row, "name")
      }
    ],
  },
  "monitoring-endpoints": {
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => get(row, "name")
      },
      {
        title: "Job Name",
        name: "jobName",
        getCellValue: row => get(row, "jobName")
      },
      {
        title: "Metrics Url",
        name: "metricsUrl",
        getCellValue: row => get(row, "metricsUrl")
      },
      {
        title: "Scraped by",
        name: "scrapedBy",
        getCellValue: row => get(row, "scrapedBy")
      },
      {
        title: "Actions",
        name: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      },
    ],
  },
  "keyset-mappings": {
    "list": [
      {
        id: "hssKeysetIdentifier",
        title: "HSS Identifier",
        name: "hssKeysetIdentifier",
        getCellValue: row => {
          return {
            "value": get(row, "hssKeysetIdentifier"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        id: "hssKeysetDescription",
        title: "HSS Description",
        name: "hssKeysetDescription",
        getCellValue: row => get(row, "hssKeysetDescription")
      },
      {
        id: "keysetName",
        title: "Name",
        name: "keysetName",
        getCellValue: row => get(row, "keyset.name")
      },
      {
        title: "Actions",
        name: "actions",
        id: "actions",
        getCellValue: row => formatTemplatedLink(get(row, "_links"))
      }
    ],
    "primaryColumns": ["hssKeysetIdentifier"]
  },
  "sms": {
    "list": [
      {
        title: "Direction",
        name: "direction",
        getCellValue: row => {
          const direction = get(row, "direction")
          switch (direction) {
            case 'incoming': return 'Incoming'
            case 'outgoing': return 'Outgoing'
            default: return 'Unknown'
          }
        }
      },
      {
        title: "Other Participant",
        name: "otherMsisdn",
        getCellValue: row => {
          const direction = get(row, "direction")
          switch (direction) {
            case 'in': return get(row, "callingNumber")
            default: return get(row, "calledNumber")
          }
        }
      },
      {
        title: "Status",
        name: "status",
        getCellValue: row => {
          if (row) {
            if (row.deliverySuccessTime) return "Delivered"
            if (row.deliveryFailedTime) {
              const validUntilDate = moment(row.validUntil)
              const now = moment()
              if (validUntilDate.isAfter(now)) return "Awaiting retry"
              else return "Failed"
            }
            if (row.deliveryAttemptTime && row.callingNumber) return "Delivered"
            else return "Received"
          }
          return 'Unknown'
        }
      },
      {
        title: "Last Event Timestamp",
        name: "recordTimestamp",
        getCellValue: row => {
          let timestamp
          if (row) {
            if (row.deliverySuccessTime) timestamp = row.deliverySuccessTime
            else if (row.deliveryFailedTime) timestamp = row.deliveryFailedTime
            else if (row.deliveryAttemptTime) timestamp = row.deliveryAttemptTime
            else if (row.originationTime) timestamp = row.originationTime
          }
          return timestamp ? formatDate(timestamp) : ''
        }
      }
    ]
  },
  "reports": {
    "endpoint": "/search/findByDeepSearch",
    "search": false,
    "list": [
      {
        title: "Name",
        name: "name",
        getCellValue: row => {
          return {
            "value": get(row, "name"),
            "link": formatTemplatedLink(get(row, "_links"))
          }
        }
      },
      {
        title: "Description",
        name: "description",
        getCellValue: row => get(row, "description")
      },
      {
        title: "Type",
        name: "type",
        getCellValue: row => get(row, "type")
      }
    ],
    "actions": [
      data => {
        return getReportCard(data);
      }
    ],
    "detail": null,
    "primaryColumns": ["name"]
  }
}
export const details = {
  monitoringSiteFeature: {
    extensions: {
      label: "Extensions",
      order: 0
    },
  },
  "sub-imsis": {
    imsi: {
      label: "IMSI",
      order: 0
    },
  },
  subs: {
    imsi: {
      label: "IMSI",
      order: 0
    },
    msisdn: {
      label: "MSISDN",
      order: 1
    },
    status: {
      label: "Status",
      order: 2
    },
    type: {
      roles: ["SUPERUSER"],
      label: "Type",
      order: 3
    },
    iccid: {
      label: "ICCID",
      order: 4
    },
    extensions: {
      label: "Extensions",
      order: 5
    }
  },
  sites: {
    name: {
      label: "Name",
      order: 0,
    },
    location: {
      label: "Location",
      order: 1,
    },
    type: {
      label: "Type",
      order: 2,
    },
    uuid: {
      label: "UUID",
      order: 3
    },
    agentVersion: {
      label: "Agent Version",
      order: 4,
    },
    ipAddress: {
      label: "IP Address",
      order: 5,
    },
    lastSeen: {
      label: "Last Seen",
      order: 6,
    },
    extensions: {
      label: "Extensions",
      order: 7,
    },
  },
  "sub-groups": {
    name: {
      label: "Name",
      order: 0,
    },
    extensions: {
      label: "Extensions",
      order: 1,
    },
  },
  "sub-sessions": {
    imei: {
      label: "IMEI",
      order: 0
    },
    state: {
      label: "State",
      order: 1,
    },
    lastSeenTimestamp: {
      label: "Last Seen",
      order: 2,
      Component: (props) => (
        <div className="form-group p-3">
          <label>{props.label}:</label>
          <p className="form-control-plaintext pl-1">{formatDate(props.data)}</p>
        </div>
      )
    }
  },
  "real-sub-sessions": {
    imei: {
      label: "IMEI",
      order: 0
    },
    state: {
      label: "State",
      order: 1,
    },
    startTime: {
      label: "Start Time",
      order: 2,
      Component: (props) => (
        <div className="form-group p-3">
          <label>{props.label}:</label>
          <p className="form-control-plaintext pl-1">{formatDate(props.data)}</p>
        </div>
      )
    },
    stopTime: {
      label: "End Time",
      order: 3,
      Component: (props) => (
        <div className="form-group p-3">
          <label>{props.label}:</label>
          <p className="form-control-plaintext pl-1">{formatDate(props.data)}</p>
        </div>
      )
    },
    mcc: {
      label: "MCC",
      order: 4,
    },
    mnc: {
      label: "MNC",
      order: 5,
    },
    tac: {
      label: "TAC",
      order: 6,
    },
    destGt: {
      label: "Dest GT",
      order: 7,
    },
    lac: {
      label: "LAC",
      order: 8,
    },
    lastSeenTimestamp: {
      label: "Last Seen",
      order: 9,
      Component: (props) => (
        <div className="form-group p-3">
          <label>{props.label}:</label>
          <p className="form-control-plaintext pl-1">{formatDate(props.data)}</p>
        </div>
      )
    }
  },
  feature: {
    amf: {
      label: "AMF",
      order: 0,
    },
    op: {
      label: "OP",
      order: 1,
    },
    extensions: {
      label: "Extensions",
      order: 2,
    },
  },
  sys: {
    name: {
      label: "Name",
      order: 0,
    },
    status: {
      label: "Status",
      order: 1
    },
    extensions: {
      label: "Extensions",
      order: 2,
    },
  },
  system: {
    name: {
      label: "Name",
      order: 0,
    },
    status: {
      label: "Status",
      order: 1
    },
    extensions: {
      label: "Extensions",
      order: 2,
    },
  },
  "sub-profiles": {
    name: {
      label: "Name",
      order: 0,
    },
    defaultApn: {
      label: "Default APN",
      order: 1,
    },
    downloadBitRate: {
      label: "Download Bit Rate",
      order: 2,
    },
    uploadBitRate: {
      label: "Upload Bit Rate",
      order: 3,
    },
    extensions: {
      label: "Extensions",
      order: 4,
    },
  },
  profile: {
    name: {
      label: "Name",
      order: 0,
    },
    defaultApn: {
      label: "Default APN",
      order: 1,
    },
    downloadBitRate: {
      label: "Download Bit Rate",
      order: 2,
    },
    uploadBitRate: {
      label: "Upload Bit Rate",
      order: 3,
    },
    extensions: {
      label: "Extensions",
      order: 4,
    },
  },
  accounts: {
    emailAddress: {
      label: "Email Address",
    },
  },
  "interceptor-scripts": {
    name: {
      label: "Name",
      order: 0
    },
    type: {
      label: "Type",
      order: 1
    },
    validate: {
      label: "Validate",
      order: 2,
      Component: ({value}) => (
        <Checkbox value={value} disabled={true} onChange={() => {
        }}></Checkbox>
      )
    },
    vendor: {
      label: "Network Function Vendor",
      order: 3
    },
    extensions: {
      label: "Extensions",
      order: 4,
    },
    body: {
      label: "Body",
      order: 5,
      Component: ({value}) => (
        <div className="code-block">
          <pre><code>{value}</code></pre>
        </div>
      )
    },
  },
  "customers": {
    name: {
      label: "Name",
      order: 0,
    },
    siteCount: {
      label: "Total Sites",
      order: 1
    },
    systemCount: {
      label: "Total Systems",
      order: 2
    },
    subscriberCount: {
      label: "Total Subscribers",
      order: 3
    },
    extensions: {
      label: "Extensions",
      order: 4
    }
  },
  "parent": {
    name: {
      label: "Name"
    },
  },
  "server-configs": {
    key: {
      label: "Key"
    },
    "value": {
      label: "Value",
      Component: (props) => (
        <div className="code-block">
          <pre className={props.className}><code>{props.value}</code></pre>
        </div>
      )
    },
    "asOf": {
      label: "Last Updated",
      Component: (props) => (
        <div>{formatDate(props.value)}</div>
      )
    }
  },
  "device": {
    imei: {
      label: "IMEI",
      order: 0
    },
    modelName: {
      label: "Model Name",
      order: 1
    },
    brandName: {
      label: "Brand Name",
      order: 2
    },
    deviceType: {
      label: "Device Type",
      order: 3
    },
    os: {
      label: "OS",
      order: 4
    }
  },
  "private-radios": {
    enodebId: {
      label: "Radio ID",
      order: 0
    },
    status: {
      label: "Status",
      order: 1
    },
  },
  "notifications": {
    hash: {
      label: "Hash",
      order: 0
    },
    "issued": {
      label: "Issued at",
      Component: (props) => (
        <div>{formatDate(props.value)}</div>
      ),
      order: 1
    },
    "consumed": {
      label: "Consumed",
      order: 2,
      Component: ({value}) => (
        <Checkbox value={value} disabled={true} onChange={() => {
        }}></Checkbox>
      )
    },
    "payload": {
      label: "Payload",
      Component: ({value}) => (
        <div className="code-block">
          <pre><code>{value}</code></pre>
        </div>
      ),
      order: 3
    }
  },
  "devices": {
    id: {
      label: "TAC",
      order: 0
    },
    brandName: {
      label: "Brand Name",
      order: 1
    },
    modelName: {
      label: "Model Name",
      order: 2
    },
    deviceType: {
      label: "Device Type",
      order: 3
    },
    os: {
      label: "OS",
      order: 4
    },
    source: {
      label: "Source",
      order: 5
    }
  },
  "carriers": {
    mcc: {
      label: "MCC",
      order: 0
    },
    mnc: {
      label: "MNC",
      order: 1
    },
    brand: {
      label: "Brand Name",
      order: 2
    },
    operator: {
      label: "Operator",
      order: 3
    },
    mode: {
      label: "Mode",
      order: 4
    },
    type: {
      label: "Carrier Type",
      order: 5
    },
    status: {
      label: "Status",
      order: 6
    },
    countryCode: {
      label: "Country Code",
      order: 7
    },
    countryName: {
      label: "Country Name",
      order: 8
    },
    bands: {
      label: "Bands",
      order: 9
    },
    notes: {
      label: "Notes",
      order: 10
    }
  },
  "files": {
    name: {
      label: "Name",
      order: 0
    },
    source: {
      label: "Source",
      order: 1
    },
    mediaType: {
      label: "Media type",
      order: 2
    },
    size: {
      label: "Size (bytes)",
      order: 3
    },
    lastUpdate: {
      label: "Last update",
      Component: (props) => (
        <div>{formatDate(props.value)}</div>
      ),
      order: 4
    },
    description: {
      label: "Description",
      order: 5
    },
    selfUrl: {
      label: "Download",
      Component: ({value}) => (
        <FormatFile selfUrl={value}/>
      ),
      order: 6
    }
  },
  "keyset": {
    name: {
      label: "Name",
      order: 0
    },
  },
  "keysets": {
    name: {
      label: "Name",
      order: 0
    },
  },
  "keyset-mappings": {
    hssKeysetIdentifier: {
      label: "HSS Identifier",
      order: 0
    },
    hssKeysetDescription: {
      label: "HSS Description",
      order: 1
    },
  },
  "sftp-accesses": {
    name: {
      label: "Name",
      order: 0
    },
    hostname: {
      label: "Hostname",
      order: 1
    },
    port: {
      label: "Port",
      order: 2
    },
    username: {
      label: "Username",
      order: 3
    },
    remoteDirectory: {
      label: "Remote directory",
      order: 4
    }
  },
  "role": {
    name: {
      label: "Name"
    },
  },
  "reports": {
    name: {
      label: "Name",
      order: 0
    },
    description: {
      label: "Description",
      order: 1
    },
    type: {
      label: "Type",
      order: 2
    },
    jobName: {
      roles: ["SUPERUSER"],
      label: "Job Name",
      order: 3
    },
    uri: {
      roles: ["SUPERUSER"],
      label: "URI",
      order: 4
    },
    extensions: {
      label: "Extensions",
      order: 5
    }
  }
};

export const moduleButtons = {
  "gatewayHostFeature" : {
    resourceName: "gateway-hosts",
    successMessage: "createGatewayHostSuccess",
    isSuperUser: true,
  },
  "monitoringSiteFeature" : {
    resourceName: "monitoring-endpoints",
    successMessage: "createMonitoringEndpointSuccess",
    isSuperUser: true,
  },
  "privateRadioSiteFeature" : {
    resourceName: "private-radios",
    successMessage: "createEnodebSuccess",
  },
  "sftpAccessCustomerFeature" : {
    resourceName: "sftp-accesses",
    successMessage: "createSftpAccessSuccess",
  },
  "apiTokenSiteFeature": {
    resourceName: "site-feature-api-tokens",
    successMessage: "createApiTokenSuccess",
    isSuperUser: true,
    endpoint: `${BASE_URL}/v1/sites/command/createToken`,
  },
  "apiTokenAccountFeature": {
    resourceName: "account-feature-api-tokens",
    successMessage: "createApiTokenSuccess",
    endpoint: `${BASE_URL}/v1/accounts/command/createToken`,
  },
  "sessions": {
    columns: headings["allSessions"].list
  },
  "imsis": {
    resourceName: "sub-imsis",
    columns: headings["sub-imsis"].list
  },
  "sub-imsis": {
    resourceName: "sub-imsis",
    columns: headings["sub-imsis"].list
  },
  "keysetMappingSiteFeature": {
    resourceName: "keyset-mappings",
    successMessage: "createKeysetMappingSuccess",
  },
  "sms": {
    columns: headings["sms"].list
  }
}

const keysetMappingsBrief = filter(headings["keyset-mappings"].list, it => !["keysetName", "actions"].includes(it.id));

export const detailModules = {
  "sessions": {
    getComponent: (data) => <ModuleTable resourceName="sessions" rows={data} details={false}/>,
    roles: ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY"],
  },
  "keysetMappings": {
    getComponent: (data) => <ModuleTable resourceName="keyset-mappings" rows={data} columns={keysetMappingsBrief} details={false}/>,
    roles: ["SUPERUSER"]
  },

  "gatewayFeature": {
    getComponent: (data) => <ModuleTable resourceName="gateways" rows={data.gateways} details={true}/>,
    roles: ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY"]
  },
  "privateRadioSiteFeature": {
    getComponent: (data) => <ModuleTable resourceName="private-radios" rows={data.privateRadios} details={false}/>,
    roles: ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY"]
  },
  "sftpAccessCustomerFeature": {
    getComponent: (data) => <ModuleTable resourceName="sftp-accesses" rows={data.sftpAccesses} details={false}/>,
    roles: ["SUPERUSER", "ADMIN"]
  },
  "apiTokenSiteFeature": {
    getComponent: (data) => <ModuleTable resourceName="tokens" rows={data.apiTokens} details={false}/>,
    roles: ["SUPERUSER", "ADMIN"]
  },
  "apiTokenAccountFeature": {
    getComponent: (data) => <ModuleTable resourceName="tokens" rows={data.apiTokens} details={false}/>,
    roles: ["SUPERUSER", "ADMIN"]
  },
  "gatewayHostFeature": {
    getComponent: (data) => <ModuleTable resourceName="gateway-hosts" rows={data.hosts} columns={headings["gateway-hosts"].list} details={true} />,
    roles: ["SUPERUSER"]
  },
  "keysetMappingSiteFeature": {
    getComponent: (data) => <ModuleTable resourceName="keyset-mappings" rows={data.keysetMappings} details={false} />,
    roles: ["SUPERUSER"]
  },
  "monitoringSiteFeature": {
    getComponent: (data) => <ModuleTable resourceName="monitoring-endpoints" rows={data.monitoringEndpoints} details={false}/>,
    roles: ["SUPERUSER"]
  },
  "imsis": {
    getComponent: (data) => <ModuleTable resourceName="sub-imsis" rows={data} columns={headings["sub-imsis"].list} details={false}/>,
    roles: ["SUPERUSER", "ADMIN"]
  },
  "sms": {
    getComponent: (data) => <ModuleTable resourceName="sms" rows={data} details={false}/>,
    roles: ["SUPERUSER", "ADMIN", "CUSTOMER_ADMIN", "READ_ONLY"],
  }
};