import {map} from "lodash"
import {object} from "prop-types";

export function createQueryStringFromMapping(mapping: object) {
  return map(mapping, (v,k) => `${k}=${v}`).join("&")
}
export function getQueryParams(queryString) {
  const queryParams = {};
  map(queryString.slice(1, queryString.length).split("&"), pair => {
    queryParams[pair.split("=")[0]] = pair.split("=")[1]
  });
  return queryParams
}

export function getQueryParam(paramName, queryString) {
  const queryParams = getQueryParams(queryString || window.location.search);
  return queryParams[paramName]
}
