// @flow
import {withStyles} from "@material-ui/core";
import StyledCard from "./StyledCard";

const styles = theme => ({
  "className": {
    "textAlign": "center",
    "padding": theme.spacing.unit*2,
    "display": "flex",
    "height": "100%",
    "paddingTop": theme.spacing.unit*3,
    "flexDirection": "column"
  }
});

const MetricTile = withStyles(styles)(StyledCard);
export default MetricTile