
export const CUSTOMER_USAGE_REPORT = {
    "value": "CUSTOMER_USAGE_REPORT",
    "label": "Customer Usage report"
};
export const CUSTOMER_DETAILED_USAGE_REPORT = {
    "value": "CUSTOMER_DETAILED_USAGE_REPORT",
    "label": "Customer Detailed Usage report"
};
export const CUSTOMER_USAGE_BY_SITE_IMSI_NETWORK_REPORT = {
    "value": "CUSTOMER_USAGE_BY_SITE_IMSI_NETWORK_REPORT",
    "label": "Customer Usage by site, IMSI and network report"
};
export const CARRIER_USAGE_REPORT = {
    "value": "CARRIER_USAGE_REPORT",
    "label": "Carrier Usage Report"
};
export const INVOICE_V1 = {
    "value": "INVOICE_V1",
    "label": "Invoice"
};
export const SITE_BACKUP = {
    "value": "SITE_BACKUP",
    "label": "Site backup"
};
export const TMO_BILLING_INPUT = {
    "value": "TMO_BILLING_INPUT",
    "label": "T-Mobile billing input"
};
export const TMO_BILLING_OUTPUT = {
    "value": "TMO_BILLING_OUTPUT",
    "label": "T-Mobile billing output"
};
export const CDR_PROVIDER_1_REPORT = {
    "value": "CDR_PROVIDER_1_REPORT",
    "label": "CDRS Provider 1 report"
};
export const CDR_PROVIDER_1_DAILY_CDRS = {
    "value": "CDR_PROVIDER_1_DAILY_CDRS",
    "label": "CDRS Provider 1 daily cdrs"
};
export const CDR_PROVIDER_1_MONTHLY_CDRS = {
    "value": "CDR_PROVIDER_1_MONTHLY_CDRS",
    "label": "CDRS Provider 1 monthly cdrs"
};

export const FILES_SOURCES = [
    CUSTOMER_USAGE_REPORT,
    CARRIER_USAGE_REPORT,
    INVOICE_V1,
    SITE_BACKUP,
    TMO_BILLING_INPUT,
    TMO_BILLING_OUTPUT,
    CDR_PROVIDER_1_REPORT,
    CDR_PROVIDER_1_DAILY_CDRS,
    CDR_PROVIDER_1_MONTHLY_CDRS
];