// @flow
import React from 'react'

import MenuItem from '@material-ui/core/MenuItem';
import SelectBase from '@material-ui/core/Select'
import {map, pull} from "lodash";
import {array, func, object} from "prop-types";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ValidationError from "components/ValidationError";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: "flex",
  },
  label: {
    whiteSpace: "nowrap"
  }
});

type P = {
  name: string,
  options: array,
  selectDefault: string,
  onChange: func,
  multiple: boolean,
  optionFormatter: func,
  label: string,
  value :string,
  error: string,
  validationError: string,
  help: string,
  className: string,
  readOnly: boolean,
  displayEmpty: boolean,
  classes: object,
  margin: string
}

class Select extends React.PureComponent<P> {
  static defaultProps = {
    margin: "normal"
  };
  componentWillReceiveProps(nextProps, nextContext) {
    const {name, options, selectDefault, onChange} = nextProps;
    if (options !== this.props.options) {
      if (options && options.length === 1 && selectDefault) {
        onChange({ [name]: this.formatValue(options[0].value)})
      }
    }
  }
  formatValue = (value) => {
    return this.props.multiple && !this.isActuallyMultiple()
      ? pull([value], null) : value
  };
  isActuallyMultiple = () => {
    const {options, multiple} = this.props;
    return options && options.length > 1 && multiple
  };
  onChange = (e) => {
    const {name, onChange} = this.props;

    onChange({[name]: this.formatValue(e.target.value)})
  };
  formatOption = (option, callback) => {
    const optionFormatter = this.props.optionFormatter;
    return callback(optionFormatter
      ? optionFormatter(option)
      :
      option)
  };

  render() {
    const {label, name, options, value, error, validationError, help, className, readOnly, displayEmpty, classes, margin, t} = this.props;
    const defaultValue = this.isActuallyMultiple() ? [] : "";
    return (
      <FormControl margin={margin} className={`${classes.formControl}  ${className}`} error={error}>
        <InputLabel className={classes.label}>{label || name}</InputLabel>
        <SelectBase
          value={value || defaultValue}
          onChange={this.onChange}
          inputProps={{name}}
          multiple={this.isActuallyMultiple()}
          disabled={readOnly}
        >
          { !this.isActuallyMultiple() && displayEmpty !== false && <MenuItem value={null}><em>{t('none')}</em></MenuItem> }
          { map(options, option => this.formatOption(option, formattedOption =>
            <MenuItem key={formattedOption.value} value={formattedOption.value}>{formattedOption.label}</MenuItem>
          ))}
        </SelectBase>
        <small className="form-text text-muted">{help}</small>
        <ValidationError error={validationError}/>
      </FormControl>
    );
  }
}

Select = withStyles(styles)(Select);
Select = withTranslation()(Select);

export default Select