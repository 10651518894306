// @flow
import React from 'react';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import {withStyles} from '@material-ui/core/styles';
import {array, func, object} from "prop-types";
import {getEmbeddedResource, getSelfLink, NestedSelector, rollUpSystems} from 'utils';
import AutoComplete from "components/fields/AutoComplete";
import AsyncAutoComplete from "components/fields/AutoCompleteAsync";
import {searchCustomerByName} from "../../../../utils/search";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});


type P = {
  values: object,
  errors: object,
  validationErrors: object,
  help: object,
  cachedOptions: object,
  classes: object,
  onChange: func,
}

type S = {
  customers: array,
  groupsByFeature: object,
  profilesByFeature: object,
  featuresByCustomer: object,
  loaded: object,
  showCustomers: boolean
}

class SubscriberStoreSelector extends React.Component<P, S> {
  state = {
    customers: [],
    groupsByFeature: {},
    profilesByFeature: {},
    featuresByCustomer: {},
    loaded: {},
    showCustomers: false
  };

  componentWillMount(): void {
    const {cachedOptions, values} = this.props;
    this.loadOptions(NestedSelector.getPrepopulatedSearchString(cachedOptions.customer, values.customer))
  }

  loadOptions = (searchString, callback) => {
    return searchCustomerByName(searchString).then((response) => {

      const alreadyHasValues = this.props.values.customer;
      const groupsByFeature = {...this.state.groupsByFeature};
      const profilesByFeature = {...this.state.profilesByFeature};
      const featuresByCustomer = {...this.state.featuresByCustomer};

      const customers = [];

      map(getEmbeddedResource(response, "customers"), customer => {
        const customerLink = getSelfLink(customer);
        const customerOption = {"label": customer.name, "value": customerLink};
        customers.push(customerOption);

        map(rollUpSystems(customer), system => {
          if (!system.subFeature) {
            return
          }
          const featureLink = getSelfLink(system.subFeature);

          // Features do not have name so we are using the system name
          const featureOption = {"label": system.name, "value": featureLink};


          if (!this.state.featuresByCustomer[customerLink]) {
            if (!featuresByCustomer[customerLink]) {
              featuresByCustomer[customerLink] = []
            }
            featuresByCustomer[customerLink].push(featureOption);
          }

          if (!this.state.groupsByFeature[featureLink]) {
            groupsByFeature[featureLink] = map(system.subFeature.groups,
              group => ({"label": group.name, "value": getSelfLink(group)}));
          }
          if (!this.state.profilesByFeature[featureLink]) {
            profilesByFeature[featureLink] = map(system.subFeature.profiles,
              profile => ({"label": profile.name, "value": getSelfLink(profile)}))
          }
        });
      });

      const uniqueCustomers = uniqBy(customers, "value");
      const onlyOneCustomer = uniqueCustomers.length === 1;

      const state = {
        customers: uniqueCustomers,
        groupsByFeature,
        profilesByFeature,
        featuresByCustomer,
        loaded: true,
        showCustomers: !onlyOneCustomer
      };

      if (onlyOneCustomer && !alreadyHasValues) {
        this.onChange(state)({"customer": uniqueCustomers[0].value})
      }
      this.setState(state, () => {
        if (callback) {
          callback(state.customers)
        }
      });
    })
  };
  onChange = state => (newData: object) => {
    const {featuresByCustomer, profilesByFeature} = state;
    const {onChange, values} = this.props;

    if (NestedSelector.hasChanged(values, newData, "customer")) {
      newData["feature"] = NestedSelector.getOptionIfOnlyOption(featuresByCustomer, newData["customer"])
    }
    if (NestedSelector.hasChanged(values, newData, "feature")) {
      newData["groups"] = [];
      newData["profile"] = NestedSelector.getOptionIfOnlyOption(profilesByFeature, newData["feature"])
    }
    onChange(newData)
  };

  render() {
    const onChange = this.onChange(this.state);
    const {values, errors, validationErrors, classes, help, cachedOptions, t} = this.props;
    const {customers, featuresByCustomer, groupsByFeature, profilesByFeature} = this.state;
    return (
      <div>
        <AsyncAutoComplete
          value={values.customer}
          error={errors.feature} // this is intentional since the backend will not throw an error for missing customer
          validationError={validationErrors.feature} // ^^
          help={help.customer}
          options={customers}
          name="customer"
          onChange={onChange}
          className={classes.formControl}
          displayEmpty={false}
          label={t('customer')}
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions={customers}
          cachedOptions={cachedOptions.customer}
        />
        <AutoComplete
          value={values.feature}
          error={errors.feature}
          validationError={validationErrors.feature}
          options={featuresByCustomer[values.customer] || []}
          name="feature"
          label={t('system')}
          onChange={onChange}
          className={classes.formControl}
          help={"Only systems with subscriber stores are listed. " + help.system}
          displayEmpty={false}
          cachedOptions={cachedOptions.feature}
          cacheOptions={true}
        />
        <AutoComplete
          value={values.profile}
          error={errors.profile}
          validationError={validationErrors.profile}
          help={help.profile}
          options={profilesByFeature[values.feature] || []}
          name="profile"
          label={t('profile')}
          onChange={onChange}
          className={classes.formControl}
          cachedOptions={cachedOptions.profile}
          displayEmpty={false}
          cacheOptions={true}
        />
        <AutoComplete
          value={values.groups}
          error={errors.groups}
          validationError={validationErrors.groups}
          help={help.groups}
          options={groupsByFeature[values.feature] || []}
          name="groups"
          label={t('groups')}
          onChange={onChange}
          className={classes.formControl}
          multiple
          cachedOptions={cachedOptions.groups}
        />
      </div>
    )
  }
}

SubscriberStoreSelector = withTranslation()(SubscriberStoreSelector);
SubscriberStoreSelector = withStyles(styles)(SubscriberStoreSelector);
export default SubscriberStoreSelector