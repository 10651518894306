import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import {copyString, getQueryParam} from 'utils'

import enCA from './en_CA.json'
import frCA from './fr_CA.json'
const supportedLocales = ['en-CA', 'fr-CA']
const localePersistenceKey = 'uiLocale'
const fallbackLocale = 'en-CA'

// this should be more idiomatic
const localeToLanguage = new Map()
localeToLanguage.set('en-CA', 'English')
localeToLanguage.set('fr-CA', 'Français')
const languageToLocale = new Map()
languageToLocale.set('English', 'en-CA')
languageToLocale.set('Français', 'fr-CA')

function getBrowserLocale() {
  const browserLocale = navigator.language
  const defaultLocale = supportedLocales.includes(browserLocale) ? browserLocale : fallbackLocale

  try {
    if (!localStorage.getItem(localePersistenceKey) ) {
      localStorage.setItem(localePersistenceKey, defaultLocale)
      return defaultLocale
    } else {
      return localStorage.getItem(localePersistenceKey)
    }
  } catch(e) {
    console.log("localstorage not available")
    return fallbackLocale
  }
}

// initialize translator instance
i18next
  .use(initReactI18next)
  .init({
    lng: getQueryParam("locale") || getBrowserLocale(),
    keySeparator: false,
    fallbackLng: fallbackLocale,
    resources: {
      'en-CA': { translation: enCA },
      'fr-CA': { translation: frCA },
    },
  })

// arrow functions are required here to bind closure variables
const getSupportedLocales = () => Array.from(supportedLocales)

const getSupportedLanguages = () => supportedLocales.map(locale => localeToLanguage.get(locale))

const getLocale = () => copyString(i18next.language)

const setLocale = locale => {
  if (supportedLocales.includes(locale)) {
    i18next.changeLanguage(locale)
    try {
      localStorage.setItem(localePersistenceKey, locale)
    } catch(e) {
      console.log("localstorage not available")
    }
    return copyString(locale)
  }
}

const getLanguage = () => copyString(localeToLanguage.get(getLocale()))

const setLanguage = language => setLocale(languageToLocale.get(language))

export { getSupportedLocales, getSupportedLanguages, getLocale, setLocale, getLanguage, setLanguage }