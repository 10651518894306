import React from 'react'
import {headings} from 'config'
import map from 'lodash/map'
import {get, cleanSelfLink, BASE_URL} from 'utils'
import { withStyles } from '@material-ui/core/styles';
import Paper  from '@material-ui/core/Paper';
import GridLoader from 'components/GridLoader';
import withControls from 'hoc/withControls';
import {
  Grid, PagingPanel,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';

import {
  DataTypeProvider,
  TreeDataState,
  CustomTreeData, PagingState, CustomPaging,
} from '@devexpress/dx-react-grid';
import RelativeLink from "components/RelativeLink";
import {array, func, object} from "prop-types";

const styles = theme => ({
  details: {
    paddingTop: theme.spacing.unit*2
  },
  root: {
    position: "relative",
    border: "1px solid #fff"
  },
  bulkActions: {
    margin: theme.spacing.unit
  },
});

const PrimaryKeyFormatter = ({ value }) => {
  return <RelativeLink to={cleanSelfLink(value.link)}>{value.value}</RelativeLink>
};

const PrimaryKeyProvider = props => (
  <DataTypeProvider
    formatterComponent={PrimaryKeyFormatter}
    {...props}
  />
);


type P = {
  classes: object
}
type S = {
  selection: array,
  primaryColumns: array,
  totalCount: number,
  pageSize: number,
  currentPage: number,
  pageSizes: array<number>,
  loading: boolean,
  items: array,
}

class CustomerTable extends React.Component<P, S> {

  resourceHeadings: object;
  lastQuery: string;
  changeCurrentPage: func;
  changePageSize: func;


  constructor(props) {
    super(props);
    this.resourceHeadings = headings[props.resourceName].list;
    let primaryColumns = headings[props.resourceName].primaryColumns || null ;

    this.lastQuery = "";
    this.state = {
      selection: [],
      primaryColumns,
      totalCount: 0,
      pageSize: 10,
      currentPage: 0,
      pageSizes: [10, 20, 50, 100],
      loading:false,
      items:[],
    }
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData(true);
  }

  getChildRows = (row, rootRows) => {
    return row ? row.children : rootRows
  };

  queryString() {
    const { pageSize, currentPage } = this.state;
    return `${BASE_URL}/v1/customers/command/getCurrentCustomer?projection=tree&size=${pageSize}&page=${currentPage}`
  }
  getCustomerTree = (customer) => {
    const customerTree = [];
    map(customer.children, child => {
      customerTree.push({
        ...child,
        "children": this.getCustomerTree(child),
      })
    })
    return customerTree.length > 0 ? customerTree : null
  }
  loadData = (isUpdating) => {
    const queryString = this.queryString();
    if (isUpdating && queryString === this.lastQuery) {
      return
    }
    get(queryString).then((response) => {
      const items = this.getCustomerTree({"children": response._embedded.customers});
      this.setState({
        items,
        loading: false,
        totalCount:response.page.totalElements
      })
    }).catch((response) => {
      this.setState({loading: false})
    });
    this.lastQuery = queryString
  };
  render() {
    const {classes} = this.props;
    const {items, primaryColumns, pageSize, currentPage, loading, totalCount, pageSizes} = this.state

    return (
      <Paper className={classes.root}>
        <Grid
          rows={items}
          columns={this.resourceHeadings}>

          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <CustomPaging
            totalCount={totalCount}
          />

          {!!primaryColumns && <PrimaryKeyProvider
            for={primaryColumns}
          /> }

          <TreeDataState />
          <CustomTreeData
            getChildRows={this.getChildRows}
          />
          <Table/>
          <TableHeaderRow />

          <TableTreeColumn
            for="name"
          />
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>
        {loading && <GridLoader/>}
      </Paper>
    )
  }
}
CustomerTable = withControls("list")(withStyles(styles)(CustomerTable));
export default CustomerTable