// @flow
import React from 'react'

import {BASE_URL} from 'utils'
import withControls from 'hoc/withControls'
import { withStyles } from '@material-ui/core/styles';
import ResourceCards from 'resource/detail/ResourceCards'

import {object} from "prop-types";
import BottomDrawer from "components/BottomDrawer";

const style = theme => ({
    root: {
      marginBottom: theme.spacing.unit *2
    }
});

type S = {
  data: object
}

class ResourceDetail extends React.Component<*, S> {
  state = {
    data: {},
  };
  componentWillReceiveProps(newProps, nextContext) {
    if (newProps.resourceId !== this.props.resourceId) {
      this.buildSelfLink(newProps.resourceName, newProps.resourceId)
    }
  }
  componentWillMount() {
    const {resourceName, resourceId} = this.props;
    this.buildSelfLink(resourceName, resourceId)
  }
  buildSelfLink = (resourceName: string, resourceId: string) => {
    if (resourceName === "subs") {
      return `${BASE_URL}/v1/subs/search/findByImsiWithSessionReport?searchString=${resourceId}`
    }
    if (resourceName === "sites") {
      return `${BASE_URL}/v1/sites/search/findByDeepSearchWithSessionReport?searchString=${resourceId}&projection=deepAlt`
    }
    if(resourceName === "reports") {
      return `${BASE_URL}/v1/reports/search/findByIdForRole?id=${resourceId}`
    }
    return `${BASE_URL}/v1/${resourceName}/${resourceId}`
  };
  render() {
    const {resourceName, resourceId} = this.props;

    return (
      <React.Fragment>
        <ResourceCards selfLink={this.buildSelfLink(resourceName, resourceId)} resourceName={resourceName} />
        <BottomDrawer />
      </React.Fragment>
    )
  }
}

ResourceDetail = withControls("detail")(
  withStyles(style)(ResourceDetail)
)

export default ResourceDetail