// @flow
import React from 'react'
import ResourceForm from '../ResourceForm'
import TextField from 'components/fields/TextField'
import {object} from "prop-types";
import CustomerSelector from "components/selectors/CustomerSelector";

let defaultData = {
  "name": null,
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "customer": {
    "name": "customer",
    "label": "Customer",
    "labelPath": "name",
    "Component": CustomerSelector,
    "relationship": "one",
    "updatable": false
  },
};

const KeysetForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default KeysetForm
