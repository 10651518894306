// @flow
import React from 'react'

import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import {getEmbeddedResource, getSelfLink, NestedSelector, rollUpSystems} from 'utils'
import {withStyles} from '@material-ui/core/styles';
import {array, func, object} from "prop-types";
import AsyncAutoComplete from "components/fields/AutoCompleteAsync";
import AutoComplete from "components/fields/AutoComplete";
import {searchCustomerByName} from "../../../../utils/search";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

type P = {
  values: object,
  errors: object,
  validationErrors: object,
  help: object,
  cachedOptions: object,
  classes: object,
  readOnly: boolean,
  onChange: func,
}

type S = {
  customers: array,
  featuresByCustomer: object,
  loaded: boolean,
  showCustomers: boolean
}

class SubscriberStoreSelector extends React.Component<P, S> {
  state = {
    customers: [],
    featuresByCustomer: {},
    loaded: false,
    showCustomers: false
  };
  componentWillMount(): void {
    const {cachedOptions, values} = this.props;
    this.loadOptions(NestedSelector.getPrepopulatedSearchString(cachedOptions.customer, values.customer))
  }

  loadOptions = (searchString, callback) => {
    searchCustomerByName(searchString).then((response) => {
      const alreadyHasValues = this.props.values.customer;
      const customers = [];
      const featuresByCustomer = {...this.state.featuresByCustomer};

      map(getEmbeddedResource(response, "customers"), customer => {
        const customerLink = getSelfLink(customer);
        const customerOption = {"label": customer.name, "value": customerLink};
        customers.push(customerOption);
        map(rollUpSystems(customer), system => {
          const featureLink = getSelfLink(system.subFeature);

          // Features do not have name so we are using the system name
          const featureOption = {"label": system.name, "value": featureLink};

          if (!this.state.featuresByCustomer[customerLink]) {
            if (!featuresByCustomer[customerLink]) {
              featuresByCustomer[customerLink] = []
            }
            featuresByCustomer[customerLink].push(featureOption);
          }
        });
      });

      const uniqueCustomers = uniqBy(customers, "value");
      const onlyOneCustomer = uniqueCustomers.length === 1;
      const state = {
        customers: uniqueCustomers,
        featuresByCustomer,
        loaded: true,
        showCustomers: !onlyOneCustomer
      };

      if (onlyOneCustomer && !alreadyHasValues) {
        this.onChange(state)({"customer": uniqueCustomers[0].value})
      }
      this.setState(state, () => {
        if (callback) {
          callback(state.customers)
        }
      });
    })
  };
  onChange = state => (newData: object) => {
    const {featuresByCustomer} = state;
    const {onChange, values} = this.props;

    if (NestedSelector.hasChanged(values, newData, "customer")) {
      newData["feature"] = NestedSelector.getOptionIfOnlyOption(featuresByCustomer, newData["customer"])
    }
    onChange(newData)
  };

  getCustomerError(error, hasValue): string {
    if (error && !hasValue) {
      return error
    }
    return ""
  }
  render() {
    const onChange = this.onChange(this.state);
    const {values, errors, validationErrors, classes, readOnly, cachedOptions, t} = this.props;
    const {customers, featuresByCustomer} = this.state;
    return (
      <div>
        <AsyncAutoComplete
          value={values.customer}

          // this is intentional since the backend will not throw an error for missing customer
          error={errors.feature}
          validationError={this.getCustomerError(validationErrors.feature, values.customer)} // ^^

          options={customers}
          name="customer"
          label={t('customer')}
          onChange={onChange}
          className={classes.formControl}
          disabled={readOnly}
          displayEmpty={false}
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions={customers}
          cachedOptions={cachedOptions.customer}
        />
        <AutoComplete
          value={values.feature}
          error={errors.feature}
          validationError={validationErrors.feature}
          options={featuresByCustomer[values.customer] || []}
          name="feature"
          label={t('system')}
          onChange={onChange}
          className={classes.formControl}
          disabled={readOnly}
          displayEmpty={false}
          cachedOptions={cachedOptions.feature}
        />
      </div>
    )
  }
}

SubscriberStoreSelector = withTranslation()(SubscriberStoreSelector);
SubscriberStoreSelector = withStyles(styles)(SubscriberStoreSelector);
export default SubscriberStoreSelector