// @flow
import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ChartContainer from "../home/ChartContainer";
import moment from "moment";
import StackedBarChart from "./StackedBarChart";
import Tooltip from "./Tooltip";
import {BASE_URL, formatBytes, get} from "utils";
import * as d3 from "d3";
import {func, object} from "prop-types";
import {map} from "lodash"
import Select from "../fields/Select";
import GridLoader from "../GridLoader";
import {DateRangePicker} from "react-dates"
import { withTranslation } from "react-i18next"

type P = {
  id: string,
  endpoint: string,
  groupByOptions: object,
  groupBy?: string
}
type S = {
  startDate: moment,
  endDate: moment,
  groupBy: string,
  data: ?object,
  key: ?string,
  tooltip: ?object,
  tooltipVisible: boolean,
  dateRange: string,
  datePart: string,
  formatDate: func,
  error: boolean,
  focusedInput: ?string
}

class UsageBarChart extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      groupBy: props.groupBy || "system",
      data: null,
      key: null,
      tooltip: null,
      tooltipVisible: false,
      dateRange: "today",
      datePart: "hour",
      formatDate: d3.timeFormat("%I %p"),
      error: false,
      focusedInput: null
    };
  }


  componentDidMount() {
    const {endpoint} = this.props;
    const {startDate, endDate, groupBy, datePart} = this.state;
    this.loadReport(endpoint, startDate, endDate, groupBy, datePart)
  }

  componentWillUpdate(nextProps: P, nextState: S, nextContext: *) {
    const {startDate, endDate, groupBy, datePart} = this.state;
    if (nextState.startDate !== startDate || nextState.endDate !== endDate ||
      nextState.groupBy !== groupBy || nextState.datePart !== datePart) {
      this.loadReport(nextProps.endpoint, nextState.startDate, nextState.endDate, nextState.groupBy, nextState.datePart)
    }
  }

  loadReport(endpoint: string, startDate: ?moment, endDate: ?moment, groupBy: string, datePart: string) {
    if (startDate == null || endDate == null) {
      return
    }
    this.setState({key: null});

    const startDateString = encodeURIComponent(startDate.format());
    const endDateString = encodeURIComponent(endDate.format());

    get(`${BASE_URL}${endpoint}minDate=${startDateString}&maxDate=${endDateString}&groupBy=${groupBy}&interval=${datePart}`).then((response) => {
        this.setState({key: moment(), data: response, error: false})
      }
    ).catch(() => {
      this.setState({"error": true})
    })
  }

  getHighlight = (data: *) => {
    const totalUpload = d3.sum(data, d => d.upload);
    const totalDownload = d3.sum(data, d => d.download);
    return `${formatBytes(totalDownload) || 0} download, ${formatBytes(totalUpload) || 0} upload`
  };
  onChangeDate = (data: *) => {
    let offsetDiff: number;
    let startDate;
    let endDate;
    let datePart = "hour";
    let formatDate = d3.timeFormat("%I %p");

    const now = moment();
    switch (data["dateRange"]) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").endOf("day");
        break;
      case "week":
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        break;
      case "last_week":
        startDate = moment().startOf("week").subtract(1, "week");
        endDate = moment().endOf("week").subtract(1, "week");
        break;
      case "last_month":
        startDate = moment().subtract(1, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month");
        break;
      case "month":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      case "last_year":
        startDate = moment().subtract(1, "year").startOf("year");
        endDate = moment().subtract(1, "year").endOf("year");
        break;
      case "year":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");
        break;
      case "custom":
        startDate = data["startDate"];
        endDate = data["endDate"];
        break;
      default:
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
    }

    // if we have both start dates figure out how we want to group the time
    // otherwise revert to default view
    if (startDate && endDate) {
      const days = moment.duration(endDate.diff(startDate)).asDays();
      if (days > 4) {
        datePart = "day";
        formatDate = d3.timeFormat("%b %d");
      }
      if (days > 90) {
        datePart = "month";
        formatDate = d3.timeFormat("%B");
      }
    } else {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
    }

    // this is necessary to correct for timechanges
    offsetDiff = startDate.utcOffset() - now.utcOffset();
    if (offsetDiff !== 0) {
      startDate.add(offsetDiff, "minute").utcOffset(now.utcOffset())
      endDate.add(offsetDiff, "minute").utcOffset(now.utcOffset())
    }

    this.setState({startDate, endDate, datePart, formatDate, dateRange: data["dateRange"]})
  };
  onChange = (data: *) => {
    this.setState({...data})
  };
  getTooltip = () => {
    const {tooltipVisible, tooltip, formatDate} = this.state;
    const { t } = this.props;
    if (!tooltip) {
      return ""
    }
    const opacity = tooltipVisible ? .9 : 0;
    const rows = tooltip.data.data;
    return (
      <Tooltip left={tooltip.x} top={tooltip.y} opacity={opacity}>
        <span>{t('date')}: {formatDate(tooltip.date)}</span><br/>
        {map(rows, (value, key) => value.y ? <span>{key}: {formatBytes(value.y)}<br/></span> : null)}
      </Tooltip>
    )
  }

  render() {
    const {key, data, groupBy, dateRange, formatDate, error, startDate, endDate, focusedInput} = this.state;
    const {id, groupByOptions, t} = this.props;
    return (
      <ChartContainer>
        <Grid container justify="space-between">
          <Grid item>
            <h4>{t('usageHistory')}</h4>
          </Grid>
          <Grid item>
            <Grid container justify="space-around" spacing={16}>
              <Grid item>
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={startDate}
                  startDateId="start-date-id"
                  endDate={endDate}
                  endDateId="end-date-id"
                  onDatesChange={({startDate, endDate}) => {
                    this.onChangeDate({"dateRange": "custom", startDate, endDate})
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => this.setState({focusedInput})}
                />
              </Grid>
              <Grid item>
                <Select options={[
                  {"value": "today", "label": t("today")},
                  {"value": "yesterday", "label": t("yesterday")},
                  {"value": "week", "label": t("thisWeek")},
                  {"value": "last_week", "label": t("lastWeek")},
                  {"value": "month", "label": t("thisMonth")},
                  {"value": "last_month", "label": t("lastMonth")},
                  {"value": "year", "label": t("thisYear")},
                  {"value": "last_year", "label": t("lastYear")},
                  {"value": "custom", "label": t("custom")}
                ]} onChange={this.onChangeDate} name="dateRange" label={t('dateRange')} value={dateRange} displayEmpty={false} margin="none"/>
              </Grid>
              <Grid item>
                <Select options={groupByOptions} onChange={this.onChange} value={groupBy} name="groupBy" label={t('groupBy')}
                        displayEmpty={false} margin={"none"}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
          <div>
            {data && <p>{t('totalUsage')}: {formatBytes(data.totalUsage) || 0}</p>}
          </div>
          <div style={{position: "relative"}}>
            {error ? <p>{t('chartLoadError')}</p> :
              key ? <StackedBarChart id={id}
                                     key={key}
                                     data={data && data.dateMap}
                                     getHighlight={this.getHighlight}
                                     getTooltip={this.getTooltip}
                                     onChange={this.onChange}
                                     formatDate={formatDate}
              /> : <GridLoader/>}
          </div>
          {this.getTooltip()}
      </ChartContainer>
)}
}

export default withTranslation()(UsageBarChart)
