// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import Select from 'components/fields/Select';
import MonitoringSiteFeature from "./fields/MonitoringSiteFeature";
import Extensions from 'components/fields/Extensions';
// import MonitoringLabels from 'components/fields/MonitoringLabels';

let defaultData = {
  "name": null,
  "status": null
};

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "jobName": {
    "Component": TextField,
    "name": "jobName",
    "label": "Job Name"
  },
  "metricsUrl": {
    "Component": TextField,
    "name": "metricsUrl",
    "label": "Metrics URL"
  },
  "scrapeInterval": {
    "Component": TextField,
    "name": "scrapeInterval",
    "label": "Scrape Interval"
  },
  "scrapedBy": {
    "Component": (props) => <Select {...props} displayEmpty={false} />,
    "options": [
      {
        "value": "AGENT",
        "label": "AGENT"
      },
      {
        "value": "PROMETHEUS",
        "label": "PROMETHEUS"
      }
    ],
    "name": "scrapedBy",
    "label": "Scraped By"
  },
  "status": {
    "Component": (props) => <Select {...props} displayEmpty={false} />,
    "options": [
      {
        "value": "ENABLED",
        "label": "Enabled"
      },
      {
        "value": "DISABLED",
        "label": "Disabled"
      }
    ],
    "name": "status",
    "label": "Status"
  },
  "feature": {
    "Component": MonitoringSiteFeature,
    "name": "feature",
    "label": "Feature"
  },
  "extensions": {
    "Component": Extensions,
  }
};

const MonitoringForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}} />
);

export default MonitoringForm
