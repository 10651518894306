// @flow
import * as React from 'react'
import {array, func} from "prop-types";

type P = {
  Component: *,
  name: string,
  onChange: func,
  label: string,
  value :string,
  error: string,
  validationError: string,
  help: string,
  className: string,
  readOnly: boolean,
  displayEmpty: boolean,
  type: string,
  variant: string,
  rows: array,
  multiline: boolean
}
class Field extends React.PureComponent<P> {
  render() {
    const { Component, ...props} = this.props;
    return (
      <Component {...props} />
    )
  }
}
export default Field
