// @flow
import createStore from "redux-zero";
import { applyMiddleware } from "redux-zero/middleware";
import { connect } from "redux-zero/devtools";

const initialState = {
  disableEventPropagation: false,
  showLogin: true,
  showProfileLink: true,
  bottomDrawer: {
    open: false
  },
  loginAttempted: false
};
const middlewares = [];
const store = createStore(initialState, middlewares);

export default store;
