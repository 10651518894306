import React, {PureComponent} from 'react';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit * 2
  }
});

class LoginButton extends PureComponent {
  render() {
    const {text, classes, disabled} = this.props
    return (
      <FormControl margin="none">
        <Button id="login-button" type="submit" variant="contained" color="primary" className={classes.button} button disabled={disabled}>
          {text}
        </Button>
      </FormControl>
    );
  }
}

export default withStyles(styles)(LoginButton);