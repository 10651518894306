// @flow
import React from 'react'

import {BASE_URL} from 'utils'
import ResourceCardContainer from 'resource/detail/card/ResourceCardContainer'
import ScriptCardContainer from '../../components/ScriptCardContainer'
import {withRouter} from 'react-router-dom'
import withPolling from '../../hoc/withPolling'
import GridLoader from 'components/GridLoader'
import {get} from 'lodash'
import {currentPageData} from "../../config";

class ResourceCards extends React.PureComponent {

  getLink = (url) => url.split(BASE_URL).pop()
  
  isScriptContainer = resourceName => resourceName === 'interceptor-scripts' || resourceName === 'server-configs'

  formatSubsData(data) {
    const sub = data.subscriber
    sub.sessions = data.sessions
    sub.device = get(data.sessions, "0.device")
    sub.state = data.state
    sub.sms = data.sms
    
    return sub
  }

  formatData = (resourceName, data) => {
    if (resourceName === 'subs'){
      return this.formatSubsData(data)
    }

    return data
  }

  getResourceDetailLink(props) {
    const queryMarker = this.getLink(props.selfLink).includes('?') ? '&' : '?'
    return props.selfLink + queryMarker + 'projection=deep'
  }

  componentDidMount() {
    this.props.startPolling(this.getResourceDetailLink(this.props))
  }

  componentDidUpdate() {
    currentPageData.resourceData = get(this.props, "data")
  }
  
  componentWillUnmount() {
    currentPageData.resourceData = undefined
    this.props.stopPolling()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.selfLink !== this.props.selfLink) {
      this.props.stopPolling(true)
      newProps.data = null
      newProps.startPolling(this.getResourceDetailLink(newProps))
    }
  }

  render() {
    const {resourceName, data, className} = this.props
    const DetailComponent = this.isScriptContainer(resourceName) ? ScriptCardContainer : ResourceCardContainer

    if (!data) {
      return <GridLoader/>
    }

    return <DetailComponent className={className} data={this.formatData(resourceName, data)} model={resourceName} />
  }

}

ResourceCards = withRouter(ResourceCards)
ResourceCards = withPolling(ResourceCards)
export default ResourceCards
