// @flow
import React from 'react'
import Grid from "@material-ui/core/Grid";
import DevicePieChart from "../components/charts/DevicePieChart"
import UsageBarChart from "../components/charts/UsageBarChart";
import CustomerReport from "../components/home/CustomerReport";
import {withStyles} from "@material-ui/core";
import {object} from "prop-types";
import { withTranslation } from "react-i18next"

const styles = theme => ({
  "home": {
    "overflow": "visible"
  }
});

type P = {
  classes: object
}
class Home extends React.PureComponent<P> {
  render() {
    const {classes, t} = this.props;
    return (
      <Grid container spacing={16} className={classes.home}>
        <Grid item xs={12}>
          <CustomerReport/>
        </Grid>
        <Grid item xs={4}>
          <DevicePieChart id="subscriberPie" groupByOptions={[
            {"value": "system", "label": t("system")},
            {"value": "customer", "label": t("customer")},
            {"value": "status", "label": t("status")},
            {"value": "site", "label": t("site")}
          ]} />
        </Grid>
        <Grid item xs={8}>
          <UsageBarChart id="usageChart" endpoint="/v1/subs/command/getUsageReportByCustomer?" groupByOptions={[
            {"value": "system", "label": t("system")},
            {"value": "site", "label": t("site")},
            {"value": "subscriber", "label": t("subscriber")},
            {"value": "customer", "label": t("customer")},
            {"value": "direction", "label": t("direction")}
          ]} groupBy="system"/>
        </Grid>
      </Grid>
    )
  }
}

Home = withTranslation()(withStyles(styles)(Home));
export default Home