import moment from 'moment-timezone';

// load moment-timezone's timezones data
moment.tz.load({version: 'latest', zones: [], links: []});

const timezonesNames = (() => {
    //  Filter a bit the list of timezones by removing the Etc/GMT-x entries
    const prefixesToRemove = ['Etc'];

    const onlyCanonicalZones = zone => zone.indexOf('/') >= 0;
    const nonBlacklistedPrefixes = zone => !(prefixesToRemove.indexOf(zone.split('/')[0]) >= 0);

    return moment.tz.names()
        .filter(zone =>
            onlyCanonicalZones(zone)
            && nonBlacklistedPrefixes(zone)
        );
})();

const timezonesSet = new Set();

timezonesNames.forEach((name) => {
        const timezone = {};
        timezone.value = name;
        timezone.label = name + " - " + moment().tz(name).zoneAbbr();
        timezonesSet.add(timezone);
    }
);

export const timezones = [...timezonesSet];
/**
 * True or false as to whether the search value is contained within the string.
 */
const isMatch = (searchValue, searchString, minLength) => {
    if (!searchValue || !searchString || typeof searchValue !== 'string' || typeof searchString !== 'string' || searchString.length < minLength) return false;
    const valueToSearchFor = searchString.toLowerCase();
    const stringToSearchIn = searchValue.toLowerCase();

    return stringToSearchIn.indexOf(valueToSearchFor) !== -1;
};

export const getDefaultTimezone = () => timezoneSearch(moment.tz.guess()).pop()
export const timezoneSearch = (searchString) => {
    let filtered = timezones
        .filter((timezone) => {
            return isMatch(timezone.label, searchString, 3)
    });
    return filtered;
};