// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel  from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type {TableActionProps} from "types";

const styles = theme => ({
  form: {
    padding: theme.spacing.unit*2,
    display: "block"
  }
});

class TableAction extends React.Component<TableActionProps> {
  render() {
    const {classes, expanded, name, togglePanel, label, children} = this.props;
    return (
      <ExpansionPanel expanded={expanded === name} onChange={togglePanel(name)}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon>expand_more</ExpandMoreIcon>}>{label}</ExpansionPanelSummary >
        <ExpansionPanelDetails className={classes.form}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

TableAction = withStyles(styles)(TableAction);
export default TableAction
