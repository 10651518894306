// @flow
import React from 'react';
import {withTranslation} from 'react-i18next';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import map from 'lodash/map'
import DefaultSnackbar from 'components/DefaultSnackbar'

import {withStyles} from '@material-ui/core/styles';
import {array, func, object} from "prop-types";

const styles = {
  list: {
    width: 800
  },
  totalElements: {
    padding: "12px 24px",
    margin: "0"
  }
};

type P = {
  actions: object,
  className: string,
  classes: object,
  selectedItems: array,
  changeSelection: func
}
type S = {
  anchorEl: ?Element,
  drawerOpen: boolean,
  snackOpen: boolean,
  expanded: ?object,
  message: ?string,
  messageType: ?string
}
class TableActionMenu extends React.Component<P, S> {
  state = {
    anchorEl: null,
    drawerOpen: false,
    snackOpen: false,
    message: null,
    expanded: null,
    messageType: null
  };
  onDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };
  toggleDrawer = () => {
    this.setState({drawerOpen: true});
  };
  onSnackClose = () => {
    this.setState({snackOpen: false, message:null });
  };
  renderMessage = messageType => (message) => {
    this.setState({snackOpen: true, message, messageType});
  };
  expandPanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  render() {
    const {actions, className, classes, selectedItems, changeSelection, t} = this.props;
    const {drawerOpen, expanded, snackOpen, message, messageType} = this.state;
    const actionProps = {
      expanded,
      selectedItems,
      changeSelection,
      togglePanel: this.expandPanel,
      renderMessage: this.renderMessage,
      closeDrawer: this.onDrawerClose
    };
    return (
      <div className={className}>
        <Button variant="outlined" onClick={this.toggleDrawer}>{t('bulkActions')}</Button>
        <Drawer anchor="right" open={drawerOpen} onClose={this.onDrawerClose}>
          <div className={classes.list}>
            <p className={classes.totalElements} >
              <Button variant="outlined" color="secondary" size="small" onClick={this.onDrawerClose}>
                {t('close')}
              </Button>
            </p>
            <p className={classes.totalElements}>{selectedItems.length} {t('elementsSelected')}</p>
            <List>
              { map(actions, ({Component, name}) => <Component key={name} {...actionProps} name={name} /> )}
            </List>
          </div>
        </Drawer>
        <DefaultSnackbar open={snackOpen} onClose={this.onSnackClose} message={message} messageType={messageType}/>
      </div>
    )
  }
}

TableActionMenu = withTranslation()(withStyles(styles)(TableActionMenu));
export default TableActionMenu