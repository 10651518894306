// @flow
import React from 'react'
import * as d3 from 'd3';
import {func, object} from "prop-types";
import {cleanId} from "../../utils/format";
import {color, otherColor} from "./colours";


type P = {
  id: string,
  data: object,
  onChange: func
}
type S = {
  width: number,
  height:number
}

class DonutChart extends React.Component<P, S> {

  container: func;

  state = {
    width: 0,
    height: 0
  };

  initChart = () => {
    this.drawChart()
  };

  getColour = (d:object) => {
    return d.key === "Other" ? otherColor(d.key) : color(d.key);
  }
  drawChart = () => {

    const {id, data, onChange} = this.props;

    const midAngle = d => (d.startAngle + (d.endAngle - d.startAngle) / 2);

    const width = this.state.width;
    const height = this.state.width*.66;

    const radius = Math.min(width, height)/3;

    const svg = d3.select(`#${cleanId(id)}`).attr('viewBox','0 0 '+width+' '+height)
      .attr('preserveAspectRatio','xMinYMin')
      .append("g");

    svg.append("g")
      .attr("class", "slices");
    svg.append("g")
      .attr("class", "labels");
    svg.append("g")
      .attr("class", "lines");

    svg.attr('transform', 'translate(' + (width/2) + ',' + (height/2) + ')');

    const outerArc = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius * .3);

    const arc = d3.arc()
      .innerRadius(radius * .1)
      .outerRadius(radius * .5);

    const pie = d3.pie()
      .value(d => { return d.value && d.value.count})
      .sort(null);

    const groupedData = d3.nest()
      .key(function(d) { return d.percentage > 5 ? d.label : "Other"; })
      .rollup(function(v) {
          const percentage = d3.sum(v, (dv) => dv.percentage);
          const label = percentage > 5 ? v[0].label : "Other"
          return {
            "count": d3.sum(v, (dv) => dv.count),
            "percentage": percentage,
            "label": label
          }
      })
      .entries(data);

    svg.selectAll('path')
      .data(pie(groupedData))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => this.getColour(d.data))
      .on("mouseover", function() { onChange({"tooltipVisible": true}); })
      .on("mouseout", function() { onChange({"tooltipVisible": false}); })
      .on("mousemove", function(d) {
        onChange({
          "tooltip": {
            data: d.data.value,
            x:d3.event.clientX,
            y:d3.event.clientY,
          }
        })
      });

    svg.append('g').classed('labels',true);
    svg.append('g').classed('lines',true);


    svg.select('.lines')
      .selectAll('polyline')
      .data(pie(groupedData))
      .enter().append('polyline')
      .attr('points', function(d) {
        if (d.data.value.percentage < 5) {
          return null
        }
        const pos = outerArc.centroid(d);
        pos[0] = radius * .6 * (midAngle(d) < Math.PI ? 1 : -1);
        return [arc.centroid(d) , outerArc.centroid(d) , pos]
      });

    svg.select('.labels').selectAll('text')
      .data(pie(groupedData))
      .enter().append('text')
      .attr('dy', '.15em')
      .html(d => {
        if (d.data.value.percentage < 5) {
          return
        }
        return `${d.data.value.percentage}%`
      })
      .attr('transform', function(d) {
        const pos = outerArc.centroid(d);
        pos[0] = radius * .65 * (midAngle(d) < Math.PI ? 1 : -1);
        return 'translate(' + pos + ')';
      })
      .style("overflow", "visible")
      .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
      .style('font-size', '1rem');

    const legend = svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(groupedData)
      .enter().append("g")
      .attr("transform", function(d, i) { return "translate(" + width/2 + "," + ((i * 20) - (height/2)) + ")"; });

    legend.append("rect")
      .attr("x", 0-24)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", d => this.getColour(d));

    legend.append("text")
      .attr("x", 0-26)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text(d => d.key);
  };
  getContainer = (container: object) => {
    if (!this.container) {
      this.container = container;
      this.setState({width: container.offsetWidth, height: container.offsetWidth }, () => {
        this.initChart()
      })
    }
  };
  render() {
    return (
      <div ref={this.getContainer} >
        <svg id={cleanId(this.props.id)} width="100%" height="66%" />
      </div>
    )
  }
}

export default DonutChart