import React from 'react';
import {withTranslation} from "react-i18next";

const styles = {
  "padding": "40px",
  "margin": "0 auto",
  "minHeight": "50vh"
};


export class FormInner extends React.Component {
  onSuccess = () => {
    window.location = document.location
  };
  render() {
    const {t, label} = this.props;
    return <div style={styles}>
      <h2 style={{"padding-left": "16px"}}>{t(label)}</h2>
      { this.props.children }
    </div>
  }
}

FormInner = withTranslation()(FormInner);