// @flow
import Card from "@material-ui/core/Card";
import * as React from "react";
import {object} from "prop-types";

type P = {
  classes: object,
  children: React.Node
}
class styledCard extends React.PureComponent<P> {
  render() {
    const {children, classes} = this.props;
      return (
        <Card className={classes.className}>
          {children}
        </Card>
      )
  }
}
export default styledCard