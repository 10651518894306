// @flow
import React from 'react'
import {headings} from 'config'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import {get, getPolling, getQueryParam, getSelfLink} from 'utils'

import Paper from '@material-ui/core/Paper'
import {fade} from '@material-ui/core/styles/colorManipulator'

import withControls from 'hoc/withControls'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import {withTranslation} from 'react-i18next'

import GridLoader from 'components/GridLoader'
import ErrorLoader from 'components/ErrorLoader'
import TableActionMenu from 'components/table/actions/TableActionMenu'

import ResourceCards from 'resource/detail/ResourceCards'

import {func, object} from "prop-types"
import * as Sentry from '@sentry/browser'


import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';


import {
  CustomPaging,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {createQueryStringFromMapping} from "../../utils";
import PrimaryKeyProvider from "./PrimaryKeyProvider";
import ActionProvider from "./ActionProvider";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  details: {
    paddingTop: theme.spacing.unit * 2
  },
  root: {
    position: "relative",
    border: "1px solid #fff"
  },
  bulkActions: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  tableStriped: {
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
  exportAction: {
    position: "relative"
  },
  inlineBlock: {
    display: "inline-block"
  }
});

type
P = {
  resourceName: string,
  bulkActions: object,
  hasSearch: boolean,
  classes: object,
  user: object,
  clearPoll: func,
  updatePoll: func,
  baseUrl: string,
  history: object,
  location: object,
  match: object,
  polling: func,
}
type
QueryStringParts = {
  page: number,
  searchString: string,
  size: number,
}

class ResourceTable extends React.Component<P, object> {

  resourceHeadings: ?object;
  defaultPageSize: number;
  rowDetail: ?object;
  previousEndpoint: string;
  loadData:
  function;
  getDefaultParams:
  function;
  searchShortcuts: ?object;

  constructor(props) {
    super(props);
    this.resourceHeadings = headings[props.resourceName].list;
    this.searchShortcuts = headings[props.resourceName].searchShortcuts;
    this.defaultPageSize = headings[props.resourceName].pageSize || 20;
    this.getDefaultParams = headings[props.resourceName].getDefaultParams || function (row) {
      return null
    };

    let resourceDetails = headings[props.resourceName].detail;
    let columnBands = headings[props.resourceName].columnBands;
    let primaryColumns = headings[props.resourceName].primaryColumns || null;
    let sort = headings[props.resourceName].sort || null;
    let endpoint = headings[props.resourceName].endpoint || "";
    let exportCSVEndpoint = headings[props.resourceName].exportCSVEndpoint || "";
    let dataFormatter = headings[props.resourceName].dataFormatter || function (items) {
      return items
    };


    let _getSelfLink = headings[props.resourceName].getSelfLink || getSelfLink;
    if (resourceDetails) {
      this.rowDetail = class extends React.PureComponent<object> {
        render() {
          return <ResourceCards
            {...this.props}
            selfLink={_getSelfLink(this.props.row)}
            resourceName={props.resourceName}
            sections={resourceDetails}
            className={props.classes.details}
          />;
        }
      }
    }

    this.previousEndpoint = "";
    this.state = {
      selection: [],
      primaryColumns,
      totalCount: 0,
      pageSizes: [10, 20, 50, 100],
      loading: true,
      items: [],
      columnBands,
      sort,
      endpoint,
      exportCSVEndpoint,
      dataFormatter,
      error: null
    };
    this.loadData = debounce(this._loadData, 400)
  }

  componentDidMount() {
    this.setDefaultQueryString();
    this._loadData();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext): boolean {
    return nextProps.polling === this.props.polling
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    this.setDefaultQueryString();
    this.loadData(true, false);
  }

  componentWillUnmount() {
    this.props.clearPoll()
  }

  setDefaultQueryString = () => {
    const queryParams = this.getQueryParams();
    if (!queryParams.page || !queryParams.size) {
      const queryString = this.createQueryString({
        page: 0,
        size: this.defaultPageSize,
        searchString: "",
        ...queryParams
      });
      this.updateQueryString(queryString);
    }
  };

  changeSelection = selection => this.setState({selection});

  changeCurrentPage = page => {
    const queryString = this.createQueryString({...this.getQueryParams(), page});
    return this.updateQueryString(queryString)
  };

  changePageSize = size => {
    const queryString = this.createQueryString({...this.getQueryParams(), size, page: 0});
    return this.updateQueryString(queryString)
  };

  changeSearchValue = searchString => {
    const queryString = this.createQueryString({...this.getQueryParams(), searchString, page: 0});
    return this.updateQueryString(queryString)
  };

  updateQueryString = newQueryString => {
    const history = this.props.history;
    return history.push(`${history.location.pathname}${newQueryString}`)
  };

  exportToCsv = () => {
    const url = this.exportToCsvEndpoint();
    window.open(url, '_blank');
  };

  // TODO we can expand on this later, but this naive implementation will work for now
  withSearchShortcuts = searchString => {
    let _searchString = searchString;
    if (searchString && this.searchShortcuts) {
      map(this.searchShortcuts, (shortcut) => {
        if (shortcut.match(_searchString)) {
          _searchString = shortcut.transform(_searchString)
        }
      })
    }
    return _searchString
  };

  createQueryString({page, size, searchString}: QueryStringParts): string {
    let queryString = `?page=${page}&size=${size}`;
    if (searchString) {
      const encodedSearch = encodeURIComponent(searchString);
      queryString = `${queryString}&searchString=${encodedSearch}`
    }
    return queryString
  }

  getQueryParams(): QueryStringParts {
    const params = {};
    map(["page", "size", "searchString"], (param) => {
      const value = getQueryParam(param);
      if (value) {
        params[param] = decodeURIComponent(value)
      }
    });
    return params
  };

  apiEndpoint() {
    const {baseUrl} = this.props;
    const {sort, endpoint} = this.state;
    const {page, size, searchString} = this.getQueryParams();

    let queryString = this.buildQueryString(searchString, page, size, sort);
    return `${baseUrl}${endpoint}${queryString}`
  }

  buildQueryString(searchString: string, page: number, size: number, sort: any) {
    // check to see if there are any shortcuts we can use
    const _searchString = this.withSearchShortcuts(searchString);

    let queryString = this.createQueryString({page, size, searchString: _searchString});
    if (sort) {
      queryString = `${queryString}&sort=${sort}`;
    }
    queryString = `${queryString}&projection=brief`;
    const defaultParams = this.getDefaultParams(null);
    if (defaultParams) {
      queryString = `${queryString}&${createQueryStringFromMapping(defaultParams)}`;
    }
    return queryString
  }

  exportToCsvEndpoint() {
    const {baseUrl} = this.props;
    const {sort, exportCSVEndpoint, totalCount} = this.state;
    const {searchString} = this.getQueryParams();

    let queryString = this.buildQueryString(searchString, 0, totalCount, sort);
    return `${baseUrl}${exportCSVEndpoint}${queryString}`
  }

  _loadData(isUpdating, polling) {
    const {dataFormatter} = this.state;

    const endpoint = this.apiEndpoint();

    if (isUpdating && endpoint === this.previousEndpoint) {
      return
    }

    if (isUpdating) {
      this.setState({loading: true});
    }
    const func = polling ? getPolling : get;
    func(endpoint).then((response) => {

      // don't return old queries that may not have been debounced properly
      if (isUpdating && endpoint !== this.apiEndpoint()) {
        return
      }

      let items = response._embedded
        ? map(response._embedded, items => map(items, item => item))[0] : [];
      items = dataFormatter(items);

      this.setState({
        items,
        loading: false,
        error: false,
        totalCount: response.page.totalElements
      });
      this.props.updatePoll(this.conditionalLoadData)

    }).catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      if (polling) {
        this.props.updatePoll(this.conditionalLoadData)
      } else {
        this.setState({
          loading: false,
          error: true
        })
      }
    });

    this.previousEndpoint = endpoint
  }

  filterColumnsByUser = user => {
    const filteredColumns = user !== "SUPERUSER"
      ? filter(this.resourceHeadings, header => header.name !== "actions")
      : this.resourceHeadings
    const translatedColumns = filteredColumns.map(column => {
      const col = {...column}
      if (column.title) col.title = this.props.t(column.title)

      return col
    })

    return translatedColumns
  };
  conditionalLoadData = (urlHash) => {
    return () => {
      const currentUrlHash = this.props.location.key;
      if (urlHash === currentUrlHash) {
        this.loadData(false, true)
      }
    }
  };

  render() {
    const {resourceName, bulkActions, hasExportToCSV, hasSearch, classes, user, t} = this.props;
    const {items, selection, primaryColumns, loading, totalCount, pageSizes, columnBands, error} = this.state;
    const {page, size, searchString} = this.getQueryParams();
    return (
      <Paper className={classes.root}>
        <div className={classes.inlineBlock}>
          {
            bulkActions.length > 0 &&
            <TableActionMenu actions={bulkActions} className={classes.bulkActions} selectedItems={selection}
                             changeSelection={this.changeSelection}/>
          }
          {
            hasExportToCSV
              ? (
                <div className={classes.bulkActions}>
                  <Button
                    variant="outlined"
                    className={classes.exportAction}
                    onClick={this.exportToCsv}
                  >{t('exportToCSV')}</Button>
                </div>
              )
              : null
          }
        </div>
        <Grid
          rows={items}
          columns={this.filterColumnsByUser(user)}
          getRowId={row => getSelfLink(row)}
        >


          {hasSearch && <SearchState
            onValueChange={this.changeSearchValue}
            value={searchString || ""}
          />}

          <PagingState
            currentPage={parseInt(page)}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={parseInt(size)}
            onPageSizeChange={this.changePageSize}
          />
          <CustomPaging
            totalCount={totalCount}
          />
          {!!primaryColumns && <PrimaryKeyProvider
            for={primaryColumns}
          />}
          <ActionProvider
            for={["actions"]}
            resourceName={resourceName}
          />
          <SelectionState
            selection={selection}
            onSelectionChange={this.changeSelection}
          />
          <IntegratedSelection/>
          {!!this.rowDetail && <RowDetailState/>}
          <Table
            noDataCellComponent={error ? ErrorLoader : Table.NoDataCell}
          />
          <TableHeaderRow/>
          <TableColumnVisibility/>
          <TableSelection showSelectAll/>

          {!!columnBands && <TableBandHeader
            columnBands={columnBands}
          />}
          {!!this.rowDetail && <TableRowDetail
            contentComponent={this.rowDetail}
          />}

          <Toolbar/>
          {hasSearch && <SearchPanel/>}

          <PagingPanel
            pageSizes={pageSizes}
          />
          <ColumnChooser/>
        </Grid>
        {loading && <GridLoader/>}
      </Paper>
    )
  }
}

ResourceTable = withStyles(styles)(ResourceTable)
ResourceTable = withRouter(ResourceTable)
ResourceTable = withControls("list")(ResourceTable)
ResourceTable = withTranslation()(ResourceTable)
export default ResourceTable
