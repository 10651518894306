import {getHeaders, handleResponse, handleGetUser} from 'utils/fetch/common';

export function get(url) {
  let buildURL = `${url}`;
  return fetch(buildURL, {
    credentials: 'include',
    headers: {
      ...getHeaders(),
    }
  }).then(handleResponse);
}

export function getCors(url) {
  let buildURL = `${url}`;
  return fetch(buildURL, {
    credentials: 'include',
    mode: "cors",
  }).then(handleResponse);
}


export function getPolling(url) {
  let buildURL = `${url}`;
  return fetch(buildURL, {
    credentials: 'include',
    headers: {
      ...getHeaders(),
      "polling": true
    }
  }).then(handleResponse);
}

export function getUser(url, config) {
  let buildURL = `${url}`;
  return fetch(buildURL, {
    credentials: 'include',
    headers: {
      ...getHeaders(),
    },
    redirect: 'error'
  }).then(handleGetUser(config)).catch(error => undefined);
}