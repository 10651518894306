// @flow
import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppToolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/es/Drawer/Drawer';
import {object, func} from 'prop-types';
import {connect} from "redux-zero/react";
import {withTranslation} from "react-i18next";

// A mapping to add context on drawers for https://gitlab.expeto.io/expeto/thunder/-/issues/1749
const MAP_RESOURCE_TITLE = {
  'gateway-hosts': 'AddGatewayHost',
  'keyset-mappings': 'AddKeysetMapping',
  'monitoring-endpoints': 'AddMonitoringEndpoint',
  'private-radios': 'AddPrivateRadio',
  'account-feature-api-tokens':'AddAPIToken',
  'site-feature-api-tokens': 'AddAPIToken',
  'sub-imsis': 'AddOrDeleteSubscriberImsis',
}

type P = {
  open: boolean,
  open: boolean,
  Component: object,
  componentProps: object,
  bottomDrawer: object,
  closeBottomDrawer: func,
}

type S = {
  open: boolean,
  history: object
}

class BottomDrawer extends React.Component<P, S> {
  state = {
    open: false,
    history: null
  };
  style = {
    position: "relative"
  };
  handleClose = (e: Event) => {
    // e.stopPropagation();
    this.props.closeBottomDrawer()
  };

  render() {
    const {bottomDrawer} = this.props;
    const title = this.props.t(MAP_RESOURCE_TITLE[bottomDrawer?.componentProps?.resourceName] || '')
    const isCancelHidden = Boolean(title)

    return (
      <Drawer anchor="bottom" open={bottomDrawer.open} disableEnforceFocus={true}>
        <AppBar style={this.style}>
          <AppToolbar>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <CloseIcon/>
            </IconButton>
            {title}
          </AppToolbar>
        </AppBar>
        <bottomDrawer.Component {...bottomDrawer.componentProps} hideCancel={isCancelHidden} />
      </Drawer>
    )
  }
}

const mapToProps = ({bottomDrawer}) => ({bottomDrawer});
const actions = () => ({
  closeBottomDrawer: (state) => {
    return {bottomDrawer: { ...state.bottomDrawer, open: false}}
  }
});

export default connect(mapToProps, actions)(
  withTranslation()(BottomDrawer)
)