import store from "store"
import Cookie from 'js-cookie'
import { BASE_URL } from "../index"
import {getLocale} from "../locales";

const ACCEPT_HEADER = 'application/json,application/hal+json,application/alps+json'

const defaultHeaders = (token) => ({
  Accept: ACCEPT_HEADER,
  "X-XSRF-TOKEN": token,
  "X-LOCALE": getLocale()
})

export const getHeaders = () => {
  const state = store.getState()
  const token = Cookie.get("X-XSRF-TOKEN")
  return state.disableEventPropagation
    ? {
      ...defaultHeaders(token),
      "disable-event-propagation": true
    }
    : defaultHeaders(token)
}

export const setCsrfCookieFromHeaders = (headers) => {
  const token = headers.get("x-xsrf-token")
  if (token) {
    Cookie.set("X-XSRF-TOKEN", token)
  }
}

export const handleMaintenanceMode = (headers) => {
  if (headers.get("maintenance") === "true") {
    if (!window.location.href.endsWith("/ui/maintenance")) {
      window.location.href = `/ui/maintenance`
    }
  }
}

export function handleResponse(response) {
  setCsrfCookieFromHeaders(response.headers);
  handleMaintenanceMode(response.headers);
  return new Promise((resolve, reject) => {
    if (response.status === 204) {
      return resolve();
    }
    let data = response.json();
    return data
      .then(result => {
        if (response.status >= 400) {
          // Session timeout
          if (response.status === 401) {
            let redirection = `${BASE_URL}/login/redirect`;
            if (result.message === "expired") {
              redirection = `${redirection}?error=session-timeout`
            }
            window.location.href = redirection
          }
          return reject(result);
        }
        return resolve(result);
      })
      .catch(() => {
        if (response.status >= 400) {
          return reject(response);
        }
        return resolve(response);
      });
  });
}

export function handleLogin(response) {
  setCsrfCookieFromHeaders(response.headers)
  return new Promise((resolve, reject) => {
    if (response.status < 400) {
      return resolve({"status": "success"})
    } else if (response.status >= 400) {
      return resolve({"status": "failure"})
    } else {
      return reject()
    }
  })
}

export const handleGetUser = (config) => (response) => {
  setCsrfCookieFromHeaders(response.headers);
  handleMaintenanceMode(response.headers);
  return new Promise((resolve, reject) => {
    if (response.status === 204) {
      return resolve();
    }

    let data = response.json();
    return data
      .then(result => {
        if (response.status === 401) {
          if (!config["showLogin"]) {
            window.location = `${BASE_URL}/login/redirect`
          }
          if (result.message === "expired") {
            window.location = `${BASE_URL}/login?error=session-timeout`
          }
        }
        if (response.status !== 200) {
          return reject(result);
        }
        return resolve(result);
      })
      .catch(() => {
        return reject();
      });
  });
}

export function serializeFormdata(data) {
  const formData = new FormData()

  for (const name in data) {
    formData.append(name, data[name])
  }
  return formData
}

export function serialize(obj) {
  const str = []
  for (const p in obj)
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
  return str.join("&")
}
