// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import Select from 'components/fields/Select';
import {object} from "prop-types";
import PrivateRadioFeature from "./fields/PrivateRadioFeature";

let defaultData = {
  "name": null,
  "status": "REGISTERED"
};

let fields = {
  "enodebId": {
    "Component": TextField,
    "name": "enodebId",
    "label": "Radio ID"
  },
  "feature": {
    "Component": PrivateRadioFeature,
    "name": "feature",
    "label": "Feature"
  },
  "status": {
    "Component": (props) => <Select {...props} displayEmpty={false} />,
    "options": [
      {
        "value": "ENABLED",
        "label": "Enabled"
      },
      {
        "value": "DISABLED",
        "label": "Disabled"
      }
    ],
    "name": "status",
    "label": "Status"
  },
};

const PrivateRadioForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={{...defaultData, ...props.defaultData}} />
);

export default PrivateRadioForm
