// @flow

import React from 'react';
import {forEach, map} from 'lodash'
import ValidationError from "../ValidationError";
import FormControl from "@material-ui/core/FormControl";
import AutoCompleteBase from "./AutoCompleteBase";
import {func, object} from "prop-types";

type P = {
  cachedOptions: object,
  options: object,
  multiple: boolean,
  value: string,
  validationError:string,
  name: string,
  onChange: func,
  label: string,
  error: string,
  help?: string,
  className?: string,
  readOnly?: boolean,
  displayEmpty: boolean,
  Component: *
}

type S = {
  cachedOptions: object
}
class AutoCompleteDefault extends React.Component<P, S> {
  state = {
    cachedOptions: {}
  };

  componentWillMount(): void {
    const cachedOptions = {...this.props.cachedOptions};
    forEach(this.props.options, (option) => {
      cachedOptions[option.value] = option.label
    });
    this.setState({cachedOptions})
  }

  componentWillReceiveProps(nextProps: P, nextContext: any): void {
    const cachedOptions = {...this.state.cachedOptions};
    forEach(nextProps.options, (option) => {
      cachedOptions[option.value] = option.label
    });
    this.setState({cachedOptions})
  }

  computeValue = (value: string) => {
    return this.props.multiple ? map(value, val => ({value: val, "label": this.state.cachedOptions[val]}))
    : {value, "label": this.state.cachedOptions[value]}
  };

  render() {
    const {value, validationError, help, className, error, multiple, readOnly, Component, ...props} = this.props;
    return (
      <FormControl margin="normal" className={className} error={error} style={{display: 'flex'}}>
        <AutoCompleteBase
          Component={Component}
          isMulti={multiple}
          {...props}
          value={this.computeValue(value)}
          isDisabled={readOnly}
        />
        <small className="form-text text-muted">{help}</small>
        <ValidationError error={validationError}/>
      </FormControl>
    )
  }
}

export default AutoCompleteDefault;