// @flow

import React from 'react';
import SelectAsync from 'react-select/async';
import AutoCompleteDefault from "components/fields/AutoCompleteDefault";
import {func, object} from "prop-types";

type P = {
  cachedOptions: object,
  options: object,
  multiple?: boolean,
  value: string,
  validationError:string,
  name: string,
  onChange: func,
  label: string,
  error: string,
  help?: string,
  className?: string,
  readOnly?: boolean,
  displayEmpty?: boolean,
}

const AutoCompleteAsync = (props: P) => <AutoCompleteDefault Component={SelectAsync} {...props} />;

export default AutoCompleteAsync;