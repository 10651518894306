// @flow
import React from 'react'
import map from 'lodash/map'
import {getEmbeddedResource, getSelfLink, NestedSelector} from 'utils'
import {withStyles} from '@material-ui/core/styles';
import ValidationError from 'components/ValidationError';
import {array, func, object} from "prop-types";
import AsyncAutoComplete from "components/fields/AutoCompleteAsync";
import AutoComplete from "components/fields/AutoComplete";
import {searchCustomerByName} from "../../../../utils/search";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

type P = {
  values: object,
  errors: object,
  validationErrors: object,
  help: object,
  cachedOptions: object,
  classes: object,
  readOnly: boolean,
  onChange: func,
}

type S = {
  customers: array,
  systemsByCustomer: object,
  loaded: boolean,
  showCustomers: boolean,
}
class CustomerSystemsSelector extends React.Component<P, S> {
  state = {
    customers: [],
    systemsByCustomer: {},
    loaded: false,
    showCustomers: false
  };
  loadOptions = (searchString, callback) => {
    searchCustomerByName(searchString).then((response) => {
      const alreadyHasValues = this.props.values.customer;

      const customers = [];
      const systemsByCustomer = {...this.state.systemsByCustomer};

      map(getEmbeddedResource(response, "customers"), customer => {

        const customerLink = getSelfLink(customer);
        const customerOption = {"label": customer.name, "value": customerLink};

        customers.push(customerOption);
        if (!this.state.systemsByCustomer[customerLink]) {
          systemsByCustomer[customerLink] = map(customer.systems,
            system => ({"label": system.name, "value": getSelfLink(system)})
          )
        }
      });

      const onlyOneCustomer = customers.length === 1;

      const state = {
        customers,
        systemsByCustomer,
        loaded:true,
        showCustomers: !onlyOneCustomer
      };

      if (onlyOneCustomer && !alreadyHasValues) {
        this.onChange(state)({"customer": customers[0].value})
      }
      this.setState(state);
      if (callback) {
        callback(state.customers)
      }
    })
  };
  onChange = state => (newData: object) => {
    const {systemsByCustomer} = state;
    const {onChange, values} = this.props;

    if (NestedSelector.hasChanged(values, newData, "customer")) {
      newData["system"] = NestedSelector.getOptionIfOnlyOption(systemsByCustomer, newData["customer"])
    }
    onChange(newData)
  };
  render() {
    const onChange = this.onChange(this.state);
    const {values, errors, validationErrors, classes, readOnly, cachedOptions, t} = this.props;
    const {customers, systemsByCustomer} = this.state;
    return (
      <React.Fragment>
        <ValidationError error="Editing customer and systems has been disabled due to data integrity not being enforced." />
        <ValidationError error="Systems can be added via the systems module." />
          <AsyncAutoComplete
            value={values.customer}
            error={errors.customer}
            validationError={validationErrors.customer}
            options={customers}
            name="customer"
            label={t('customer')}
            onChange={onChange}
            className={classes.formControl}
            displayEmpty={false}
            readOnly={readOnly}
            cacheOptions={true}
            loadOptions={this.loadOptions}
            defaultOptions={true}
            cachedOptions={cachedOptions.customer}
          />
          <AutoComplete
            value={values.systems}
            error={errors.systems}
            validationError={validationErrors.systems}
            options={systemsByCustomer[values.customer] || []}
            name="systems"
            label={t('customer')}
            onChange={onChange}
            className={classes.formControl}
            multiple
            readOnly={true}
            cachedOptions={cachedOptions.systems}
          />
      </React.Fragment>
    )
  }
}

CustomerSystemsSelector = withTranslation()(CustomerSystemsSelector);
CustomerSystemsSelector = withStyles(styles)(CustomerSystemsSelector);
export default CustomerSystemsSelector