// @flow
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {del} from "utils";
import _get from "lodash/get";
import {func} from "prop-types";
import {withTranslation} from "react-i18next";
import ConfirmButton from "../../ConfirmButton";

type P = {
  value: string,
  label: string,
  onClick: func,
  renderMessage: func,
  endpoint: string,
  resourceName: string
}

class DeleteButton extends React.PureComponent<P> {
  onClick = () => {
    const {endpoint, renderMessage, t, messageNamePrefix} = this.props;
    del(endpoint).then((response) => {
      renderMessage("success")(t(`${messageNamePrefix}DeleteSuccess`));
    }).catch((error) => {
      renderMessage("error")(_get(error, ["message"], t(`${messageNamePrefix}DeleteError`)));
    })
  };
  render() {
    const { t } = this.props;
    return <ConfirmButton Component={MenuItem} onClick={this.onClick}><span>{t('delete')}</span></ConfirmButton>
  }
}

export default withTranslation()(DeleteButton);
