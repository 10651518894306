// @flow
import React, {PureComponent} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation } from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';
import {object} from "prop-types";

const styles = {
    root: {
        width: '100%',
        overflowX: 'scroll',
    },
    tableCell: {
        whiteSpace: "pre"
    }
};

type P = {
    data: object,
    classes: object
}
class ColumnsMappingTable extends PureComponent<P> {
    render() {
        const {data, classes, t} = this.props;
        return (
            <div className={classes.root}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('columnName')}</TableCell>
                            <TableCell>{t('mapping')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(row => (
                            <TableRow key={row.columnName}>
                                <TableCell>{row.columnName}</TableCell>
                                <TableCell>{row.mappingName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }
}
ColumnsMappingTable = withStyles(styles)(ColumnsMappingTable);
export default withTranslation()(ColumnsMappingTable);