import CustomerUsageReportCard from "../../../components/reports/CustomerUsageReportCard";
import CdrProvider1LoaderCard from "../../../components/reports/CdrProvider1LoaderCard";
import CdrProvider1ReportCard from "../../../components/reports/CdrProvider1ReportCard";
import TmoBillingReportCard from "../../../components/reports/TmoBillingReportCard";
import TmoPricingReportCard from "../../../components/reports/TmoPricingReportCard";
import React from "react";

export const CUSTOMER_USAGE_REPORT_FULL = {
    "value": "CustomerUsageReportFull",
    "label": "Full usage report",
};

export const CUSTOMER_DETAILED_USAGE_REPORT = {
    "value": "CustomerDetailedUsageReport",
    "label": "Detailed usage report",
};

export const CUSTOMER_USAGE_BY_IMSI = {
    "value": "CustomerUsageByImsi",
    "label": "Summary usage report - By IMSI",
};

export const CUSTOMER_USAGE_BY_SYSTEM = {
    "value": "CustomerUsageBySystem",
    "label": "Summary usage report - By System",
};

export const CUSTOMER_USAGE_BY_IMSI_SITE_NETWORK = {
    "value": "CustomerUsageByImsiSiteNetwork",
    "label": "Summary usage report",
}

export const CUSTOMER_ACTIVE_SUBSCRIBERS_BY_SITE = {
    "value": "CustomerActiveSubscribersBySite",
    "label": "Active devices by site report",
}

export const CUSTOMER_ACTIVE_SUBSCRIBERS = {
    "value": "CustomerActiveSubscribers",
    "label": "Active devices report",
}

export const TMO_BILLING_REPORT = {
    "value": "TmoBillingReport",
    "label": "T-Mobile billing report",
};

export const TMO_PRICING_REPORT = {
    "value": "TmoPricingReport",
    "label": "T-Mobile pricing report",
};

export const CDR_PROVIDER_1_REPORT = {
    "value": "CdrProvider1Report",
    "label": "CDRs Provider 1 report (T-Mobile)",
};

export const CDR_PROVIDER_1_LOADER = {
    "value": "CdrProvider1Loader",
    "label": "CDRs Provider 1 loader (T-Mobile)",
};

const renderReportForm = (data) => {
    switch(data.type) {
        case CUSTOMER_USAGE_REPORT_FULL.value:
        case CUSTOMER_DETAILED_USAGE_REPORT.value:
        case CUSTOMER_USAGE_BY_IMSI.value:
        case CUSTOMER_USAGE_BY_SYSTEM.value:
        case CUSTOMER_USAGE_BY_IMSI_SITE_NETWORK.value:
        case CUSTOMER_ACTIVE_SUBSCRIBERS.value:
        case CUSTOMER_ACTIVE_SUBSCRIBERS_BY_SITE.value:
            return <CustomerUsageReportCard report={data}/>
        case CDR_PROVIDER_1_LOADER.value:
            return <CdrProvider1LoaderCard report={data}/>
        case CDR_PROVIDER_1_REPORT.value:
            return <CdrProvider1ReportCard report={data}/>
        case TMO_BILLING_REPORT.value:
            return <TmoBillingReportCard report={data}/>
        case TMO_PRICING_REPORT.value:
            return <TmoPricingReportCard report={data}/>
    }
}

export const getReportCard = (data: any) => renderReportForm(data)

export const REPORT_TYPES = [
    CUSTOMER_USAGE_REPORT_FULL,
    CUSTOMER_DETAILED_USAGE_REPORT,
    CUSTOMER_USAGE_BY_IMSI,
    CUSTOMER_USAGE_BY_SYSTEM,
    CUSTOMER_USAGE_BY_IMSI_SITE_NETWORK,
    CUSTOMER_ACTIVE_SUBSCRIBERS_BY_SITE,
    CUSTOMER_ACTIVE_SUBSCRIBERS,
    TMO_BILLING_REPORT,
    TMO_PRICING_REPORT,
    CDR_PROVIDER_1_REPORT,
    CDR_PROVIDER_1_LOADER
];