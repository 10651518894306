import {get, getSelfLink} from "utils";
import {map, get as _get} from "lodash"

export const fetchResourceOptions = (endpoint, resourceName) => () => {
  return get(endpoint).then(response => {
    return map(getEmbeddedResource(response, resourceName),
      val => ({"label": _get(val, "content.name", val.name), "value": getSelfLink(val)})
    )
  })
};

export function getEmbeddedResource(response, resourceName) {
  return _get(response, ["_embedded", resourceName], [])
}