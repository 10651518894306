// @flow
import React from 'react'
import {getSelfLink, isNotObject} from 'utils'
import {map, pull} from 'lodash'
import Grid from '@material-ui/core/Grid';
import ResourceCard from "resource/detail/card/ResourceCard";
import {headings} from "config";
import StatusPane from '../../../components/StatusPane';

const createCards = (data, isRoot) => {
  return map(data, (value, key) => {
    if (!isNotObject(value) && ["extensions", "_links"].indexOf(key) === -1) {
      return <ResourceCard key={key} data={value} model={key} isRoot={isRoot} rootValue={getSelfLink(data)}/>
    }
  })
};

class ResourceCardContainer extends React.PureComponent<*> {
  render() {
    const {className, data, model} = this.props;
    const cards = pull(createCards(data, true), undefined);
    const half_length = Math.ceil(cards.length / 2);
    const column1 = cards.slice(half_length, cards.length);
    const column2 = cards.slice(0, half_length);
    const visualizations = headings[model].visualizations;
    const actions = headings[model].actions;
    return (
      <Grid container direction="row" spacing={16} className={className}>
        <Grid item xs={6}>
          <Grid container direction="column" wrap="nowrap" spacing={16} width="100%">
            {model === 'subs' &&
              <StatusPane className={className} session={data.sessions[0]} state={data.state}/>
            }
            <ResourceCard {...this.props} rootValue={getSelfLink(data)}/>
            {column1}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {actions
            ?  <Grid container direction="column" wrap="nowrap" spacing={16}>
              {
                map(actions, action => {
                  if (action) {
                    return action(data);
                  } else {
                    return null
                  }
                })
              }
            </Grid>
            : null
          }
          <Grid container direction="column" wrap="nowrap" spacing={16}>
            {visualizations && map(visualizations, visualization => {
                if (visualization(data)) {
                  return <Grid item>{visualization(data)}</Grid>
                } else {
                  return null
                }
              }
            )}
            {column2}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ResourceCardContainer
