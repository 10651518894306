// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import FileField from 'components/fields/FileField';
import Select from 'components/fields/Select';
import {object} from "prop-types";
import CustomerSelect from "../accounts/fields/CustomerSelect";
import {FILES_SOURCES} from "./FileSources";

let defaultData = {
    "name": null,
    "mediaType": null,
    "size": null,
    "lastUpdate": null,
    "source": null,
    "description": null,
    "customer": null
};

let fields = {
    "description": {
        "Component": TextField,
        "name": "description",
        "label": "Description"
    },
    "source": {
        "Component": Select,
        "options": FILES_SOURCES,
        "name": "source",
        "label": "Source"
    },
    "customer": {
        "label": "Customer",
        "labelPath": "name",
        "name": "customer",
        "Component": CustomerSelect,
        "relationship": "one",
        "selectDefault": true,
        "displayEmpty": false,
    },
    "file": {
        "Component": FileField,
        "name": "file"
    }
};

const CreateFileForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default CreateFileForm
