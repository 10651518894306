import React, {Component} from 'react';
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";

const errorColor = "#d62728";
const neutralColor = "#7f7f7f";
const warnColor = "#ff7f0e";
const happyColor = "#1f77b4";
const successColor = "#2ca02c";

const styles = theme => ({
  chip: {
    borderRadius: "4px",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "bolder",
    padding: "4px 8px",
    height: "auto"
  },
  label: {
    padding: "0",
  },
  errorChip: {
    borderColor: errorColor,
    backgroundColor: errorColor
  },
  neutralChip: {
    borderColor: neutralColor,
    backgroundColor: neutralColor
  },
  warnChip: {
    borderColor: warnColor,
    backgroundColor: warnColor
  },
  happyChip: {
    borderColor: happyColor,
    backgroundColor: happyColor
  },
  successChip: {
    borderColor: successColor,
    backgroundColor: successColor
  },
});

// This will return disconnected if last seen is greater than MINUTES_UNTIL_CONSIDERED_DISCONNECTED in minutes

const MINUTES_UNTIL_CONSIDERED_DISCONNECTED = 5;

export default withStyles(styles)(class FormatStatus extends Component {

  getClass = (status) => {
    if (status === -1) {
      return this.props.classes.errorChip
    } else {
      return this.props.classes.successChip
    }
  };

  getStatus = (lastSeen) => {
    const siteLastSeen = moment(lastSeen);
    const now = moment();

    if (!lastSeen) {
      return 0
    }
    if (now.diff(siteLastSeen, "minute") > MINUTES_UNTIL_CONSIDERED_DISCONNECTED) {
      return -1
    } else {
      return 1
    }
  };

  getText = (status) => {
    if (status === -1) {
      return "Disconnected"
    } else {
      return "Connected"
    }
  };

  render() {
    const {classes, lastSeen} = this.props;
    const status = this.getStatus(lastSeen);
    if (status === 0) {
      return <div />
    }
    return (
      <Chip className={`${classes.chip} ${this.getClass(status)}`} classes={classes} label={this.getText(status)} variant="outlined"/>
    );
  };
})

