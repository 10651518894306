// @flow
import React from 'react'
import ResourceForm from '../ResourceForm'
import SubscriberStoreSelector from 'resource/forms/profiles/fields/SubscriberStoreSelector';

import TextField from 'components/fields/TextField'
import Extensions from 'components/fields/Extensions';
import {object} from "prop-types";
import {mapNestedFields} from "utils";

let defaultData = {
  "name": null,
}

let fields = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "defaultApn": {
    "Component": TextField,
    "name": "defaultApn",
    "label": "Default APN"
  },
  "uploadBitRate": {
    "Component": TextField,
    "name": "uploadBitRate",
    "label": "Upload Bit Rate"
  },
  "downloadBitRate": {
    "Component": TextField,
    "name": "downloadBitRate",
    "label": "Download Bit Rate"
  },
  "extensions": {
    "Component": Extensions,
  },
  "customer": {
    "relationship": "one",
    "labelPath": "name",
  },
  "feature": {
    "updatable": false,
    "Component": props =>
      <SubscriberStoreSelector
        {...mapNestedFields(props.nestedFields)}
        onChange={props.onChange}
        readOnly={props.readOnly}
      />,
    "label": "Feature",
    "labelPath": "system.name",
    "relationship": "one",
    "nestedFields": [
      "feature",
      "customer"
    ],
  },

}

const ProfileForm = (props: object) => (
  <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
)

export default ProfileForm
