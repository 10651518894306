// @flow
import React from 'react'
import {BASE_URL, get} from "utils";
import moment from 'moment'
import DonutChart from "components/charts/DonutChart";
import ChartContainer from "components/home/ChartContainer";
import Grid from "@material-ui/core/Grid";
import Tooltip from "./Tooltip";
import {object} from "prop-types";
import Select from "components/fields/Select";
import GridLoader from "../GridLoader";
import { withTranslation } from 'react-i18next'

type P = {
  id: string,
  filterKey?: string,
  filterValue?: string,
  groupByOptions: object,
  defaultGroupBy?: string,
}
type S = {
  data: ?object,
  key: ?moment,
  tooltip: ?object,
  tooltipVisible: boolean,
  groupBy: string,
  error: boolean
}

class DevicePieChart extends React.Component<P, S> {

  constructor(props: P) {
    super(props);
    this.state = {
      data: null,
      key: null,
      tooltip: null,
      tooltipVisible: false,
      groupBy: props.defaultGroupBy || "system",
      error: false
    };
  }

  componentDidMount() {
    this.loadReport(this.state.groupBy)
  }

  onChange = (data: *) => {
    this.setState({...data})
  };

  updateChart = (data: *) => {
    this.onChange(data);
    this.loadReport(data["groupBy"]);
  };

  loadReport(groupBy: string) {
    const {filterKey, filterValue} = this.props;

    let endpoint = `${BASE_URL}/v1/subs/command/getSubscriberReport?groupBy=${groupBy}`;
    if (filterKey && filterValue) {
      endpoint = `${endpoint}&filterKey=${filterKey}&filterValue=${filterValue}`
    }
    this.setState({key: null})
    get(endpoint).then((response) => {
        this.setState({key: moment(), data: response, error: false})
      }
    ).catch(() => {
      this.setState({"error": true})
    });
  }

  getTooltip = () => {
    const { t } = this.props;
    const {tooltipVisible, tooltip} = this.state
    if (!tooltip) {
      return ""
    }
    const opacity = tooltipVisible ? .9 : 0;
    return (
      <Tooltip left={tooltip.x} top={tooltip.y} opacity={opacity}>
        <span>{t('name')}: {tooltip.data.label}</span><br/>
        <span>{t('count')}: {tooltip.data.count}</span><br/>
        <span>{t('percentage')}: {tooltip.data.percentage}%</span><br/>
      </Tooltip>
    )
  };

  render() {
    const {key, data, groupBy, error} = this.state;
    const {id, groupByOptions, t} = this.props;
    return (
      <ChartContainer>
        <Grid container justify="space-between">
          <Grid item component="h4">{t('subscribersBy')} {t(groupBy)}</Grid>
          <Grid item>
            <Select
              options={groupByOptions} onChange={this.updateChart} name="groupBy" label={t('groupBy')} value={groupBy}
              displayEmpty={false}/>

          </Grid>
        </Grid>
        <div style={{"position": "relative"}}>
          {error ? <p>{t('chartLoadError')}</p> :
            key ? <DonutChart id={id} key={key} data={data} onChange={this.onChange}/>
              : <GridLoader/>}
          {this.getTooltip()}
        </div>

      </ChartContainer>
    )
  }
}

export default withTranslation()(DevicePieChart)
