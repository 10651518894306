import {DataTypeProvider} from "@devexpress/dx-react-grid";
import RowActionMenu from "../../components/row/actions/RowActionMenu";
import React from "react";
import {object} from "prop-types";
import {rowActions} from "../../bulkActions";
import map from 'lodash/map'

const RowActionFormatter = (resourceName) => (props: object) => {
  const row = {feature: props.value, ...props.row}
  return <RowActionMenu key={row.feature} row={row} value={{value: props.value, actions: rowActions[resourceName]}} resourceName={resourceName}  />
}


// This makes it so we don't keep recreating the component
// It will rerender otherwise.
const resourceToComponents = {}
map(rowActions, (value, key) => {
  resourceToComponents[key] = RowActionFormatter(key)
})

export default (props: object) => <DataTypeProvider formatterComponent={resourceToComponents[props.resourceName]} {...props} />