import React from 'react'
import { Link } from 'react-router-dom'
import DefaultChip from 'components/DefaultChip'
import { cleanSelfLink, getSelfLink } from 'utils'
import { withTranslation } from "react-i18next"

class ResourceCardChips extends React.PureComponent<P> {
    determineLink(value, model) {
        // TODO break this out to the config and follow existing pattern under something like "chip algo" not prematurely optimising this for now
        if (!getSelfLink(value)) {
            return null
        }
        return cleanSelfLink(getSelfLink(value), 2)
    }
    render() {
        const { model , t } = this.props;
        let { data } = this.props;
        if (!Array.isArray(data)) {
            data = [data]
        }
        if (data.length === 0) {
            return null
        }
        return (
            <div className="form-group p-3">
                {data.map( value => {
                    const link = this.determineLink(value, model);
                    return link ?
                        <DefaultChip
                            key={link}
                            label={t(value.name)}
                            component={Link}
                            to={link}
                        />
                        : <DefaultChip
                            key={link}
                            label={t(value.name)}
                        />
                })}
            </div>
          )
    }
}

ResourceCardChips = withTranslation()(ResourceCardChips)
export default ResourceCardChips