// @flow
import React from "react";
import {BASE_URL, post} from "utils";
import get from "lodash/get"
import {func} from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import {withTranslation} from "react-i18next";

type MessageType = "success" | "error"

type P = {
  endpoint: string,
  errorMessage?: string,
  message: string,
  renderMessage: func<MessageType>,
  onClick: func,
  label: string,
  value: string
}

class RecreateGateway extends React.PureComponent<P> {
  onClick = () => {
    const {renderMessage, t, value} = this.props;
    const endpoint = `${BASE_URL}/v1/gateways/command/recreate?gateway=${value}`;
    post(endpoint, {}).then((response) => {
      renderMessage("success")(t('gatewayRecreateSuccess'));
    }).catch((error) => {
      renderMessage("error")(get(error, ["message"], t('gatewayRecreateError')));
    })
  };
  render() {
    const {t} = this.props;
    return <MenuItem onClick={this.onClick}><span>{t('recreateGateway')}</span></MenuItem>
  }
}


export default withTranslation()(RecreateGateway);
