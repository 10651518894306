// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import {post, BASE_URL} from "utils";
import _get from 'lodash/get'
import {func} from "prop-types";
import {withTranslation} from "react-i18next";

type MessageType = "success" | "error"

type P = {
  errorMessage: string,
  message: string,
  renderMessage: func<MessageType>,
  onClick: func,
  label: string,
  value: string
}

class AddApiTokenSiteFeature extends React.PureComponent<P> {
  onClick = () => {
    const {renderMessage, onClick, value} = this.props;
    const featureData ={
      "site": value,
      "type": "api_token_site_feature"
    };
    post(`${BASE_URL}/v1/site-feature-api-tokens`, featureData).then((response) => {
      renderMessage("success")(this.props.t("apiTokenFeatureSuccess"));
      onClick()
    }).catch((error) => {
      renderMessage("error")(_get(error, ["details", "0","message"], this.props.t("apiTokenFeatureError")));
      onClick()
    })
  };
  render() {
    return <MenuItem onClick={this.onClick}><span>{this.props.t("addApiTokenFeature")}</span></MenuItem>
  }
}

export default withTranslation()(AddApiTokenSiteFeature)
