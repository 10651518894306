// @flow
import React from 'react'
import ResourceForm from '../ResourceForm'

import TextField from 'components/fields/TextField'
import Extensions from 'components/fields/Extensions';
import {object} from "prop-types";
import Select from "components/fields/Select";
import {REPORT_TYPES} from "./ReportTypes";

let defaultData = {
    "name": null,
    "description": null,
    "type": null,
    "jobName": null,
    "uri": null,
}

let fields = {
    "name": {
        "Component": TextField,
        "name": "name",
        "label": "Name"
    },
    "description": {
        "Component": TextField,
        "name": "description",
        "label": "Description"
    },
    "type": {
        "Component": Select,
        "options": REPORT_TYPES,
        "name": "type",
        "label": "Type"
    },
    "jobName": {
        "Component": TextField,
        "name": "jobName",
        "label": "Job name"
    },
    "uri": {
        "Component": TextField,
        "name": "uri",
        "label": "URI"
    },
    "extensions": {
        "Component": Extensions,
    }
}

const ReportForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
)

export default ReportForm
