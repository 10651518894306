// @flow
import React, {Component} from "react";
import {Route} from "react-router-dom";
import {connect} from "redux-zero/react";
import { withTranslation } from "react-i18next";

const mapToProps = ({user}) => (
  {
    role: user.role || "",
    permissions: user.permissions
  });


class UserAwareRoute extends Component {
  static hasAccess(permissions, resourceName: string, viewName: string): boolean {
    return permissions[resourceName].includes(viewName)
  }

  render() {
    const {Component, name, role, t, permissions, ...routeProps} = this.props;
    return (
      <Route {...routeProps} render={
        (props) => {
          return UserAwareRoute.hasAccess(permissions, props.match.params.resourceName, name)
            ? <Component user={role} permissions={permissions} {...props} />
            : <p>{t('youDoNotHaveAccess')}</p>
        }}
      />
    )
  }
}

UserAwareRoute = connect(mapToProps)(UserAwareRoute);
UserAwareRoute = withTranslation()(UserAwareRoute);

export default UserAwareRoute;