import React from "react"
import { getPolling } from "../utils/fetch/get"

const withPolling = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      pollingId: null,
      data: null,
      pollingError: null,
    }

    POLLING_INTERVAL = 5000 // milliseconds

    poll = url => {
      getPolling(url)
        .then(data => {
          if (this.state.pollingId != null) {
            this.setState({data, pollingError: null})
          }
        })
        .catch(err => {
          console.error(err)
          this.setState({pollingError: err})
        })
    }

    startPolling = url => {
      if (this.state.pollingId) clearInterval(this.state.pollingId)
      this.poll(url)
      const handle = setInterval(this.poll.bind(null, url), this.POLLING_INTERVAL)
      this.setState({pollingId: handle})
    }

    stopPolling = (clearData = false) => {
      clearInterval(this.state.pollingId)
      this.setState({pollingId: null, data: clearData ? null : this.state.data})
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props}
          startPolling={this.startPolling}
          stopPolling={this.stopPolling}
          isCurrentlyPolling={Boolean(this.state.pollingId)}
          pollingError={this.state.pollingError}
          data={this.state.data}
        />
      )
    }
  }
}

export default withPolling
