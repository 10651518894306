// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import {object} from "prop-types";
import Select from "../../../components/fields/Select";
import CustomerSelect from "../accounts/fields/CustomerSelect";
import {FILES_SOURCES} from "./FileSources";

let defaultData = {
    "name": null,
    "mediaType": null,
    "size": null,
    "lastUpdate": null,
    "source": null,
    "description": null,
    "customer": null
};

let fields = {
    "name": {
        "Component": TextField,
        "name": "name",
        "label": "Name"
    },
    "mediaType": {
        "Component": TextField,
        "name": "mediaType",
        "label": "Media type",
        "updatable": false
    },
    "size": {
        "Component": TextField,
        "name": "size",
        "label": "Size (bytes)",
        "updatable": false
    },
    "lastUpdate": {
        "Component": TextField,
        "name": "lastUpdate",
        "label": "Last update",
        "updatable": false,
        "displayEmpty": false
    },
    "source": {
        "Component": Select,
        "options": FILES_SOURCES,
        "name": "source",
        "label": "Source"
    },
    "description": {
        "Component": TextField,
        "name": "description",
        "label": "Description"
    },
    "customer": {
        "label": "Customer",
        "labelPath": "name",
        "name": "customer",
        "Component": CustomerSelect,
        "relationship": "one",
        "selectDefault": true,
        "displayEmpty": false,
    }
};

const EditFileForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default EditFileForm
