import React, {Component} from 'react';

import {emphasize} from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import CancelIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import map from "lodash/map";


type State = { isOpen: boolean, value: Object };

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    width: "100%"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isselected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const dropdownCloseStyles = {
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1,
};

const dropdownOpenStyles = {
  backgroundColor: 'white',
  borderRadius: 4,
  marginTop: 8,
  position: 'absolute',
  width: '100%',
  zIndex: 2,
};

const Dropdown = ({children, isOpen, target, onClose}) => (
  <div style={{position: 'relative'}}>
    {target}
    {isOpen ? <div style={dropdownOpenStyles}>{children}</div> : null}
    {isOpen ? <div style={dropdownCloseStyles} onClick={onClose}/> : null}
  </div>
);


const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

class AutoCompletePopup extends Component<*, State> {
  state = {isOpen: false};
  toggleOpen = () => {
    this.setState(state => ({isOpen: !state.isOpen}));
  };
  handleChange = value => {
    if (this.props.isMulti) {
      this.props.onChange({[this.props.name]: map(value, v => v.value)});
    } else {
      this.props.onChange({[this.props.name]: value.value});
      this.setState(state => ({isOpen: !state.isOpen}));
    }
  };
  computeValue = (value) => {
    return this.props.isMulti
      ? value ? map(value, v => v.label) : ""
      : value ? value.label || "" : ""
  }

  render() {
    const {isOpen} = this.state;
    const {Component, classes, theme, label, value, ...props} = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <TextField
              label={label}
              value={this.computeValue(value)}
              onClick={this.toggleOpen}
              isselected={isOpen.toString()}
          />
        }
      >
        <Component
          autoFocus
          backspaceRemovesValue={false}
          components={components}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
          classes={classes}
          {...props}
          onChange={this.handleChange}
        />
      </Dropdown>
    );
  }
}

export default withStyles(styles, {withTheme: true})(AutoCompletePopup)


