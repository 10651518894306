// @flow
import React from 'react'
import Divider from "@material-ui/core/Divider";
import AceEditor from "react-ace";

import 'brace/mode/clojure';
import 'brace/mode/groovy';
import 'brace/theme/github';
import {func} from "prop-types";
import ValidationError from "components/ValidationError";

type P = {
  name: string,
  value: string,
  validationError: string,
  mode: string,
  onChange: func
};

class CodeEditor extends React.PureComponent<P> {
  onChange = (name: string) => (value: string) => {
    this.props.onChange({[name]: value})
  };
  getErrorMessages = () => {
    const {validationError} = this.props;
    if(validationError){
      console.log(validationError);
      return [{ row: 0, column: 2, type: 'error', text: validationError}];
    }
  };
  render() {
    const {name, value, mode, validationError} = this.props;
    return (
      <React.Fragment>
        <Divider/>
        <AceEditor
          mode={mode}
          theme={"github"}
          onChange={this.onChange(name)}
          annotations={this.getErrorMessages()}
          value={value || ""}
          name={name}
          width="150%"
          height="70vh"
          editorProps={{ $blockScrolling: true }}
        />
        <Divider/>
        <ValidationError error={validationError} />
      </React.Fragment>
    )
  }
}

export default CodeEditor