// @flow
import React, {Component} from 'react';
import {BrowserRouter, withRouter} from 'react-router-dom'
import {connect} from "redux-zero/react";

import {get, getUser, getCors} from "utils/fetch";
import { withTranslation } from "react-i18next"

import {sideNav} from 'config.js'
import SideNavigation from 'SideNavigation'

import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import Page from 'components/Page';
import LoggedIn from "./containers/LoggedIn";
import SelectLocale from "./containers/SelectLocale"

import * as Sentry from '@sentry/browser';
import ErrorPage from "./components/ErrorPage";
import {BASE_URL, getLogoUrl} from "./utils";
import {loadAccessRules} from "config";
const drawerWidth = 170;
const appBarHeight = 64;
const spacingUnit = 12;


const mapToProps = ({
                      config,
                      disableEventPropagation,
                      user,
                      ...props
                    }) => ({
  config,
  disableEventPropagation,
  user: user || {}
});

const actions = (store) => ({
  setConfig: (state, config) => {
    return {...state, config}
  },
  setUser: (state, user) => {
    loadAccessRules(user);
    return {...state, user}
  },
  setAttemptedLogin: (state) => {
    return {attemptedLogin: true}
  }
});

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    backgroundColor: '#fff',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "appBar--adminMode": {
    position: "fixed",
    backgroundColor: '#F00',
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: theme.spacing.unit
  },
  toolbar: {
    padding: "0px",
    display: "flex",
    justifyContent: "space-between"
  },
  page: {
    marginRight: spacingUnit,
    marginTop: appBarHeight + spacingUnit,
    paddingLeft: spacingUnit,
    flexGrow: 1,
    maxWidth: `calc(100% - ${theme.spacing.unit * 9}px)`,
    overflow: "hidden",
    paddingRight: theme.spacing.unit / 4,
    paddingBottom: theme.spacing.unit * 24
  },
  menuButton: {
    marginLeft: 12,
  },
  menuButtonHidden: {
    display: 'none',
  },
  logo: {
    width: '140px'
  },
  menuItem: {
    marginRight: `24px`,
    verticalAlign: "top",
    color: "#212529",
  }
});

type S = {
  open: boolean,
  error: ?Error,
};


class AppContainer extends Component<*, S> {
  state = {
    open: true,
    error: null
  };

  setVisibilityToggle(config) {
    const component = this
    document.addEventListener("visibilitychange", function () {
      // If you click this tab
      if (!document.hidden) {
        // If we are checking for single sign off
        if (config["statusUrl"]) {
          // get endpoint to see if we still have a session
          getCors(config["statusUrl"])
            .then(response => {
              // if we don't have a session log off
              if (response["activeSessionFound"] === false) {
                window.location = `${BASE_URL}/logout`
              }
            })
        }

        // Get user and update user in case the user has been updated on another tab
        getUser(`${BASE_URL}/v1/accounts/command/currentUser?projection=withRole`, config).then((response) => {
          if (response !== undefined) {
            response.username = response.id;
            component.props.setUser(response)
          }
        })
      }
    });
  }

  componentWillMount(): void {
    // We need this fetch the original CSRF token
    get(`${BASE_URL}/v1/accounts/command/token`).catch(response => {

      if (response.status === 503) {
        this.props.setAttemptedLogin()
      }
    })
    get(`${BASE_URL}/config`).then(response => {
      this.props.setConfig(response);
      this.setVisibilityToggle(response)
    })

  }

  componentWillReceiveProps(nextProps: Props, nextContext: *): * {
    if (nextProps.location.pathname !== this.props.location.pathname){
      this.setState({"error": null})
    }
  }

  toggleDrawer = () => {
    this.setState({open: !this.state.open})
  };

  componentDidCatch(error, errorInfo) {
    this.setState({error});
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const {classes, disableEventPropagation, user, config, t} = this.props;
    const {error, open} = this.state;
    const showProfileLink = config ? config["showProfileLink"] : null
    return (
      <div className={classes.root}>
        <CssBaseline/>
        <AppBar
          position="absolute"
          className={classNames(
            disableEventPropagation ? classes["appBar--adminMode"] : classes.appBar,
            open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <div>
              <IconButton
                aria-label="Open drawer"
                onClick={this.toggleDrawer}
                className={classNames(
                  classes.menuButton,
                  open && classes.menuButtonHidden,
                )}
              >
              <MenuIcon/>
              </IconButton>
              {!disableEventPropagation
                ? <img src={getLogoUrl()} className={classes.logo} alt="Expeto"/>
                : <h2>{t('inDevMode')}</h2>}
            </div>
            <div>
              {user.role && <a href={`${BASE_URL}/docs/index.html`} className={classes.menuItem}>{t('documentation')}</a>}
              {user.role && <SelectLocale/> }
              <LoggedIn username={user.emailAddress} disableEventPropagation={disableEventPropagation} showProfileLink={showProfileLink} role={user.role}/>
            </div>
          </Toolbar>
        </AppBar>
        <SideNavigation sideNav={sideNav} open={open} toggleDrawer={this.toggleDrawer} role={user.role}/>
        {!error ? <Page className={classes.page} role={user.role}/>
          : <ErrorPage className={classes.page}
                       errorMessage={t("errorNowReload")}/>
        }
      </div>
    );
  }
}

AppContainer = withTranslation()(connect(mapToProps, actions)(withStyles(styles)(AppContainer)));
AppContainer = withRouter(AppContainer)
const App = () => (
  <BrowserRouter basename="/ui">
    <AppContainer/>
  </BrowserRouter>
);

export default App;
