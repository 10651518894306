// @flow
import React from 'react'
import {BASE_URL} from 'utils'
const styles = {"width": "125px", "float": "right", "padding": "5px 0 0 0", "marginBottom": "-10px"};

const PoweredBy = () => (
    <img src={`${BASE_URL}/images/rogers/expeto-logo-powered-by.png`} style={styles} alt="Powered by Expeto"/>
);


export default PoweredBy