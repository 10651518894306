import {DateRangePicker} from "react-dates";
import React from "react";
import {array, func} from "prop-types";
import moment from "moment";

export const TODAY = {
    start: moment().startOf("day"),
    end: moment().endOf("day"),
    text: "Today"
};
export const YESTERDAY = {
    start: moment().subtract(1, "day").startOf("day"),
    end: moment().subtract(1, "day").endOf("day"),
    text: "Yesterday"
};
export const THIS_WEEK = {
    start: moment().startOf("week"),
    end: moment().endOf("week"),
    text: "This week"
};
export const LAST_WEEK = {
    start: moment().subtract(1, "week").startOf("week"),
    end: moment().subtract(1, "week").endOf("week"),
    text: "Last week"
};
export const THIS_MONTH = {
    start: moment().startOf("month"),
    end: moment().endOf("month"),
    text: "This month"
};
export const LAST_MONTH = {
    start: moment().subtract(1, "month").startOf("month"),
    end: moment().subtract(1, "month").endOf("month"),
    text: "Last month"
};
export const THIS_YEAR = {
    start: moment().startOf("year"),
    end: moment().endOf("year"),
    text: "This year"
};
export const LAST_YEAR = {
    start: moment().subtract(1, "year").startOf("year"),
    end: moment().subtract(1, "year").endOf("year"),
    text: "Last year"
};

type P = {
    startDate: date,
    startDateId: string,
    endDate: date,
    endDateId: string,
    onChange: func,
    presets: array<object>
}

class DateRangeSelector extends React.PureComponent<P> {
    state = {
        focusedInput: null
    };

    renderDatePresets(presets, onChange) {
        return (
            <div>
                {presets.map(({text, start, end}) => {
                    return (
                        <button
                            key={text}
                            type="button"
                            onClick={() => {
                                onChange({ startDate: start, endDate: end });
                                this.setState({focusedInput: null});
                            }}
                        >
                            {text}
                        </button>
                    );
                })}
            </div>
        );
    }

    render() {
        const {startDate, startDateId, endDate, endDateId, presets, onChange} = this.props;
        const {focusedInput} = this.state;
        return (
            <DateRangePicker
                isOutsideRange={() => false}
                startDate={startDate}
                startDateId={startDateId}
                endDate={endDate}
                endDateId={endDateId}
                onDatesChange={onChange}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => this.setState({focusedInput})}
                renderCalendarInfo={() => this.renderDatePresets(presets, onChange)}
                showClearDates
                reopenPickerOnClearDates
                minimumNights={0}
            />
        )
    }
}

export default DateRangeSelector