import React from 'react'

import {get} from 'utils'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});


// if we have passed in a site link get the corresponding feature.
class KeysetMappingFeature extends React.Component {
  componentWillMount(): void {
    if (this.props.value.includes("sites")) {
      get(`${this.props.value}/features`).then(response => {
        this.props.onChange({"feature":response["_embedded"]["site-feature-keyset-mappings"][0]._links.self.href})
      })
    }
  }
  render() {
    return null
  }
}

KeysetMappingFeature = withStyles(styles)(KeysetMappingFeature);
export default KeysetMappingFeature