// @flow
import React from 'react';
import map from 'lodash/map';
import {getEmbeddedResource, getSelfLink, NestedSelector} from 'utils';
import {withStyles} from '@material-ui/core/styles';
import {array, func} from "prop-types";
import AsyncAutoComplete from "../fields/AutoCompleteAsync";
import {searchCustomerByName} from "../../utils/search";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
  nestedControl: {
    marginLeft: theme.spacing.unit * 3,
  },
});

type P = {
  name: string,
  onChange: func,
  readOnly: boolean,
  label: string,
  value: string,
  valueLabel: string,
  error: string,
  validationError: string,
  help: string
}
type S = {
  customers: array,
  showCustomers: boolean,
  searchString: string
}

class CustomerSelector extends React.Component<P, S> {
  state = {
    customers: [],
    showCustomers: false,
    searchString: "",
  };
  loadOptions = (searchString, callback) => {
    const {name} = this.props;

    searchCustomerByName(searchString).then((response) => {
      const customers = map(getEmbeddedResource(response, "customers"), customer => ({
        "label": customer.name,
        "value": getSelfLink(customer)
      }));

      this.setState({"showCustomers": true, customers}, () => {
        const oneAndOnlyOption = NestedSelector.getOptionIfOnlyOption(customers);
        if (oneAndOnlyOption) {
          this.onChange({[name]: oneAndOnlyOption});
        }
      });
      callback(customers)
    })
  };
  onChange = (newData) => {
    const {onChange, readOnly} = this.props;
    if (readOnly) {
      return
    }
    onChange(newData)
  };
  render() {
    const {label, value, valueLabel, error, validationError, help, readOnly, name, t} = this.props;
    const {customers} = this.state;
    return (
      <div>
        <AsyncAutoComplete
          value={value}
          error={error}
          validationError={validationError}
          help={help}
          options={customers}
          name={name}
          label={t(label)}
          onChange={this.onChange}
          readOnly={readOnly}
          displayEmpty={false}
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions={true}
          cachedOptions={{[value]: valueLabel}}
        />
      </div>
    )
  }
}

CustomerSelector = withTranslation()(CustomerSelector);
CustomerSelector = withStyles(styles)(CustomerSelector);
export default CustomerSelector