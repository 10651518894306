// @flow
import React from 'react'
import ConfirmButton from 'components/ConfirmButton';
import {BASE_URL, post} from 'utils';
import SubscriberStoreSelector from 'resource/forms/subscribers/fields/SubscriberStoreSelector';
import TableAction from 'components/table/actions/TableAction';
import Select from "components/fields/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import type {TableActionProps} from "types";
import {object} from "prop-types";
import Extensions from "../../fields/Extensions";
import { withTranslation } from 'react-i18next';

const SUBSCRIBER_STATUS_OPTIONS = [
  {
    "value": "Active",
    "label": "Active"
  },
  {
    "value": "Inactive",
    "label": "Inactive"
  }
];

const styles = theme => ({
  formControl: {
    display: 'flex',
  },
});

type S = {
  values: object,
  validationErrors: object,
  errors: object
}
class BulkUpdateSubscriberStore extends React.Component<TableActionProps, S> {
  state = {
    values: {
      customer: null,
      feature: null,
      groups: null,
      profile: null,
      status: null,
      extensions: {},
    },
    validationErrors: {},
    errors: {}
  };
  onClick = () => {
    const endpoint = `${BASE_URL}/v1/subs/command/bulkUpdate`;
    let values = {
      subscriberStoreFeature: this.state.values.feature,
      subscriberGroups: this.state.values.groups,
      subscriberProfile: this.state.values.profile,
      subscribers:this.props.selectedItems,
      status: this.state.values.status,
      extensions: this.state.values.extensions
    };
    post(endpoint, values).then((response) => {
      this.props.renderMessage("success")("Subscribers Updated");
      this.props.changeSelection([]);
      this.props.closeDrawer()

    }).catch((error) => {
      this.props.renderMessage("error")(error.message)
    })
  };
  onChange = (newData) => {
    this.setState((state) => ({
        ...state, values: {...state.values, ...newData}
      }))
  };
  buildHelpObject() {
    const t = this.props.t
    const help = {};
    help.groups = t('optional')
    help.customer = t('customerBulkFieldSubOwner')
    help.system = help.profile = t('required')

    return help
  }
  render() {
    const {values} = this.state;
    const {togglePanel, name, classes, selectedItems, t, ...props} = this.props;
    return (
      <TableAction togglePanel={togglePanel} name={name} {...props} label={t("bulkUpdate")}>
        <div>
          <SubscriberStoreSelector values={values} errors={{}} validationErrors={{}} help={this.buildHelpObject()} cachedOptions={{}} onChange={this.onChange}/>
          <Extensions onChange={this.onChange} value={values.extensions} name="extensions"/>
          <Select
            label={t('status')}
            name="status"
            options={SUBSCRIBER_STATUS_OPTIONS}
            onChange={this.onChange}
            value={values.status}
            displayEmpty={true}
            className={classes.formControl}
            help={t('optional')}
          />
          <ConfirmButton
              variant="contained"
              color="primary"
              onClick={this.onClick}
              confirmMessage={t('update') + ' ' + selectedItems.length + ' ' + t('subscribersQuestion')}
          >{t('submit')}</ConfirmButton>
          <Button variant="outlined" color="secondary" onClick={togglePanel(name)}>{t('cancel')}</Button>
        </div>
      </TableAction>
    )
  }
}
BulkUpdateSubscriberStore = withTranslation()(withStyles(styles)(BulkUpdateSubscriberStore));
export default BulkUpdateSubscriberStore
