// @flow
import  React from 'react'
import TextInput from 'components/fields/TextField';
import CodeEditor from 'components/fields/CodeEditor';
import ResourceForm from 'resource/forms/ResourceForm';
import {object} from "prop-types";


const fields = {
  "key":{
    "Component": TextInput,
    "name": "key",
    "label": "Key"
  },
  "value": {
    "Component": CodeEditor,
    "mode": "clojure",
    "name": "value",
    "label": "Value"
  },
};

const defaultData = {
  key:"",
  value:"",
};

const ConfigForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default ConfigForm