// @flow
import React, {PureComponent} from 'react';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';
import {array, object} from "prop-types";
import {map} from "lodash"

const styles = theme => ({
  panel: {
    width: "100%",
  },
  panelSummary: {
  },
  panelDetails: {
    padding: 0
  },
  panelTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "0 !important" // We want out button to align right with the padding
  }
});

type P = {
  classes: object,
  title: string,
  content: object,
  actions: array
}
class ResourceCardExpansionPanel extends PureComponent<P> {
  render() {
    const {classes, title, content, actions, t} = this.props;
    return (
      <ExpansionPanel className={classes.panel} defaultExpanded={true}>
        <ExpansionPanelSummary>
          <div className={classes.panelTitle}>
            <h4 className="card-title">{t(title)}</h4>
            <div>
              {map(actions, action => action)}
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          { content }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

ResourceCardExpansionPanel = withStyles(styles)(ResourceCardExpansionPanel)
ResourceCardExpansionPanel = withTranslation()(ResourceCardExpansionPanel)
export default ResourceCardExpansionPanel;
