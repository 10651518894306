import React from "react";
import ApiTokenForm from "resource/forms/apiTokens/ApiTokenForm";
import {FormInner} from "../../form/FormInner";
import {connect} from "redux-zero/react";
import bottomDrawerAction from "../bottomDrawerAction";
import {withTranslation} from "react-i18next";
import OpenBottomDrawerButton from "../../row/actions/OpenBottomDrawerButton";


let ApiTokenSuccess = props => (
  <React.Fragment>
    <h2>{props.t("apiTokenSuccess")}</h2>
    <h3>{props.t("apiTokenOneTimeMessage")}</h3>
    <code>{props.token}</code>
  </React.Fragment>
)

ApiTokenSuccess = withTranslation()(ApiTokenSuccess)

class BottomDrawer extends React.Component {
  state = {
    token: null
  }
  onSuccess = (response) => {
    this.setState({token: response})
  }
  render() {
    const props = this.props
    const state = this.state
    return (
      <FormInner>
        {state.token ? <ApiTokenSuccess token={state.token}/>
                     : <ApiTokenForm {...props}  onSuccess={this.onSuccess}  />}
      </FormInner>
    )
  }
}

export default connect(null, bottomDrawerAction(BottomDrawer))(OpenBottomDrawerButton)
