import React from "react";
import { connect } from "redux-zero/react";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames'
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import Icon from "@material-ui/core/es/Icon/Icon";
import { withTranslation } from "react-i18next"

const styles = theme => ({
  username: {
    paddingRight: `${theme.spacing.unit*2}px`,
    verticalAlign: "top"
  },
  arrow: {
    verticalAlign: "top"
  },
  usernameDefault: {
    color: "#212529",
  },
  usernameDanger: {
    color: "#fff"
  }
});
const actions = store => ({
  toggleEventPropagation: state => ({ disableEventPropagation: !state.disableEventPropagation }),
});

class LoggedIn extends React.Component {
  state = {
    anchorEl: null,
  };
  openMenu = (event: *) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClick = (callback) => () => {
    callback();
    this.handleClose()
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { role, username, disableEventPropagation, toggleEventPropagation, classes, t, showProfileLink } = this.props;
    const { anchorEl } = this.state;
    return (
      <React.Fragment>
        {username &&
          <React.Fragment>
            <span
              id="profile-menu-link"
              onClick={this.openMenu}
              className={classNames(
                classes.username,
                disableEventPropagation ? classes.usernameDanger : classes.usernameDefault
            )}>
              {username}
              <span className={classes.arrow}>
                <Icon>arrow_drop_down</Icon>
              </span>
            </span>
          </React.Fragment>
        }
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {showProfileLink && <Link id="profile-details-link" to="/profile" target="_blank"><MenuItem onClick={this.handleClose}>
            {t('profile')}
          </MenuItem></Link>}
          {role === "SUPERUSER" &&
            <MenuItem
              onClick={this.handleClick(toggleEventPropagation)}
            >
              <span>{t('toggleDeveloperMode')}</span>
            </MenuItem>}
          <Link id="logout-link" to="/logout"><MenuItem onClick={this.handleClose}>
            {t('logout')}
          </MenuItem></Link>
        </Menu>
      </React.Fragment>
    )
  }
}

LoggedIn = withStyles(styles)(LoggedIn)
LoggedIn = withTranslation()(LoggedIn)
LoggedIn = connect(null, actions)(LoggedIn)

export default LoggedIn
