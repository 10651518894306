// @flow
import React from 'react'
import {BASE_URL, get, getEmbeddedResource, isNotObject} from 'utils';
import {difference, map} from 'lodash';
import {Grid, PagingPanel, SearchPanel, Table, TableHeaderRow, TableRowDetail, Toolbar} from '@devexpress/dx-react-grid-material-ui';
import {CustomPaging, PagingState, RowDetailState, SearchState} from '@devexpress/dx-react-grid';
import Card from '@material-ui/core/Card';
import {array, object} from 'prop-types';
import GridLoader from "../../GridLoader";


const sessionFields = {
  "PGW": [
    "ip4Address", "ip6Address", "upload", "download", "apnNode", "enodebId", "rat", "causeCode", "bearerId", "usageState",
    "ingress", "mnc", "mcc", "secondaryImsi"
  ],
  "HSS": [
    "destGt", "lac", "hostRealm", "lastSeen", "state", "tac", "ingress", "mnc", "mcc", "secondaryImsi"
  ]
};

sessionFields["UPF"] = sessionFields["PGW"]
sessionFields["UDR"] = sessionFields["HSS"]

const detailRowStyle = {
  padding: "16px"
};
const DetailRow = (props) => {
  return (
    <Card style={detailRowStyle}>
      {map(sessionFields[props.row.type], (key) => {
        return props.row[key] && isNotObject(props.row[key])
          ? <p>{key}: {props.row[key]}</p>
          : null
      })}
    </Card>
  )
};

type P = {
  subscriber: string,
  columns: object
}

type S = {
  expandedRowIds: array,
  rows: array,
  page: number,
  size: number,
  loading: boolean,
  totalCount: number,
  searchString: string
}

const endpointBase = `${BASE_URL}/v1/subs/search/findByRefWithSessionHistory?subscriber=`
class SessionHistoryTable extends React.Component<P, S> {
  state = {
    expandedRowIds: [],
    rows: [],
    page: 0,
    size: 10,
    loading: false,
    totalCount: 0,
    searchString: ""
  };

  previousEndpoint: string;

  componentDidMount(): void {
    this.loadData(false)
  }

  createQueryString({page, size, searchString}: object): string {
    return `&page=${page}&size=${size}&searchString=${searchString}`;
  }

  apiEndpoint() {
    const {subscriber} = this.props;
    const {page, size, searchString} = this.state;
    let queryString = this.createQueryString({page, size, searchString});
    return `${endpointBase}${subscriber}${queryString}`
  }

  loadData = (isUpdating: boolean) => {
    const endpoint = this.apiEndpoint();
    if (isUpdating && endpoint === this.previousEndpoint) {
      return
    }
    this.setState({loading: true});

    get(endpoint).then((response) => {
      this.setState({
        rows: getEmbeddedResource(response, "sessions"),
        loading: false,
        totalCount: response.page.totalElements
      });
    }).catch((error) => {
      this.setState({
        loading: false,
      });
    });
    this.previousEndpoint = endpoint
  };
  changeExpandedDetails = (previousExpandedRows: array) => (expandedRowIds: array) => {
    const diff = difference(expandedRowIds, previousExpandedRows);
    this.setState({expandedRowIds: diff});
  };

  changeCurrentPage = (page: number) => {
    this.setState({page})
  };

  changePageSize = (size: number) => {
    this.setState({size})
  };

  changeSearchString = (searchString: string) => {
    this.setState({searchString})
  };
  componentDidUpdate(prevProps: object, prevState: object, snapshot: *): void {
    this.loadData(true);
  }

  render() {
    const {expandedRowIds, rows, page, size, totalCount, loading, searchString} = this.state;
    const {columns} = this.props;

    return (
      <div>
        <Grid
          rows={rows}
          columns={columns}>
          <SearchState
            onValueChange={this.changeSearchString}
            value={searchString || ""}
          />
          <PagingState
            currentPage={page}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={size}
            onPageSizeChange={this.changePageSize}
          />
          <CustomPaging
            totalCount={totalCount}
          />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={this.changeExpandedDetails(expandedRowIds)}
          />
          <Table/>
          <TableHeaderRow/>
          <TableRowDetail contentComponent={DetailRow}/>
          <Toolbar/>
          <SearchPanel/>
          <PagingPanel/>
        </Grid>
        {loading && <GridLoader/>}
      </div>
    )
  }
}

export default SessionHistoryTable
