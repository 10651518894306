// @flow
import {getEmbeddedResource, getSelfLink} from 'utils'
import map from 'lodash/map'
import AsyncAutoComplete from 'components/fields/AutoCompleteAsync';
import {func, object} from "prop-types";

import React from 'react'
import {searchCustomerByName} from "../../../../utils/search";
import {withTranslation} from "react-i18next";

class CustomerSelect extends React.Component<object, *> {
  state = {
    options: []
  };

  componentWillMount(): void {
    this.loadOptions(this.props.valueLabel)
  }

  loadOptions = (searchString: string, callback: func) => {
    searchCustomerByName(searchString).then((response) => {
      const customers = [];
      map(getEmbeddedResource(response, "customers"), customer => {
        const customerOption = {"label": customer.name, "value": getSelfLink(customer)};
        customers.push(customerOption)
      });
      this.setState({options: customers});
      if (callback) {
        callback(customers)
      }
    })
  };

  render() {
    const {label, name, t} = this.props

    return <AsyncAutoComplete
      {...this.props}
      label={t(name) || label}
      loadOptions={this.loadOptions}
      options={this.state.options}
      defaultOptions={true}
    />
  }
}

CustomerSelect = withTranslation()(CustomerSelect)
export default CustomerSelect