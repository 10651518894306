// @flow
import {BASE_URL, get} from 'utils'
import React from "react";
import FormatBoolean from "../../../../utils/format/FormatBoolean";
import {object} from "prop-types";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const initialState = {
  value: "unknown"
};
const defaultKey = "interceptor-script-conf"

type P = {
  classes: object
}

type S = {
  value: string
}

class ScriptActive extends React.PureComponent<P, S> {
  state = initialState;

  findByContentForKey = (scriptName: string) => {
    let endpoint = `${BASE_URL}/v1/server-configs/search/findByContentForKey?key=${defaultKey}&value=${scriptName}`
    get(endpoint).then(response => {
      this.setState({value: (response.page.totalElements > 0).toString()})

    }).catch((error) => {
      this.setState({value: "error"})
    })
  }

  onChange = (newData) => {
    this.setState((state) => ({
      ...state, values: {...state.values, ...newData}
    }))
  };

  render() {
    const {scriptName, scriptType} = this.props;
    if(scriptType!=="SYSTEM_ADD") {
      this.findByContentForKey(scriptName)
      const {value} = this.state;
      return (
          <FormatBoolean value={value}/>
      )
    }
    else return (
        <HelpOutlineIcon/>
    )
  }
}

export default ScriptActive