// @flow
import React from 'react';
import ResourceForm from 'resource/forms/ResourceForm';
import TextField from 'components/fields/TextField';
import Select from 'components/fields/Select'
import {object} from "prop-types";


let defaultData = {
    "mcc": null,
    "mnc": null,
    "brand": null,
    "operator": null,
    "type": null,
    "status": null,
    "countryCode": null,
    "countryName": null,
    "bands": null,
    "notes": null
};

let fields = {
    "mcc": {
        "Component": TextField,
        "name": "mcc",
        "label": "MCC"
    },
    "mnc": {
        "Component": TextField,
        "name": "mnc",
        "label": "MNC"
    },
    "brand": {
        "Component": TextField,
        "name": "brand",
        "label": "Brand"
    },
    "operator": {
        "Component": TextField,
        "name": "operator",
        "label": "Operator"
    },
    "mode": {
        "Component": Select,
        "options": [
            {
                "value": "Public",
                "label": "Public"
            },
            {
                "value": "Private",
                "label": "Private"
            },
            {
                "value": "Unknown",
                "label": "Unknown"
            }
        ],
        "name": "mode",
        "label": "Mode"
    },
    "type": {
        "Component": Select,
        "options": [
            {
                "value": "International",
                "label": "International"
            },
            {
                "value": "National",
                "label": "National"
            },
            {
                "value": "Test",
                "label": "Test"
            }
        ],
        "name": "type",
        "label": "Type"
    },
    "status": {
        "Component": Select,
        "options": [
            {
                "value": "Allocated",
                "label": "Allocated"
            },
            {
                "value": "Implement_design",
                "label": "Implement / Design"
            },
            {
                "value": "Not_operational",
                "label": "Not Operational"
            },
            {
                "value": "Ongoing",
                "label": "Ongoing"
            },
            {
                "value": "Operational",
                "label": "Operational"
            },
            {
                "value": "Planned",
                "label": "Planned"
            },
            {
                "value": "Reserved",
                "label": "Reserved"
            },
            {
                "value": "Returned_Spare",
                "label": "Return Spare"
            },
            {
                "value": "Temporary_operational",
                "label": "Temporary Operational"
            },
            {
                "value": "Test_network",
                "label": "Test Network"
            },
            {
                "value": "Unknown",
                "label": "Unknown"
            }
        ],
        "name": "status",
        "label": "Status"
    },
    "countryCode": {
        "Component": TextField,
        "name": "countryCode",
        "label": "Country Code"
    },
    "countryName": {
        "Component": TextField,
        "name": "countryName",
        "label": "Country Name"
    },
    "bands": {
        "Component": TextField,
        "name": "bands",
        "label": "Bands"
    },
    "notes": {
        "Component": TextField,
        "name": "notes",
        "label": "Notes"
    }
};

const CarrierForm = (props: object) => (
    <ResourceForm {...props} fields={fields} defaultData={defaultData}/>
);

export default CarrierForm
