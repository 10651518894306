// @flow
import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
  },
})

let DefaultChip = ({classes, ...props}) => (
    <Chip {...props} className={classes.chip} />
);
DefaultChip = withStyles(styles)(DefaultChip);

export default DefaultChip