// @flow
import {formatToUppercase, getEmbeddedResource, getSelfLink} from 'utils'
import map from 'lodash/map'
import AsyncAutoComplete from 'components/fields/AutoCompleteAsync';
import {func, object} from "prop-types";

import React from 'react'
import {findAllRoles} from "../../../../utils/search";
import {withTranslation} from "react-i18next";

const localeMap = {
  SUPERUSER: 'roleSuperuser',
  ADMIN: 'roleAdmin',
  CUSTOMER_ADMIN: 'roleCustomerAdmin',
  READ_ONLY: 'roleReadOnly'
}

class RoleSelect extends React.Component<object, *> {
  state = {
    options: []
  };

  componentWillMount(): void {
    this.loadOptions(this.props.valueLabel)
  }

  loadOptions = (searchString: string, callback: func) => {
    findAllRoles().then((response) => {
      const roles = [];
      map(getEmbeddedResource(response, "account-roles"), role => {
        const roleLabel = formatToUppercase(this.props.t(localeMap[role.name]))
        const roleOption = {"label": roleLabel, "value": getSelfLink(role)};
        roles.push(roleOption)
      });
      this.setState({options: roles});
      if (callback) {
        callback(roles)
      }
    })
  };

  // We need to figure this out because there isn't consistency on these values yet
  formatValue = (value: ?object) => {
    const formattedValue = getSelfLink(value);
    if (formattedValue) {
      return formattedValue
    }
    return value
  };

  render() {
    const { label, name, value, t } = this.props

    return <AsyncAutoComplete
      {...this.props}
      label={t(name) || label}
      value={this.formatValue(value)}
      loadOptions={this.loadOptions}
      options={this.state.options}
      defaultOptions={true}
    />
  }
}

RoleSelect = withTranslation()(RoleSelect)
export default RoleSelect