// @flow
import {getEmbeddedResource, getSelfLink} from 'utils'
import map from 'lodash/map'
import AsyncAutoComplete from 'components/fields/AutoCompleteAsync';
import {func, object} from "prop-types";

import React from 'react'
import {searchSubscriberByDeepSearch} from "../../../../utils/search";


class SubscriberSelect extends React.Component<object, *> {
    state = {
      options:[]
    };

  componentWillMount(): void {
    this.loadOptions(this.props.valueLabel)
  }
  loadOptions = (searchString: string, callback: func) => {
    searchSubscriberByDeepSearch(searchString).then((response) => {
      const subscribers = [];
      map(getEmbeddedResource(response, "subscribers"), subscriber => {
        const subscriberOption = {"label": subscriber.imsi, "value": getSelfLink(subscriber)};
        subscribers.push(subscriberOption)
      });
      this.setState({options: subscribers});
      if(callback) {
        callback(subscribers)
      }
    })
  };
  render() {
    return <AsyncAutoComplete {...this.props} loadOptions={this.loadOptions} options={this.state.options} defaultOptions={true} />
  }
}

export default SubscriberSelect