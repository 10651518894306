// @flow
import React, {Component} from 'react';
import map from 'lodash/map'
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {array, func} from "prop-types";
import {BASE_URL, getQueryParam, post} from "../../utils";
import ValidationError from "../ValidationError";
import LoginButton from "./LoginButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit * 2
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "250px"
  },
  helperMessage: {
    textAlign: "center",
    width: "250px",
    lineHeight: 1.5
  }
});

type P = {
  onSubmit: func,
  classes: array,
}
type S = {
  password: string,
  confirmPassword: string,
  confirmationError: boolean,
  passwordsMatch: boolean,
  suggestions: array,
  warning: string,
  strength: number
}

class UpdatePassword extends Component<P, S> {
  state = {
    password: "",
    confirmPassword: "",
    confirmationError: false,
    passwordsMatch: false,
    suggestions: [],
    warning: "",
    strength: 0
  };
  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: getQueryParam("token")
    };
    post(`${BASE_URL}/v1/accounts/command/updatePassword`, data).then(() => {
      window.location.href = `${BASE_URL}/ui/login`
    }).catch((response) => {
      this.setState({warning: response.details[0].message})
    })
  };
  onChange = name => event => {
    if (name === "confirmPassword" && event.target.value === this.state.password) {
      this.setState(
        {
          confirmationError: false,
          passwordsMatch: true
        }
      )
    }
    if (name === "password") {
      const suggestions = this.validatesPassword(event.target.value)
      this.setState({suggestions})
    }
    this.setState({
      [name]: event.target.value,
    });
  };
  calculatePasswordConfirmation = () => {
    const {password, confirmPassword} = this.state;
    this.setState({
      "confirmationError": password !== confirmPassword,
      "passwordsMatch": password === confirmPassword
    })
  };

  validatesPassword = (password: string) => {
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
    const digitTest = /(?=.*\d)/;
    const lowercaseTest = /(?=.*[a-z])/;
    const uppercaseTest = /(?=.*[A-Z])/;
    const specialTest = /(?=.*[!@#$%&*()])/;
    const lengthTest = /.{8,}/;
    const validPassword = regExp.test(password);
    const suggestions = [];

    if (!validPassword) {
      if (!digitTest.test(password)) suggestions.push("number")
      if (!lowercaseTest.test(password)) suggestions.push("lowercase")
      if (!uppercaseTest.test(password)) suggestions.push("uppercase")
      if (!specialTest.test(password)) suggestions.push("special")
      if (!lengthTest.test(password)) suggestions.push("length")
    }
    return suggestions;
  };

  render() {
    const {password, confirmPassword, confirmationError, suggestions, passwordsMatch, warning} = this.state;
    const {classes, t} = this.props;
    return (
      <React.Fragment>
        {warning && <FormHelperText id="error-message" className={classes.helperMessage} error={true}>{warning}</FormHelperText>}
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField
            id="password"
            label={t('password')}
            value={password}
            onChange={this.onChange('password')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            type="password"
          />
          <TextField
            id="confirm-password"
            label={t('confirmPassword')}
            value={confirmPassword}
            onChange={this.onChange('confirmPassword')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            type="password"
            onBlur={this.calculatePasswordConfirmation}
          />
          {confirmationError && <ValidationError id={`confirmation-error`} error={t('mismatchedPasswordsError')}/>}
          {suggestions && map(suggestions, (suggestion) => <ValidationError id={`suggestion-${suggestion}`} error={t(suggestion + 'Suggestion')}/>)}
          <LoginButton text={t('updatePassword')} disabled={suggestions.length > 0 || !passwordsMatch}/>
        </form>
      </React.Fragment>
    )
  }
}

UpdatePassword = withStyles(styles)(UpdatePassword)
UpdatePassword = withTranslation()(UpdatePassword)

export default UpdatePassword