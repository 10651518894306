// @flow
import React from 'react'
import ResourceForm from 'resource/forms/ResourceForm'
import Select from 'components/fields/Select'
import Extensions from 'components/fields/Extensions'
import TextField from 'components/fields/TextField'
import CustomerSitesSelector from 'resource/forms/systems/fields/CustomerSitesSelector';
import CustomerSelector from 'components/selectors/CustomerSelector';
import {object} from "prop-types";

let defaultData = {
  "name":null,
  "extensions": {},
  "status": "Active",
  "customer": null,
  "features": [],
  "sites": [],
};

const fieldsSuperUser = {
  "name": {
    "Component": TextField,
    "name": "name",
    "label": "Name"
  },
  "extensions": {
    "Component": Extensions,
  },
  "status": {
    "Component": Select,
    "options": [
      {
        "value": "Active",
        "label": "Active"
      },
      {
        "value": "Inactive",
        "label": "Inactive"
      }
    ],
    "name": "status",
    "label": "Status",
  },
  "customer": {
    "label": "Customer",
    "labelPath": "name",
    "name": "customer",
    "Component":CustomerSelector,
    "relationship": "one",
    "updatable": false
  },
  "sites": {
    "label": "Sites",
    "name": "sites",
    "Component": CustomerSitesSelector,
    "relationship": "many",
  },
};

const fieldsAdmin = {
  "name": {
    "Component": TextField,
    "name": "name",
  },
  "extensions": {
    "Component": Extensions,
  },
};

const SystemForm = (props: object) => (
  <ResourceForm {...props} fields={props.isSuperUser ? fieldsSuperUser : fieldsAdmin} defaultData={defaultData} />
);
export default SystemForm
